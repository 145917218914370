import React, { useCallback } from 'react';
import { Dialog, Grid } from '@material-ui/core';

import { MTGComponent, MTGSearchComponent } from '../MTG';

export const RelatedMTGPopup = ({ mTGRelatedPopup, setFormState, formState, setLoading, setBannerState, filterCurrentFromCandidates }) => {

  const handleClose = useCallback(() => {
    setFormState(previousState => ({
      ...previousState,
      mTGRelatedPopup: {
        ...previousState.mTGRelatedPopup,
        show: false,
        isSearch: false
      }
    }));
  }, [setFormState]);

  const renderForm = () => {
    return <>
      <MTGComponent
        mTGRelatedPopup={mTGRelatedPopup}
        setFormState={setFormState}
        formState={formState}
        allowUpdate={true}
        setLoading={setLoading}
        setBannerState={setBannerState}
        filterFromCurrent={filterCurrentFromCandidates}
      />
    </>
  }

  return (
    <Dialog open={mTGRelatedPopup.show} onClose={handleClose} aria-labelledby="simple-dialog-title"
      fullWidth maxWidth={mTGRelatedPopup.isSearch ? "md" : "sm"}  >
      {mTGRelatedPopup.isSearch ?
        <Grid container>
          <Grid item xs={5}>
            {renderForm()}
          </Grid>
          <Grid item xs={7}>
            <MTGSearchComponent
              mTGRelatedPopup={mTGRelatedPopup}
              setFormState={setFormState}
              filterFromCurrent={filterCurrentFromCandidates} />
          </Grid>
        </Grid>
        : renderForm()
      }
    </Dialog>
  );
}
