import axios from 'axios';
import packageJson from '../package.json';

const configuration = {};

const setProperty = (name, value) => {
  if (value) {
    configuration[name] = value;
  } else {
    throw new Error(`'${name}' configuration value is empty.`);
  }
};

export const getAppConfig = () => configuration;

export const loadConfig = async () => {
  let version = packageJson.config.applicationVersion;

  try {
    const response = await axios.get(`${window.location.origin}/api/configuration`);
    setProperty('apiUrl', response.data.apiUrl, configuration);
    setProperty('clientId', response.data.clientId, configuration);
    setProperty('tenantId', response.data.tenantId, configuration);
    setProperty('version', version, configuration);
    setProperty('environment', response.data.environmentName);

    return configuration;
  } catch (error) {
    throw error;
  }
};
