import React from 'react';

import { Checkbox, withStyles } from "@material-ui/core";

export const CheckboxElement = withStyles({
  root: {
    color: "#1FB6AD",
    '&$checked': {
      color: "#1FB6AD",
    },
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);