import React, { useCallback } from "react";
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { Attachment, Error } from "@material-ui/icons";

import { Spinner, CheckboxElement } from "components/common";
import { FileUploadStatus } from "Helpers";

const MessageFileAttachment = ({ attachment, attachmentIndex, mailId, loadingStatus, setFiles }) => {
  const renderAttachmentIcon = () => {
    if (loadingStatus === FileUploadStatus.inProgress) {
      return <ListItemIcon><Spinner size="1.5rem" /></ListItemIcon>;
    }

    if (loadingStatus === FileUploadStatus.success) {
      return <ListItemIcon><Attachment /></ListItemIcon>;
    }

    if (loadingStatus === FileUploadStatus.failed) {
      return <ListItemIcon><Error color="error" /></ListItemIcon>;
    }
  };

  const handleAttachmentSelected = useCallback(checked => {
    setFiles(prevFiles => {
      let updatedFiles = prevFiles.map(file => {
        if (file.id === mailId) {
          let updatedFile = { ...file };
          let updatedAttachments = updatedFile.attachments.map((attachment, j) => {
            if (j === attachmentIndex) {
              return { ...attachment, isAttached: checked };
            }
            return attachment;
          });
          return { ...updatedFile, attachments: updatedAttachments };
        }
        return file;
      });
      return updatedFiles;
    });
  }, [attachmentIndex, mailId, setFiles]);

  return (
    <ListItem key={attachmentIndex}>
      {renderAttachmentIcon()}
      <ListItemText id={attachmentIndex} primary={attachment.name} className="overflow-wrap-anywhere" />
      <ListItemSecondaryAction>
        <CheckboxElement
          edge="end"
          onChange={(_event, checked) => handleAttachmentSelected(checked)}
          checked={attachment.isAttached}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default MessageFileAttachment;