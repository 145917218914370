import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { parse } from 'query-string';

import { Divider, FormControl, Grid, List, ListItem, ListItemText, ListItemIcon, TextField, Radio, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import { Error, Send } from "@material-ui/icons";

import { fetchCurrentUser } from 'Api';
import { Banner, BannerType, DatePickerElement, Footer, FormButton } from '../common';
import QuoteMapper from './Helpers';
import { fetchEmailTemplate, fetchPolicy, sendData } from './BrokerLetter.Api';

import BrokerAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerAutocomplete";
import BrokerBranchAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerBranchAutocomplete";
import BrokerContactAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerContactAutocomplete";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './BrokerLetter.css';

import { validateRequiredField, validateEmailField, validateDatePicker } from "components/SubmissionForm/Validation";

export const BrokerLetter = ({ setLoading }) => {
  const initialFormState = {
    status: '',
    message: '',
    message2: '',
    effectiveDate: null,
    policyNo: '',
    underwriterEmail: '',
    brokerContactEmail: '',
    broker: null,
    brokerBranch: null,
    brokerContact: null,
    brokerEmail: '',
    waitingPeriod: true,
    policyDetails: '',
    notificationRequest: '',
    product: null,
    companyId: null,
    newBrokerEmail : ''
  };

  const initialValidationState = {
    fields: {
      effectiveDate: { valid: true, message: "" },
      broker: { valid: true, message: "" },
      brokerBranch: { valid: true, message: "" },
      brokerContact: { valid: true, message: "" },
      brokerContactEmail: { valid: true, message: "" },
      newBrokerEmail: { valid: true, message: "" }
    },
    valid: true,
    start: true
  };

  const location = useLocation();

  const [formState, setFormState] = useState(initialFormState);
  const [validationState, setValidationState] = useState(initialValidationState);
  const [bannerState, setBannerState] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());

  useEffect(() => {
    let initializeForm = async () => {
      let queryParams = QuoteMapper.mapQueryParametersToForm(parse(location.search));
      var validationResult = validateQueryParams(queryParams);
      if (validationResult.length > 0) {
        setValidationState({
          errors: validationResult
        });
      } else {
        setFormState({
          ...formState,
          ...queryParams
        });

        let policyResponse = await fetchPolicy(queryParams.policyNo);
        if (policyResponse.success && queryParams.status) {
          let user = await fetchCurrentUser();

          //console.log(queryParams);
          let notificationRequest = {
            Status: "BoRNew",
            BrokerName: queryParams.brokerContact,
            BrokerEmail: queryParams.brokerContactEmail,
            BrokerBranch: queryParams.brokerBranch,
            SubmissionNumber: queryParams.policyNo,
            Insured: queryParams.insured,
            UnderwriterName: user?.displayName,
            Title: user?.jobTitle,
            PhoneNumber: user?.mobilePhone,
            UnderwriterEmail: user?.mail,
            SharePoint: queryParams.sharepoint,
            Target: queryParams.target
          };

          let emailTemplateResponse = await fetchEmailTemplate(notificationRequest);
          
          if (emailTemplateResponse.success) {
            let emailTemplates = emailTemplateResponse.data.split('|');
            setFormState({
              ...formState,
              ...queryParams,
              policyDetails: policyResponse.data,
              underwriterEmail: user?.mail,
              notificationRequest: notificationRequest,
              message: emailTemplates[0],
              message2: emailTemplates[1],
              companyId: 1, //Major Classes: Energy. Environmental, or War & Terrorism
              product: {
                productId: policyResponse.data.productId
              }
            });

            setEditorState(htmlToEditorState(emailTemplates[0]));
            setEditorState2(htmlToEditorState(emailTemplates[1]));
          } else {
            setBannerState({
              show: true,
              type: BannerType.error,
              message: emailTemplateResponse.errorMessage
            });
          }
        } else {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: policyResponse.errorMessage
          });
        }
      }

      setLoading(false);
    }

    setLoading(true);
    initializeForm();
  }, []);

  const htmlToEditorState = (html) => {
    let state = EditorState.createEmpty();

    try {
      let contentBlock = htmlToDraft(html);
      let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      state = EditorState.createWithContent(contentState);
    } catch (error) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: 'Error occured during email loading to editor: ' + error.message
      });
    }

    return state;
  }

  const validateQueryParams = params => {
    let result = [];

    if (!params.policyNo) {
      result.push({
        parameter: 'policy_reference_number',
        message: 'policy_reference_number value should be set.'
      })
    }
    if (!params.brokerContact) {
      result.push({
        parameter: 'broker_contact',
        message: 'broker_contact value should be set.'
      })
    }
    if (!params.brokerContactEmail) {
      result.push({
        parameter: 'broker_contact_email',
        message: 'broker_contact_email value should be set.'
      })
    }
    if (!params.brokerName) {
      result.push({
        parameter: 'broker_name',
        message: 'broker_name value should be set.'
      })
    }
    if (!params.brokerBranch) {
      result.push({
        parameter: 'broker_branch',
        message: 'broker_branch value should be set.'
      })
    }
    if (!params.insured) {
      result.push({
        parameter: 'insured',
        message: 'insured value should be set.'
      })
    }
    if (!params.sharepoint) {
      result.push({
        parameter: 'sharepoint',
        message: 'sharepoint value should be set.'
      })
    }
    if (!params.target) {
      result.push({
        parameter: 'target',
        message: 'target value should be set.'
      })
    }

    return result;
  }

  const onEditorStateChange = async (state) => {
    setEditorState(state);
    setFormState({
      ...formState,
      message: draftToHtml(convertToRaw(state.getCurrentContent()))
    });
  };

  const onEditorStateChange2 = async (state) => {
    console.log(state);
    setEditorState2(state);
    setFormState({
      ...formState,
      message2: draftToHtml(convertToRaw(state.getCurrentContent()))
    });
  };

  const onSubmit = async () => {
    let result = validateForm(formState);
    setValidationState(_ => result);   

    if (!result.valid) {
      setBannerState({
        show: true,
        type: BannerType.warning,
        message: "Form contains validation errors. Please provide valid information."
      });
      return;
    }

    setLoading(true);
    console.log(formState);
    let formData = QuoteMapper.mapFormToRequest(formState);
    let submitResponse = await sendData(formData);
    if (!submitResponse.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: submitResponse.errorMessage
      });
    }
    else {
      clearForm();
      setBannerState({
        type: BannerType.success,
        message: "Emails have been sent successfully.",
        show: true
      });
    }

    setLoading(false);
  };

  const clearForm = () => {
    setFormState(previousState => ({
      ...initialFormState,
      underwriterEmail: ''
    }));
  }

  const onBrokerContactEmailChanged = event => {
    let value = event?.target?.value;
    setFormState(previousState => ({
      ...previousState,
      newBrokerEmail: value
    }));
  };

  const onBrokerContactEmailBlur = event => {
    let value = event?.target?.value;

    let result = validateRequiredField("Broker Contact Email", value);
    if (result.valid) {
      result = validateEmailField("Broker Contact Email", value);
    }

    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        newBrokerEmail: result
      }
    }));
  };

  const onEffectiveDateChanged = value => {
    let result = validateDatePicker(value);

    setValidationState(validState => ({
      ...validState,
      fields: {
        ...validState.fields,
        effectiveDate: result
      }
    }));

    setFormState(prevState => ({
      ...prevState,
      effectiveDate: value
    }));
  };

  const onWPChanged = event => {
    let value = event?.target?.value;
    setFormState({
      ...formState,
      waitingPeriod: value
    });
  };

  const validateDate = (field, effectiveDate) => {
      let result = validateRequiredField(field, effectiveDate);
      return result.valid ? validateDatePicker(effectiveDate) : result;
  }

  const onBrokerContactChanged = event => {
    /*
     * var msg = formState.message2;
    //console.log(formState);
    msg = msg.replace("(Insured Contact)", formState.brokerContact.name);
    //console.log(msg);
    setFormState({
      ...formState,
      message2: msg
    });
    formState.message2 = msg;
    console.log(formState);
    */
  };

  const validateForm = (form) => {
    const effectiveDateResult = validateDate("Effective Date", form.effectiveDate);
    const brokerContactEmailResult = validateEmailField("Broker Contact Email", form.newBrokerEmail);
    const brokerResult = validateRequiredField("Broker Name", form.broker?.name);
    const brokerBranchResult = validateRequiredField("Broker Branch", form.brokerBranch?.name);
    const brokerContactResult = validateRequiredField("Broker Contact", form.brokerContact?.name)

    return {
      fields: {
        effectiveDate: effectiveDateResult,
        broker: brokerResult,
        brokerBranch: brokerBranchResult,
        brokerContact: brokerContactResult,
        newBrokerEmail: brokerContactEmailResult
      },
      valid: effectiveDateResult.valid & brokerResult.valid & brokerBranchResult.valid & brokerContactResult.valid & brokerContactEmailResult.valid,
      start: true
    };
  };

  const renderEmailEditor = () => {
    return (
      <Grid container className="form-section" direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid item xs={6} >
            <h4>For Old Broker (incumbent)</h4>
            <FormControl
              fullWidth
              required
            >
              <Editor
                id="message"
                label="Email body"
                editorClassName="text-editor"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </FormControl>
        </Grid>
        <Grid item xs={6} >
            <h4>For New Broker</h4>
            <FormControl
              fullWidth
              required
            >
              <Editor
                id="message2"
                label="Email body"
                editorClassName="text-editor"
                editorState={editorState2}
                onEditorStateChange={onEditorStateChange2}
              />
            </FormControl>
        </Grid>

        <Grid item container xs={6} spacing={2}>
          <Grid item xs={6}>
            <DatePickerElement
              id="effectiveDate"
              label="Effective Date"
              required
              fullWidth
              value={formState.effectiveDate}
              onChange={onEffectiveDateChanged}
              error={!validationState.fields.effectiveDate.valid}
              helperText={validationState.fields.effectiveDate.message}
            />
          </Grid>
          {formState.status == "MTE" ? waitingPeriodSelection() : ""}
        </Grid>

        <Grid item container xs={6} spacing={2}>
          <Grid item xs={12}>
            <BrokerAutocomplete
              fieldsState={formState}
              validationState={validationState}
              setFieldsState={setFormState}
              setValidationState={setValidationState}
              setBannerState={setBannerState}
            />
          </Grid>
          <Grid item xs={12}>
            <BrokerBranchAutocomplete
              fieldsState={formState}
              validationState={validationState}
              setFieldsState={setFormState}
              setValidationState={setValidationState}
              setBannerState={setBannerState}
            />
          </Grid>
          <Grid item xs={6}>
            <BrokerContactAutocomplete
              fieldsState={formState}
              validationState={validationState}
              setFieldsState={setFormState}
              setValidationState={setValidationState}
              setBannerState={setBannerState}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="brokerEmail"
              label="Broker Email Address"
              required
              fullWidth
              onFocus={onBrokerContactChanged}
              onChange={onBrokerContactEmailChanged}
              onBlur={onBrokerContactEmailBlur}
              value={formState.newBrokerEmail ?? ""}
              error={!validationState.fields.newBrokerEmail.valid}
              helperText={validationState.fields.newBrokerEmail.message}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} direction="column">
          <div className="form-footer">
            <FormButton endIcon={<Send />} onClick={onSubmit}>
              Submit
            </FormButton>
          </div>
        </Grid>
      </Grid>
    );
  }

  const waitingPeriodSelection = () => {
    return <>
      <Grid item xs={12}>
        <FormLabel id="demo-row-radio-buttons-group-label">Endorsements</FormLabel>
        <RadioGroup row defaultValue="true" onChange={onWPChanged}>
          <FormControlLabel value="true" control={<Radio color="default" />} label="Waiting Period" />
          <FormControlLabel value="false" control={<Radio color="default" />} label="No Waiting Period" />
        </RadioGroup>
      </Grid>
    </>;
  }

  const renderValidationMessages = () => {
      return <>
        <h3 className="validation-errors-header">
          Query parameters validation is failed. Please take a look at the errors below
        </h3>
        <List component="div" disablePadding>
          {validationState.errors?.map(error => {
            return (
              <ListItem key={error.parameter}>
                <ListItemIcon>
                  <Error color="error" />
                </ListItemIcon>
                <ListItemText primary={error.message} />
              </ListItem>
            );
          })}
        </List>
      </>;
  }

  const renderForm = () => {
    return (
      <div className="broker-quote-container">
        <h2>Broker of Record Letters</h2>
        <Divider />
        <Banner
          type={bannerState.type}
          message={bannerState.message}
          show={bannerState.show}
          showBanner={show => setBannerState({
            ...bannerState,
            show: show
          })}
        />
        {validationState.start ? renderEmailEditor() : renderValidationMessages()}
        <Footer />
      </div>
    );
  }

  return renderForm();
};