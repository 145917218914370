export const initialDropdowns = {
  entitiesList: [],
  majorClasses: [],
  minorClasses: [],
  classes: [],
  producingTeams: [],
  assignedUWs: [],
  years: [],
  entities: {},
  documentTypes: [],
  placingBases: [],
  productPlacingBases: [],
  states: [],
  miamiCedants: [],
  miamiCedantsCountries: [],
  territories: []
};
