export const QueryParametersConstants = {
  PolicyNo: "policy_reference_number",
  Status: "status",
  BrokerContact: "broker_contact",
  BrokerContactEmail: "broker_contact_email",
  BrokerName: "broker_name",
  BrokerBranch: "broker_branch",
  Insured: "insured",
  Sharepoint: "sharepoint",
  Target: "target"
};