import { isDeclaration, isDualStampEntity } from 'components/SubmissionWizard/Helpers';
import { v4 as uuidv4 } from 'uuid';

export const generatePolicies = (policyNumbers, businessEntity, policies) => {
  let numbers = policyNumbers - policies.length;
  let previousLayer = policies.length;

  if (policyNumbers > policies.length) {
    const isDualStamp = isDualStampEntity(businessEntity.entityCode);
    return [...policies, ...Array.from({ length: numbers }, (_, i) => i + 1).map(i => ({
      id: uuidv4(),
      layer: previousLayer + i,
      classTypeCode: '',
      classType: '',
      majorClassCode: '',
      majorClass: '',
      minorClassCode: '',
      minorClass: '',
      class: '',
      classCode: '',
      producingTeamCode: '',
      producingTeam: '',
      entityCode: isDualStamp ? '' : businessEntity.entityCode,
      entity: isDualStamp ? '' : businessEntity.entityDescription,
      declarationType: '',
      declarationAssured: null
    }))];
  }
  else if (policyNumbers < policies.length) {
    let policiesList = []
    for (let i = 0; i < policyNumbers; i++) {
      policiesList = [...policiesList, policies[i]]
    }

    return policiesList;
  }

  return policies;
}