import { authorizedApiClient } from "Api";

const api = {
  brokers: {
    buildMessageModel: "api/brokersNotifications/build-message",
  },
  policy: {
    get: "api/policy/{policyNo}",
    brokerInfo: "api/policy/broker/{policyNo}",
    submit: "api/policy/brokerInfo",
    editableInfo: "api/policy/editableInfo/{policyNo}",
    kissflowInfo:"api/policy/GetKissflowDetails/{processId}/{instanceId}"
  }
};

export const fetchKissflowInfo = async (processId, instanceId) => {
  try {
    let url = api.policy.kissflowInfo
      .replace("{processId}", processId)
      .replace("{instanceId}", instanceId);

    let response = await authorizedApiClient.get(url);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during fetching kissflow info: " + error
    };
  }
}

export const fetchPolicy = async policyNo => {
  try {
    let response = await authorizedApiClient.get(api.policy.get.replace("{policyNo}", policyNo));
    return {
      success: true,
      data: response.data
    }
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occured during fetching policy details: " + error
    }
  }
};

export const fetchPolicyBrokerInfo = async policyNo => {
  try {
    let response = await authorizedApiClient.get(api.policy.brokerInfo.replace("{policyNo}", policyNo));
    return {
      success: true,
      data: response.data
    }
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occured during fetching policy details: " + error
    }
  }
};

export const fetchEditableInfo = async policyNo => {
  try {
    let response = await authorizedApiClient.get(api.policy.editableInfo.replace("{policyNo}", policyNo));
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the editable info data loading. Please, try again later."
    };
  }
};


export const sendData = async formData => {
  try {
    await authorizedApiClient.post(api.policy.submit, formData);
    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during saving the info: " + error
    };
  }
};