import React, { Component } from 'react';

import { getAuthorizationProvider } from './AuthorizationProvider';

let authorizationProvider;

export function withAuth(HocComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isAuthenticated: false,
        user: {},
        renewIframe: false,
        hasError: false,
        errorMessage: null
      };
    }

    async UNSAFE_componentWillMount() {
      authorizationProvider = await getAuthorizationProvider();

      authorizationProvider.handleRedirectCallback(() => {
        let userAccount = authorizationProvider.getAccount();

        this.setState({
          isAuthenticated: true,
          user: userAccount
        });
      }, (authErr) => {  // on fail
        console.log(authErr);

        this.setState({
          hasError: true,
          errorMessage: authErr.errorMessage
        });
      });

      if (authorizationProvider.isCallback(window.location.hash)) {
        this.setState({
          auth: {
            renewIframe: true
          }
        });

        return;
      }

      let userAccount = authorizationProvider.getAccount();
      if (!userAccount) {
        authorizationProvider.loginRedirect({});
        return;
      } else {
        this.setState({
          isAuthenticated: true,
          user: userAccount
        });
      }
    }

    onSignIn() {
      authorizationProvider.loginRedirect({});
    }

    onSignOut() {
      authorizationProvider.logout();
    }

    render() {
      if (this.state.renewIframe) {
        return <div>hidden renew iframe - not visible</div>;
      }

      if (this.state.isAuthenticated) {
        return (
          <HocComponent
            auth={this.state}
            onSignIn={() => this.onSignIn()}
            onSignOut={() => this.onSignOut()}
            {...this.props}
          />
        );
      }

      if (this.state.hasError) {
        return <div>{this.state.errorMessage}</div>;
      }

      return <div>Login in progress...</div>;
    }
  };
}
