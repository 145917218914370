import React, { useCallback } from 'react';

import { FormControl, Grid, TextField } from '@material-ui/core';

import { ComponentController, Dropdown, InformationMessage } from 'components/common';
import { GeneralDetailsFields, TotalNumberOfPolicyReferencesName, createMajorClassStructure } from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { AssuredAndPolicyReferenceControl } from './common';

import '../GeneralDetails.css';

const EpiUpdateSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  modellingMajorClasses
}) => {
  const maxJustificationLength = 250;

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        majorClass: majorClass,
        requiredModellingPriority: majorClass?.isModellingPriority
          ? previous.dropdownsData.modellingPriorities[0]
          : ''
      }
    }));
  }, [dropdownsData.modellingPriorities, setFormState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const renderModellingPriority = () => {
    let name = "requiredModellingPriority";
    return fields.majorClass?.isModellingPriority &&
    (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id={name}
            label={GeneralDetailsFields.requiredModellingPriority}
            value={fields.requiredModellingPriority}
            data={dropdownsData.modellingPriorities}
            onChange={e => onFieldChange(name, e.target.value)}
          />
        </FormControl>
      </Grid>
    );
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType ="lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      additionalRequestData={{ documentType: fields.documentType , businessEntities: dropdownsData.entitiesForFilter }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
    />)
  }

  const renderAssured = () => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = () => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.policyReferenceNumber}
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    return (
      <Grid item>
        <TotalPoliciesField
          name={TotalNumberOfPolicyReferencesName}
          label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
          required={true}
          control={control}
          value={fields.totalNumberOfPolicyReferences}
          errors={errors}
          setFormState={setFormState}
          setValidationValue={setValidationValue}
          getRequiredError={getRequiredError}
        />
      </Grid>
    );
  }

  const renderJustificationForEpiUpdate = () => {
    return (
      <Grid item>
        <ComponentController
          name="justificationForEpiUpdate"
          control={control}
          required
          rules={{ maxLength: maxJustificationLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              onBlur={onBlur}
              value={fields.justificationForEpiUpdate}
              error={!!errors[name]}
              helperText={<InformationMessage
                fieldName={GeneralDetailsFields.justificationForEpiUpdate}
                fieldErrors={errors.justificationForEpiUpdate}
                maxLength={maxJustificationLength}
                showLengthInfo={true}
                length={fields.justificationForEpiUpdate.length}
              />}
            />
          }
        />
      </Grid>
    );
  }

  return <>
    {renderModellingPriority()}
    {renderLookupForAssuredAndPolicyReference()}
    {renderPolicyReferenceNumber()}
    {renderAssured()}
    {renderTotalNumberOfReferences()}
    {renderJustificationForEpiUpdate()}
  </>;
}

export default EpiUpdateSection;