import { UserAgentApplication } from "msal";

import { loadConfig } from "../config";

let authorizationProvider;

const getAuthenticationConfig = async () => {
  const config = await loadConfig();
  return {
    authority: "https://login.microsoftonline.com/" + config.tenantId,
    clientId: config.clientId,
    redirectUri: config.apiUrl
  };
};

export const getAuthorizationProvider = async () => {
  if (authorizationProvider) {
    return authorizationProvider;
  }

  authorizationProvider = new UserAgentApplication({
    auth: await getAuthenticationConfig(),
    cache: {
      cacheLocation: 'localStorage'
    }
  });

  return authorizationProvider;
};