import React, { useCallback } from 'react';
import clsx from 'clsx';

import { Grid, makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import { FormButton } from './FormButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  footer: {
    borderTop: "1px solid var(--components-color)",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    marginRight: "1rem",
    textAlign: "right"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

const useCustomStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    alignItems: 'center'
  },
  active: {
    color: '#1FB6AD'
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: '#1FB6AD',
    zIndex: 1,
    fontSize: 24,
  },
  text: {
    color: "white",
    textAlign: "center"
  }
});

export const Wizard = ({ steps, activeStep, setActiveStep, onNext, onSubmit }) => {
  const classes = useStyles();

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, [setActiveStep]);

  const handleNext = useCallback(async () => {
    let validateAction = steps[activeStep].props.validationRef.current;
    if (validateAction) {
      let validationResult = await validateAction();
      if (validationResult) {
        await onNext();
      }
    } else {
      await onNext();
    }
  }, [onNext, activeStep, steps[activeStep]]);

  const handleSubmit = useCallback(async () => {
    let validateAction = steps[activeStep].props.validationRef.current;
    if (validateAction) {
      let validationResult = await validateAction();
      if (validationResult) {
        await onSubmit();
      }
    } else {
      await onSubmit();
    }
  }, [onSubmit, activeStep, steps[activeStep]]);

  const CustomStepIcon = props => {
    const classes = useCustomStepIconStyles();
    const { active, completed, icon } = props;

    return (
      <div className={clsx(classes.root, { [classes.active]: active })}>
        {completed
          ? <Check className={classes.completed} />
          : <div className={classes.circle}><div className={classes.text}>{icon}</div></div>}
      </div>
    );
  }

  const renderStepper = () => {
    return (
      steps.length > 1 &&
      <Stepper activeStep={activeStep} alternativeLabel>
        {
          steps.map(step => (
            <Step key={step.props.name}>
              <StepLabel StepIconComponent={CustomStepIcon}>{step.props.name}</StepLabel>
            </Step>
          ))
        }
      </Stepper>
    );
  }

  const renderButtons = () => {
    return (
      <div>
        <FormButton disabled={activeStep === 0} onClick={handleBack}>Back</FormButton>
        {
          activeStep === steps.length - 1
            ? <FormButton onClick={handleSubmit}>Submit</FormButton>
            : <FormButton onClick={handleNext}>Next</FormButton>
        }
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {renderStepper()}
      <Grid container className={classes.instructions} direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
        {steps[activeStep]}
      </Grid>
      <div className={classes.footer}>
        {renderButtons()}
      </div>
    </div>
  );
}