import React, { useState, useCallback } from 'react';
import { useGreyHint } from 'hamilton-web-components'
import { TextField, Dialog, DialogActions, DialogTitle, DialogContent, CircularProgress, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import debounce from 'lodash/debounce';

import { DialogButton, BannerType, Dropdown } from 'components/common';
import { validateRequiredField } from 'components/SubmissionForm/Validation';
import { fetchAssuredList, fetchPolicyReferences, addAssured, fetchAddressStates, fetchAddressCountries } from 'components/SubmissionForm/Form.api';
import { validateAddressLine1, validateAddressLine2, validateAddressLine3, validateCity, validateState, validateZipCode, validateAddAssuredDialog } from './AssuredAutocomplete.validation';
import { isNYEnergyEnviroWT } from "components/SubmissionForm/Helpers";

import './AssuredAutocomplete.css';

const AssuredAutocomplete = (props) => {
  const {
    fieldsState,
    entityData,
    validationState,
    setFieldsState,
    setPolicyReferencesList,
    setValidationState,
    setBannerState,
    setLoading,
    inputRef,
    hintText
  } = { ...props };

  const loadingTime = 2000;

  const initialDialogState = {
    opened: false,
    insured: "",
    address: {
      line1: "",
      line2: "",
      line3: "",
      city: "",
      state: "",
      zipCode: "",
      countryCode: "US"
    }
  };

  const initialDialogValidationState = {
    insured: { valid: true, message: "" },
    address: {
      line1: { valid: true, message: "" },
      line2: { valid: true, message: "" },
      line3: { valid: true, message: "" },
      city: { valid: true, message: "" },
      state: { valid: true, message: "" },
      zipCode: { valid: true, message: "" },
      countryCode: { valid: true, message: "" }
    }
  };

  const [isAssuredLoading, setIsAssuredLoading] = useState(false);
  const [inputValue, setInputValue] = useState('')
  const [assuredList, setAssuredList] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [dialogValidationState, setDialogValidationState] = useState(initialDialogValidationState);
  const [dialogState, setDialogState] = useState(initialDialogState);
  const [addressStates, setAddressStates] = useState([]);
  const [addressCountries, setAddressCountries] = useState([]);

  const { greyHintForStartAdornment } = useGreyHint({
    inputRef,
    inputValue,
    setInputValue,
    hintText,
    sx: {ml: '0.25rem', 'span': {fontFamily: "inherit", fontSize: "1rem"}}
  });

  const addNewAssured = async () => {
    setLoading(true);    
    setIsAssuredLoading(true);

    if (fieldsState.countryCode != 'US') {
      //Add default fields
      if (dialogState.address.state.length == 0) {
        dialogState.address.state = '0';
      }
      if (dialogState.address.zipCode.length == 0) {
        dialogState.address.zipCode = '0';
      }
    }

    let response = await addAssured({
      entity: fieldsState.businessEntity,
      assured: dialogState.insured,
      address: dialogState.address,
      relationshipId: fieldsState.brokerContact?.relationshipId
    });

    if (response.success) {
      validateAssured(dialogState.insured);
      setFieldsState({
        ...fieldsState,
        assured: dialogState.insured,
        assuredId: response.data?.id,
        isNewAssured: true,
        assuredAddress: dialogState.address
      });
    } else {
      setFieldsState({
        ...fieldsState,
        assured: ""
      });

      setBannerState({
        show: true,
        type: BannerType.error,
        message: "Error occurred. Insured is not added."
      });
    }

    setLoading(false);
    setIsAssuredLoading(false);
  };

  const getAssuredQuery = useCallback(debounce(async newValue => {
    let assuredRequest = {
      filter: encodeURIComponent(newValue),
      entity: fieldsState.businessEntity
    };

    let response = await fetchAssuredList(assuredRequest);
    if (response.success) {
      setAssuredList(response.data);
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }

    setIsAssuredLoading(false);
  }, loadingTime), [fieldsState, entityData, setBannerState]);

  const getPoliciesForAssured = async (assuredId, productId) => {
    let response = await fetchPolicyReferences(assuredId, productId);
    if (response.success) {
      return response.data.map(r => r.policyReference);
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }
  };

  const validateAssured = selectedAssured => {
    let result = validateRequiredField("Insured Name", selectedAssured);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        assured: result,
        policyReferenceNumber: { valid: true, message: "" }
      }
    });

    return result.valid;
  };

  const handleClose = () => {
    setDialogValidationState(initialDialogValidationState);

    setDialogState(initialDialogState);
  };

  const handleAdd = async event => {
    let validationResult = validateAddAssuredDialog(dialogState, addressStates, fieldsState.countryCode);

    setDialogValidationState({
      ...dialogValidationState,
      insured: validationResult.fields.insured,
      address: {
        line1: validationResult.fields.address.line1,
        line2: validationResult.fields.address.line2,
        line3: validationResult.fields.address.line3,
        city: validationResult.fields.address.city,
        state: validationResult.fields.address.state,
        zipCode: validationResult.fields.address.zipCode
      }
    });

    if (validationResult.valid) {
      event.preventDefault();
      await addNewAssured();
      handleClose();      
    }
  };

  const handleChange = async (_, assuredValue) => {
    let response = await fetchAddressStates();
    if (response.success) {
      setAddressStates(response.data);
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }

    let response2 = await fetchAddressCountries();
    if (response2.success) {
      setAddressCountries(response2.data);
      setFieldsState({
        ...fieldsState,
        country: 'United States of America',
        countryCode: 'US',
      });

    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response2.errorMessage
      });
    }

    if (typeof assuredValue === 'string') {
      setDialogState({
        ...dialogState,
        opened: true,
        insured: assuredValue
      });
    } else if (assuredValue && assuredValue.inputValue) {
      // Create a new value from the user input
      setDialogState({
        ...dialogState,
        opened: true,
        insured: assuredValue.inputValue
      });
    } else {
      let assuredId = assuredValue?.id;
      let productId = fieldsState.product?.productId;
      let hasExpiringPolicies = false;
      if (validateAssured(assuredValue?.value)) {
        let policyRefs = await getPoliciesForAssured(assuredId, productId);
        setPolicyReferencesList(policyRefs);

        if (policyRefs !== undefined && policyRefs.length > 0) {
          hasExpiringPolicies = true;
        }
      }

      setFieldsState({
        ...fieldsState,
        assured: assuredValue?.value ?? '',
        assuredId: assuredId,
        assuredAddress: assuredValue?.address,
        policyReferenceNumber: '',
        expiringPolicyReferenceNumber: '',
        isNewAssured: false,  
        hasExpiringPolicies: hasExpiringPolicies,
        preRenewal: false,
        typeOfBusiness: ''
      });
      validateAssured(assuredValue?.value);
    }
  };

  const handleTextChange = async event => {
    let newValue = event.target?.value;
    if (newValue) {
      setIsAssuredLoading(true);
      await getAssuredQuery(newValue);
    }
  };

  const handleBlur = () => {
    validateAssured(fieldsState.assured);
  };

  const onPopupClosed = () => {
    setIsOpened(false);
    setIsAssuredLoading(false);
  };

  const onPopupOpened = () => {
    setIsOpened(true);
    setAssuredList([]);
  };

  const onAddressLine1Changed = event => {
    setDialogState({
      ...dialogState,
      address: {
        ...dialogState.address,
        line1: event?.target?.value
      }      
    });
  };

  const onAddressLine1Blur = event => {
    let result = validateAddressLine1(event?.target?.value);
    setDialogValidationState({
      ...dialogValidationState,
      address: {
        ...dialogValidationState.address,
        line1: result
      }
    });
  };

  const onAddressLine2Changed = event => {
    setDialogState({
      ...dialogState,
      address: {
        ...dialogState.address,
        line2: event?.target?.value
      }      
    });
  };

  const onAddressLine2Blur = event => {
    let result = validateAddressLine2(event?.target?.value);
    setDialogValidationState({
      ...dialogValidationState,
      address: {
        ...dialogValidationState.address,
        line2: result
      }
    });
  };

  const onAddressLine3Changed = event => {
    setDialogState({
      ...dialogState,
      address: {
        ...dialogState.address,
        line3: event?.target?.value
      }      
    });
  };

  const onAddressLine3Blur = event => {
    let result = validateAddressLine3(event?.target?.value);
    setDialogValidationState({
      ...dialogValidationState,
      address: {
        ...dialogValidationState.address,
        line3: result
      }
    });
  };

  const onCityChanged = event => {
    setDialogState({
      ...dialogState,
      address: {
        ...dialogState.address,
        city: event?.target?.value
      }      
    });
  };

  const onCityBlur = event => {
    let result = validateCity(event?.target?.value);
    setDialogValidationState({
      ...dialogValidationState,
      address: {
        ...dialogValidationState.address,
        city: result
      }
    });
  };

  const onStateChanged = event => {
    setDialogState({
      ...dialogState,
      address: {
        ...dialogState.address,
        state: event?.target?.value
      }      
    });
  };

  const onStateBlur = event => {
    let result = validateState(event?.target?.value, addressStates, fieldsState.countryCode);
    setDialogValidationState({
      ...dialogValidationState,
      address: {
        ...dialogValidationState.address,
        state: result
      }
    });
  };

  const onZipCodeChanged = event => {
    setDialogState({
      ...dialogState,
      address: {
        ...dialogState.address,
        zipCode: event?.target?.value
      }      
    });
  };

  const onZipCodeBlur = event => {
    let result = validateZipCode(event?.target?.value, fieldsState.countryCode);
    setDialogValidationState({
      ...dialogValidationState,
      address: {
        ...dialogValidationState.address,
        zipCode: result
      }
    });
  };

  const filterOptions = (options, params) => {
    let filter = createFilterOptions({
      ignoreCase: true,
      matchFrom: 'any',
      stringify: option => option.name,
      limit: 200
    });

    const filtered = filter(options, params);
    let rows = filtered.map(r => ({
      value: r.name,
      id: r.id,
      address: r.address
    }));

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      rows.unshift({
        inputValue: params.inputValue,
        value: `Add "${params.inputValue}" organisation`
      });
    }

    return rows;
  };

  const getOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }

    // Regular option
    return option.value;
  };

  const renderOption = option => {
    if (option.inputValue) {
      return (
        <Grid container className="add-new-assured-row" id="Mui-addNewOption">
          <Grid item xs={12}>
            <div>{option.value}</div>
          </Grid>
        </Grid>
      );
    }

    return option.value;
  };

  const renderInputElement = params => (
        <TextField
          {...params}
          inputRef={inputRef}
          required
          label="Insured Name"
          onChange={handleTextChange}
          error={!validationState.fields.assured.valid}
          helperText={validationState.fields.assured.message}
          InputProps={{
            ...params.InputProps,
            ...greyHintForStartAdornment,
            endAdornment: (
              <>
                {isAssuredLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          inputProps={{
            ...params.inputProps,
            maxLength: 130,
          }}
        />
  );

  const onCountryChanged = async event => {
    const newCountry = event.target.value;
    let val = addressCountries.filter(x => x.description === newCountry);
    setFieldsState({
      ...fieldsState,
      country: newCountry,
      countryCode: val[0]['code']
    });
    setDialogState({
      ...dialogState,
      address: {
        ...dialogState.address,
        countryCode: val[0]['code']
      }
    });

    var zipCodeField = document.getElementById("ZIP Code-label").getElementsByClassName("MuiFormLabel-asterisk")[0];
    var stateField = document.getElementById("State-label").getElementsByClassName("MuiFormLabel-asterisk")[0];
    if (val[0]['code'] == 'US') {
      zipCodeField.textContent = "*"
      stateField.textContent = "*"
    }
    else {
      zipCodeField.textContent = "";
      stateField.textContent = "";
    }
  };

  const renderAddAssuredDialog = () => {

    let countryDrop;
    //Add Country Dropdown only for certain major classes
    if (isNYEnergyEnviroWT) {
      countryDrop = <Dropdown
        fullWidth
        id="Country"
        label="Country"
        margin="dense"
        required
        data={addressCountries.map(x => x.description)}
        onChange={onCountryChanged}
        value={fieldsState.country}
      />
    }

    return (
      <Dialog open={dialogState.opened} onClose={handleClose} aria-labelledby="assured-dialog-title">
        <DialogTitle id="assured-dialog-title">Add a new Insured to Genasys Ski</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="Insured Name"
            label="Insured Name"
            margin="dense"
            value={dialogState.insured}
            error={!dialogValidationState.insured.valid}
            helperText={dialogValidationState.insured.message}
          />
          {countryDrop}
          <TextField
            fullWidth
            id="Line 1"
            label="Line 1"
            margin="dense"
            required
            onChange={onAddressLine1Changed}
            onBlur={onAddressLine1Blur}
            value={dialogState.address.line1}
            error={!dialogValidationState.address.line1.valid}
            helperText={dialogValidationState.address.line1.message}
          />
          <TextField
            fullWidth
            id="Line 2"
            label="Line 2"
            margin="dense"
            onChange={onAddressLine2Changed}
            onBlur={onAddressLine2Blur}
            value={dialogState.address.line2}
            error={!dialogValidationState.address.line2.valid}
            helperText={dialogValidationState.address.line2.message}
          />
          <TextField
            fullWidth
            id="Line 3"
            label="Line 3"
            margin="dense"
            onChange={onAddressLine3Changed}
            onBlur={onAddressLine3Blur}
            value={dialogState.address.line3}
            error={!dialogValidationState.address.line3.valid}
            helperText={dialogValidationState.address.line3.message}
          />
          <TextField
            fullWidth
            id="City"
            label="City"
            margin="dense"
            required
            onChange={onCityChanged}
            onBlur={onCityBlur}
            value={dialogState.address.city}
            error={!dialogValidationState.address.city.valid}
            helperText={dialogValidationState.address.city.message}
          />
          <TextField
            fullWidth
            id="State"
            label="State"
            margin="dense"
            required
            onChange={onStateChanged}
            onBlur={onStateBlur}
            value={dialogState.address.state}
            error={!dialogValidationState.address.state.valid}
            helperText={dialogValidationState.address.state.message}
          />
          <TextField
            fullWidth
            id="ZIP Code"
            label="ZIP Code"
            margin="dense"
            required
            onChange={onZipCodeChanged}
            onBlur={onZipCodeBlur}
            value={dialogState.address.zipCode}
            error={!dialogValidationState.address.zipCode.valid}
            helperText={dialogValidationState.address.zipCode.message}
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose} disabled={isAssuredLoading}>
            Cancel
          </DialogButton>
          <DialogButton onClick={handleAdd} disabled={isAssuredLoading}>
            {isAssuredLoading ? <CircularProgress color="inherit" size={16} style={{marginRight: 8}} /> : null}
            Add
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  };

  return <>
    <Autocomplete
      id="assured"
      value={fieldsState.assured}
      onChange={handleChange}
      onBlur={handleBlur}
      filterOptions={filterOptions}
      loading={isAssuredLoading}
      open={isOpened}
      onOpen={onPopupOpened}
      onClose={onPopupClosed}
      clearOnBlur
      clearOnEscape
      freeSolo
      handleHomeEndKeys
      options={assuredList}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInputElement}
      inputValue={inputValue}
      onInputChange={(e, newValue) => setInputValue(newValue)}
    />
    {renderAddAssuredDialog()}
  </>;
};

export default AssuredAutocomplete;