import React, { useMemo } from 'react';

import { TilesNames, ModellingPriorities } from '../Helpers';
import { SubmissionDataPopup } from './SubmissionData';
import { RelatedMTGPopup } from './MTG';
import { DeclinePopup } from "./Decline";
import { SelectFoldersPopup } from './SelectFolders';
import { SelectOrCreateFolderPopup } from './SelectOrCreateFolder';
import { EngineeringFilesSelectionPopup } from './EngineeringFilesSelection';
import { DeclineRelatedMtgPopup } from "./DeclineRelatedMtg";

export const PopupsFactory = ({ tile, formData, popupProps, isModellingTask }) => {
  const submissionDataPopup = useMemo(() => {
    return <SubmissionDataPopup {...popupProps} />;
  }, [popupProps]);

  const relatedMTGPopup = useMemo(() => {
    return <RelatedMTGPopup {...popupProps} />;
  }, [popupProps]);

  const declinePopup = useMemo(() => {
    return <DeclinePopup {...popupProps} />;
  }, [popupProps]);

  const selectFoldersPopup = useMemo(
    () => <SelectFoldersPopup {...popupProps} />,
    [popupProps]
  );

  const selectOrCreateFolderPopup = useMemo(
    () => <SelectOrCreateFolderPopup {...popupProps} />,
    [popupProps]
  );

  const engineeringFilesSelectionPopup = useMemo(
    () => <EngineeringFilesSelectionPopup {...popupProps} />,
    [popupProps]
  );

  const getSubmissionDataPopup = () => isEngineering()
    ? engineeringFilesSelectionPopup
    : submissionDataPopup;

  const isEngineering = () => {
    if (formData.majorClass == "Property Insurance" && !popupProps.formState.engineeringFilesSelected) {
      return true;
    }

    return false;
  }

  const declineRelatedMtgPopup = useMemo(() => {
    return <DeclineRelatedMtgPopup {...popupProps} />;
  }, [popupProps]);

  const isModellingPreformed = () => {
    const priority = formData.modellingPriority;
    return [
      ModellingPriorities.High,
      ModellingPriorities.Medium,
      ModellingPriorities.Low,
      ModellingPriorities.NotRequired
    ].includes(priority);
  }

  const getPopup = () => {
    if (isModellingTask) {
      return getSubmissionDataPopup();
    }
    else if (popupProps.mTGRelatedPopup.show) {
      return relatedMTGPopup;
    }

    const showOrPreventSubmissionTilePopups = () => {
      if (formData.majorClass == "Casualty Reinsurance" || formData.majorClass == "Casualty Insurance") {
        return <></>;
      }
      else if (formData.majorClass == "Property Insurance" && isEngineering()) {
        return engineeringFilesSelectionPopup;
      }
      else if (!isModellingPreformed() && !tileObj.isLocked ) {
        return submissionDataPopup;
      }
      else {
        return <></>;
      }
    }

    const getDeclinePopup = () => {

      let isRelated = popupProps.formState.isMTGRelated;
      if (isRelated && !popupProps.formState.declineRelatedMtgSelected) {
        return declineRelatedMtgPopup;
      }
      return declinePopup;
    }

    let tileObj = popupProps.formState.tiles.find((t) => { return t.content == tile; });

    switch (tile) {
      case TilesNames.SubmissionData:
        return showOrPreventSubmissionTilePopups();
      case TilesNames.Decline:
        return popupProps.formState.tiles.find((t) => { return t.declined; }) || tileObj.isLocked ? <></> : getDeclinePopup();
      case TilesNames.Quotes:
      case TilesNames.Binders:
      case TilesNames.FinalPolicy:
        return formData.majorClass == "Financial Lines Insurance" ? selectFoldersPopup : <></>;
      case TilesNames.Endorsements:
        return formData.majorClass == "Property Insurance" ? selectOrCreateFolderPopup : <></>;
      default:
        return <></>;
    }
  }

  return getPopup();
};