export const initialValidationState = {
  files: {
    dropped: false,
  },
  fields: {
    renewedPolicy: { valid: true, message: '' },
    policyEntryInformation: { valid: true, message: '' },
    entity: { valid: true, message: '' },
    majorClasses: {},
    minorClass: { valid: true, message: '' },
    class: { valid: true, message: '' },
    dealName: { valid: true, message: '' },
    producingTeam: { valid: true, message: '' },
    assignedUW: { valid: true, message: '' },
    effectiveDate: { valid: true, message: '' },
    quoteBy: { valid: true, message: '' },
    yearOfAccount: { valid: true, message: '' },
    state: { valid: true, message: '' },
    typeOfDocument: { valid: true, message: '' },
    broker: { valid: true, message: '' },
    brokerBranch: { valid: true, message: '' },
    brokerContact: { valid: true, message: '' },
    brokerContactEmail: { valid: true, message: '' },
    placingBasis: { valid: true, message: '' },
    justificationForEpiUpdate: { valid: true, message: '' },
    assured: { valid: true, message: '' },
    secondAssured: { valid: true, message: '' },
    policyReferenceNumber: { valid: true, message: '' },
    secondPolicyReferenceNumber: { valid: true, message: '' },
    totalNumberOfPolicyReferences: { valid: true, message: '' },
    masterPolicyReferenceNumber: { valid: true, message: '' },
    secondMasterPolicyReferenceNumber: { valid: true, message: '' },
    expiringPolicyReferenceNumber: { valid: true, message: '' },
    secondExpiringPolicyReferenceNumber: { valid: true, message: '' },
    comments: { valid: true, message: '' },
    assuredMiami: { valid: true, message: '' }
  },
  valid: false,
};
