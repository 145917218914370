import React, { useEffect, useCallback } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { MTGComponent, MTGSearchComponent } from '../MTG'

import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, Divider
} from '@material-ui/core';

import { DatePickerElement, DialogButton, Dropdown } from 'components/common';
import { checkIfRpaFlowIsRunning } from 'components/RpaDropzones/RpaDropzones.Api';
import { ModellingPriorities } from 'components/RpaDropzones/Helpers';
import { BannerType } from 'components/common/Banner';

import '../Popup.css';

export const SubmissionFieldNames = {
  Priority: 'Modelling Priority',
  DueDate: 'Modelling Due Date',
  Comments: 'Modelling Comments'
};

export const SubmissionDataPopup = ({ popupState, mTGRelatedPopup, setFormState, submit, submitArguments, formState, filterCurrentFromCandidates, setBannerState }) => {
  const { control, handleSubmit, formState: { errors } } = useForm({ mode: "onTouched" });

  useEffect(async () => {

    if (mTGRelatedPopup.majorClass != undefined && mTGRelatedPopup.majorClass.includes("Reinsurance")) {
      let rpaFlowState = await checkIfRpaFlowIsRunning({ businessId: mTGRelatedPopup.id, documentType: formState.droppedTile });
      if (rpaFlowState.success) {

        setFormState(previousState => ({
          ...previousState,
          mTGRelatedPopup: {
            ...previousState.mTGRelatedPopup,
            disableChanges: rpaFlowState.data
          }
        }));

        if (rpaFlowState.data) {
          setBannerState({
            type: BannerType.info,
            message: "Changing the relation of the deals is blocked until the RPA flow is finished",
            show: true
          });
        }
      }
    }

    setFormState(state => ({
      ...state,
      droppedTile: 'Submission Data',
      popup: { ...state.popup, show: true }
    }));
  }, []);

  const validateDate = date => {
    if (!date ||
      (date instanceof Date && !isNaN(date))) {
      return true;
    }

    return false;
  }

  const handleClose = useCallback(() => {
    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        isSearch: false
      },
      isModellingTask: false,
      popup: { ...state.popup, show: false },
      droppedTile: '',
      files: state.files.map(file => ({ ...file, isEngineering: undefined })),
      engineeringFilesSelected: false
    }));
  }, [setFormState]);

  const handleNotRequiredModelling = useCallback(event => {
    event.preventDefault();

    setFormState(state => ({
      ...state,
      popup: {
        data: {
          requiredModellingPriority: ModellingPriorities.NotRequired
        }
      }, show: false
    }));

    submit({ ...submitArguments });
  }, [setFormState, submit, submitArguments]);

  const onSubmit = useCallback(data => {
    setFormState(state => ({
      ...state,
      popup: {
        data: {
          requiredModellingPriority: data.priority,
          modellingDueDate: data.dueDate,
          modellingComments: data.comments
        },
        show: false,
        submit: true
      }
    }));
  }, [setFormState, submit, submitArguments]);

  useEffect(() => {
    if (formState.popup.submit) {
      submit({ ...submitArguments });
    }
  }, [formState.popup.submit]);

  const getPriorityValidationError = () => {
    let required = errors.priority?.type === 'required' &&
      <span role="alert">'{SubmissionFieldNames.Priority}' is required</span>;

    return required;
  }

  const getDueDateValidationError = () => {
    let required = errors.dueDate?.type === 'required' &&
      <span role="alert">'{SubmissionFieldNames.DueDate}' is required</span>;

    let validDate = errors.dueDate?.type === 'validDate' &&
      <span role="alert">'{SubmissionFieldNames.DueDate}' is not valid</span>;

    let disablePastDate = errors.dueDate?.type === 'disablePastDate' &&
      <span role="alert">'{SubmissionFieldNames.DueDate}' must be not earlier than today</span>;

    return required || validDate || disablePastDate;
  }

  const renderForm = () => {
    return <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <DialogTitle id="submission-data-popup-title">Submission Data - Modelling</DialogTitle>
        <Divider style={{ backgroundColor: "#1FB6AD", padding: "0.04rem" }} />
        <DialogContent>
          <Controller
            name="priority"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) =>
              <FormControl fullWidth error={!!errors.priority}>
                <Dropdown
                  id="priority"
                  name="priority"
                  testId="priority-dropdown"
                  label={SubmissionFieldNames.Priority}
                  data={[ModellingPriorities.Low, ModellingPriorities.Medium, ModellingPriorities.High]}
                  errorText={getPriorityValidationError()}
                  field={field}
                />
              </FormControl>
            }
          />
          <Controller
            name="dueDate"
            control={control}
            defaultValue={null}
            rules={
              {
                required: true,
                validate: {
                  validDate: value => validateDate(value),
                  disablePastDate: value => {
                    let currentDateTime = new Date();
                    let currentDate = new Date(
                      currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate());

                    return value >= currentDate;
                  }
                }
              }}
            render={({ field: { ref, ...rest } }) =>
              <DatePickerElement
                id="Modelling Due Date"
                label={SubmissionFieldNames.DueDate}
                name="dueDate"
                data-testid="dueDate-date-picker"
                format="MM/dd/yyyy"
                disablePast={true}
                fullWidth
                error={!!errors.dueDate}
                helperText={getDueDateValidationError()}
                {...rest}
              />
            }
          />
          <Controller
            name="comments"
            control={control}
            defaultValue=""
            rules={{ required: false }}
            render={({ field: { ref, ...rest } }) =>        
                <TextField
                 id="comments"
                 name="comments"
                 label={SubmissionFieldNames.Comments}                 
                 multiline
                 maxRows={6}
                 fullWidth
                 {...rest}
                />
            }
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="submit" data-testid="submit-button">Ok</DialogButton>
        </DialogActions>
        <Divider style={{ backgroundColor: "#1FB6AD", padding: "0.04rem", marginTop: "0.5rem" }} />
        <DialogActions style={{ justifyContent: "center" }}>
          <DialogButton
            className="modelling-not-required-dialog-button"
            data-testid="modellingNotRequired-button"
            onClick={handleNotRequiredModelling}
            styles={{
              backgroundColor: "#FF3737",
              color: "white",
              margin: "0.5rem 1rem 0.5rem 1rem",
              width: "100%",
              hover: { color: "#FF3737", borderColor: "#FF3737" }
            }}
          >
            Modelling Not Required
          </DialogButton>
        </DialogActions>
      </form>
      {mTGRelatedPopup.majorClass !== undefined && !mTGRelatedPopup.majorClass.includes("Insurance") ?
        <MTGComponent
          mTGRelatedPopup={mTGRelatedPopup}
          setFormState={setFormState}
          formState={formState}
          allowUpdate={false}
          filterFromCurrent={filterCurrentFromCandidates}
        />
        : ''
      }
    </>
  }

  return (
    <Dialog open={popupState?.show} onClose={handleClose} aria-labelledby="submission-data-popup-title"
      fullWidth maxWidth={mTGRelatedPopup?.isSearch ? "md" : "sm"}  >
      {mTGRelatedPopup.isSearch ?
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {renderForm()}
          </Grid>
          <Grid item xs={6}>
            <MTGSearchComponent
              mTGRelatedPopup={mTGRelatedPopup}
              setFormState={setFormState}
              filterFromCurrent={filterCurrentFromCandidates} />
          </Grid>
        </Grid>
        : renderForm()
      }
    </Dialog>
  );
}