import React, { useCallback } from 'react';
import clsx from 'clsx';

import { Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DialogButton } from 'components/common';

const useStyles = makeStyles({
  divider: {
    backgroundColor: "#1FB6AD",
    padding: "0.04rem"
  },
  dialogActions: {
    justifyContent: "center"
  }
});

const defaultConfig = {
  cancelButtonLabel: "Cancel",
  confirmButtonLabel: "Confirm"
}

export const ConfirmationDialog = ({
  isOpened,
  title,
  content,
  onConfirm,
  config,
  setIsOpened,
  onClose
}) => {
  const classes = useStyles();

  const cancelButtonLabel = config?.cancelButtonLabel ?? defaultConfig.cancelButtonLabel;
  const confirmButtonLabel = config?.confirmButtonLabel ?? defaultConfig.confirmButtonLabel;

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setIsOpened(false);
  }, [onClose, setIsOpened]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    setIsOpened(false);
  }, [onConfirm, setIsOpened]);

  const renderTitle = () => {
    let render = !!title;
    return render && <>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <Divider className={classes.divider} />
    </>;
  }

  const renderButtons = () => {
    if (config?.warning) {
      return <DialogButton onClick={handleClose}>{cancelButtonLabel}</DialogButton>;
    }
    else {
      return <>
        <DialogButton onClick={handleClose}>{cancelButtonLabel}</DialogButton>
        <DialogButton onClick={handleConfirm}>{confirmButtonLabel}</DialogButton>
      </>;
    }
  }

  return (
    <Dialog open={isOpened} onClose={handleClose} aria-labelledby="confirmation-dialog-title">
      {renderTitle()}
      <DialogContent>{content}</DialogContent>
      <DialogActions className={clsx("dialog-buttons", classes.dialogActions)}>
        {renderButtons()}
      </DialogActions>
    </Dialog>
  );
}