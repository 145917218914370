import React, { useEffect, useMemo } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { DefaultFormType, FormsTypes } from 'star-ai';
import { AppContext } from 'AppContext';
import { Area } from 'Helpers';

const AIAreaProvider: React.FC = () => {
  const { formType } = useParams();
  const navigate = useNavigate();

  const area = useMemo(() => {
    switch (formType) {
      case FormsTypes.Select:
        return Area.HamiltonSelect;
      case FormsTypes.International:
        return Area.HamiltonInternational;
      default:
        return null;
    }
  }, [formType]);

  useEffect(() => {
    if (!formType) {
      navigate(DefaultFormType);
    }
  }, [formType, navigate]);

  return (
    <AppContext.Provider value={{ area }}>
      <Outlet />
    </AppContext.Provider>
  );
};

export default AIAreaProvider;
