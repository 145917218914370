import React, { forwardRef, useEffect } from 'react';
import { Select, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';

export const Dropdown = forwardRef((props, ref) => {
  useEffect(() => {
    if (!props.shouldSetDefaultValue || props.emptyValue) return;

    if (props.data.length === 1 && props.value !== props.data[0]) {
      props.onChange({
        target: { name: props.name, value: props.data[0] }
      });
    }
  }, [props, props.data]);

  const activeRef = props.inputRef || ref;
  
  return <>
    {!props.hideLabel && <InputLabel id={props.label} required={props.required} shrink={props.emptyValue}>{props.label}</InputLabel>}
    <Select
      labelId={props.label}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled}
      data-testid={props.testId}
      displayEmpty={props.emptyValue}
      inputRef={activeRef}
      {...props.field}
    >
      {props.emptyValue && <MenuItem key={-1} value=""><em>None</em></MenuItem>}
      {
        props.data.map((row, index) => {
          return (
            <MenuItem key={index} value={row}>{row}</MenuItem>
          );
        })
      }
    </Select>
    <FormHelperText>{props.errorText}</FormHelperText>
  </>;
});