import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DateFnsUtils from "@date-io/date-fns";
import './DragAndDropArea.css';
import { checkIfRpaFlowIsRunning } from '../RpaDropzones.Api';
import { Banner, BannerType } from "../../common";
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    size: 'small',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export const DragAndDropArea = (
  {
    text,
    onFileDrop,
    classes,
    onDragClasses,
    showModdelingLabel,
    isRelated,
    modellingTask,
    setFormState,
    majorClass,
    declined,
    isLocked,
    businessId
  }) => {
  const [onHoverDragArea, setOnHoverDragArea] = useState(false);
  const [bannerState, setBannerState] = useState({});
  const submissionData = 'Submission Data';

  const chipClasses = useStyles();

  const getFormattedDate = date => {
    if (!date) {
      return null;
    }

    let utils = new DateFnsUtils();
    return utils.format(date, "MM-dd-yy");
  }

  const onDrop = async event => {
    event.preventDefault();
    event.stopPropagation();

    setOnHoverDragArea(false);
    onFileDrop(event);
  };

  const onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
    setOnHoverDragArea(true);
  };

  const onDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    setOnHoverDragArea(false);
  };

  const checkRpaState = async () => {
    if (text == submissionData) {
      let rpaFlowState = await checkIfRpaFlowIsRunning({ businessId: businessId, documentType: text });

      if (rpaFlowState.success) {
        setFormState(previousState => ({
          ...previousState,
          mTGRelatedPopup: {
            ...previousState.mTGRelatedPopup,
            disableChanges: rpaFlowState.data
          }
        }));

        if (rpaFlowState.data) {
          setBannerState({
            type: BannerType.info,
            message: "Changing the relation of the deals is blocked until the RPA flow is finished",
            show: true
          });
        }
      }
    }
  };

  const renderDropDown = () => {
    let hideRelatedMtg = majorClass !== undefined && majorClass.includes("Insurance");
    if (text === submissionData) {
      if (!showModdelingLabel && hideRelatedMtg) {
        return <p className="in-middle">{text}</p>
      }
      else {
        return <>
          <Grid container spacing={2} >
            <Grid item xs={6}>
              <p className="in-middle">{text}</p>
            </Grid>
            <Grid item xs={6}>
              <div className={chipClasses.root}>
                {renderModellingTask()}
                {hideRelatedMtg ? '' : renderRelatedMTG()}
              </div>
            </Grid>
          </Grid>
        </>
      }
    }
    else {
      return <p className="in-middle">{text}</p>
    }
  }

  const handleModellingTaskClick = useCallback(() => {
    setFormState(previousState => ({
      ...previousState,
      isModellingTask: true,
      engineeringFilesSelected: true
    }));
  }, [setFormState]);

  const getMTGRelatedData = useCallback(async () => {

    await checkRpaState();

    setFormState(previousState => ({
      ...previousState,
      mTGRelatedPopup: {
        ...previousState.mTGRelatedPopup,
        show: true
      }
    }));
  }, [setFormState]);


  const renderModellingTask = () => {
    if (showModdelingLabel) {
      if (modellingTask) {
        return <Chip size="small" label={`${modellingTask.priority} / ${getFormattedDate(new Date(modellingTask.date))}`} style={{ backgroundColor: '#00BFFF' }} />
      }
      else {
        let preventModelling = (majorClass == "Casualty Reinsurance" || majorClass == "Casualty Insurance" || declined || isLocked)
        return <Chip size="small" label="Modelling not required " onClick={preventModelling ? '' : handleModellingTaskClick} style={{ backgroundColor: '#FA8072' }} />
      }
    }
  }

  const renderRelatedMTG = () => {
    if (isRelated) {
      return <Chip size="small" label='MTG Related' onClick={getMTGRelatedData} style={{ backgroundColor: '#7FFFD4' }} />
    }
    else {
      return <Chip size="small" label="MTG NOT Related +" onClick={getMTGRelatedData} style={{ backgroundColor: '#D3D3D3' }} />
    }
  }

  const applyStyles = () => {
    const dragDropAreaDefaultClass = "drag-drop-zone";
    const onDragDefaultClass = "drag-drop-zone-on-drag";

    let resultClasses = classes ? classes : dragDropAreaDefaultClass;

    if (onHoverDragArea) {
      return `${resultClasses} ${onDragClasses ? onDragClasses : onDragDefaultClass}`;
    }

    return resultClasses;
  }

  return (
    <div
      className={applyStyles()}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}>
      {renderDropDown()}

      <Banner
        type={bannerState.type}
        message={bannerState.message}
        show={bannerState.show}
        expose={bannerState.expose}
        showBanner={show => setBannerState({
          ...bannerState,
          show: show
        })}
      ></Banner>
    </div>
  );
};