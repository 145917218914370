export const FileUploadStatus = {
  notStarted: "notStarted",
  inProgress: "inProgress",
  success: "success",
  failed: "failed"
};

export const FileType = {
  document: "Document",
  message: "Message"
};

export const ProducingTeam = {
  London: "London",
  NewYork: "New York MGA",
  Miami: "Miami MGA",
  Bermuda: "Bermuda",
  Shanghai: "Shanghai",
  Dubai: "Dubai",
  HamiltonSelect: "Hamilton Select"
};

export const Area = {
  HamiltonInternational: "HamiltonInternational",
  HamiltonRe: "HamiltonRe",
  HamiltonSelect: "HamiltonSelect"
};

export const ChunkSize = {
  chunk: 28311552 // max default file size for uploading (.NET core web app)
};