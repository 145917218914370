import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

import { ComponentController, DialogButton } from 'components/common';
import { GeneralDetailsFields } from '../Helpers';

const brokerContactMaxLength = 50;

export const BrokerContactPopup = ({
  popupState,
  setPopupState,
  setFormState,
  setValidationValue
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: { brokerContact: popupState.newValue }
  });

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onSubmit = useCallback(async popupData => {
    handleClose();

    let brokerContact = {
      brokerContactId: -1,
      brokerContact: popupData.brokerContact
    };

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        brokerContact: brokerContact
      }
    }));

    setValidationValue("brokerContact", brokerContact, { shouldValidate: true });
  }, [handleClose, setFormState, setValidationValue]);

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {brokerContactMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="broker-contact-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="broker-contact-dialog-title">Add a new Broker Contact</DialogTitle>
        <DialogContent>
          <ComponentController
            name="brokerContact"
            control={control}
            rules={{ maxLength: brokerContactMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};