import React, { useEffect, useCallback, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Divider, FormControl, FormControlLabel, makeStyles
} from '@material-ui/core';
import { DialogButton, MultiSelectDropdown, CheckboxElement } from 'components/common';
import { isZip } from 'components/RpaDropzones/Helpers';

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    marginLeft: "5px",
    fontSize: "14px"
  },
}));

export const SelectFoldersPopup = ({ popupState, setFormState, submit, submitArguments, formState }) => {
  const classes = useStyles();
  const [missingFileNames, setMissingFileNames] = useState([]);
  const [needsValidation, setNeedsValidation] = useState(true);
  const [defaultValues, setDefaultValues] = useState([]);
  const [foldersToAll, setFoldersToAll] = useState([]);
  const [applyToAllChecked, setApplyToAllChecked] = useState(false);

  useEffect(() => {
    setFormState(state => ({
      ...state,
      popup: { ...state.popup, show: true }
    }));
  }, []);

  const handleClose = useCallback(() => {
    setFormState(state => ({
      ...state,
      popup: { ...state.popup, show: false, data: {} },
      droppedTile: ''
    }));
  }, [setFormState]);

  const tile = formState.tiles.find(e => e.content === formState.droppedTile);

  const validate = () => {
    const fileNames = formState.fileNames
      .filter(({ name }) => !formState.popup.data.folders || !formState.popup.data.folders[name] || formState.popup.data.folders[name].length == 0)
      .map(({ name }) => name);
    setMissingFileNames(fileNames);
    setNeedsValidation(false);
    return fileNames.length === 0;
  }

  useEffect(() => {
    if (needsValidation) {
      validate();
    }
  }, [missingFileNames, formState.popup.data.folders, needsValidation]);

  const handleChange = useCallback((fileName, folderName) => {
    setFormState(state => ({
      ...state,
      popup: {
        ...state.popup,
        data: {
          folders: {
            ...state.popup?.data?.folders,
            [fileName]: folderName
          }
        }
      }
    }));
    setNeedsValidation(true);
    setApplyToAllChecked(false);    
  }, [setFormState]);

  const handleSubmit = useCallback(() => {
    if (!validate()) {
      return;
    }
    submit(submitArguments);
    setFormState(state => ({
      ...state,
      popup: { ...state.popup, show: false }
    }));
  }, [setFormState, submit, submitArguments]);

  const handleApplyToAll = useCallback((checked, foldersToAll) => {
    if (checked) {

      setDefaultValues([...foldersToAll]);
      
      for (var i = 0; i <= formState.files.length - 1; i++) {

        var fileName = formState.files[i].name;
        if (fileName) {
          handleChange(fileName, foldersToAll);
        }      

        if (formState.files[i].attachments) {
          for (var j = 0; j <= formState.files[i].attachments.length - 1; j++) {

            var attachmentName = formState.files[i].attachments[j].name;        

            if (attachmentName) {
              handleChange(attachmentName, foldersToAll);
            }
          }
        }
      }
    }

    setApplyToAllChecked(checked);

  }, [setFormState, setDefaultValues]);

  const handleApplyToAllChange = (folders) => {
    setFoldersToAll(folders);
    setApplyToAllChecked(false);
  };

  const renderFiles = (files, parentId = null, indent = 0) => {
    const children = files.filter(file => file.parentId === parentId || (!parentId && !file.parentId));
    return (
      <>

        {children.map(file => (<div>
          <div key={file.id} style={{ paddingLeft: `${indent}rem`, display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '40%' }}>
              {file.name}
            </div>

            <FormControl key={file.id} error={missingFileNames.includes(file.name)} style={{ width: '30%' }}>
              <MultiSelectDropdown
                key={file.name}
                errorText={missingFileNames.includes(file.name) ? 'Required' : ''}
                data={tile.folders ?? []}
                onChange={(selected) => handleChange(file.name, selected)}
                disabled={(isZip(file.name) && !tile.keepZipFile)}
                defaultValues={defaultValues}
              />
            </FormControl>
          </div>
          {file.attachments?.map(({ name }) => (
            <div key={file.id} style={{ paddingLeft: `${indent}rem`, display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '40%' }}>
                {name}
              </div>

              <FormControl key={name + "_FormControl"} error={missingFileNames.includes(name)} style={{ width: '30%' }}>
                <MultiSelectDropdown
                  key={name + "_MultiSelectDropdown"}
                  errorText={missingFileNames.includes(name) ? 'Required' : ''}
                  data={tile.folders ?? []}
                  onChange={(selected) => handleChange(name, selected)}
                  disabled={(isZip(name) && !tile.keepZipFile)}
                  defaultValues={defaultValues}
                />
              </FormControl>
            </div>
          ))}
          { file.id ? renderFiles(files, file.id, indent + 1) : null }
        </div>
        ))
        }

      </>
    );
  };

  return (
    <Dialog open={popupState?.show} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent style={{ height: "90px", padding: "10px", overflowY: "unset" }}>
        <DialogTitle style={{ display: "inline-flex", width: "35%" }}>{tile.content}</DialogTitle>
        <FormControl style={{ width: '30%' }}>
          <MultiSelectDropdown
            data={tile.folders ?? []}
            onChange={(selected) => handleApplyToAllChange(selected)}
            errorText=""
          />
        </FormControl>
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          style={{ fontSize: "16px", marginLeft: "5px" }}
          control={
            <CheckboxElement
              style={{ fontSize: "16px", marginLeft: "5px" }}              
              edge="end"
              onChange={(_event, checked) => handleApplyToAll(checked, foldersToAll)}
              checked={applyToAllChecked}
            />
          }
          label="Apply To All"
          labelPlacement="end"
        >
        </FormControlLabel>
      </DialogContent>
      <Divider style={{ backgroundColor: "#1FB6AD", padding: "0.04rem" }} />
      <DialogContent>
        {renderFiles(formState.files)}
      </DialogContent>
      <DialogActions className="dialog-buttons">
        <DialogButton onClick={handleClose}>Cancel</DialogButton>
        <DialogButton onClick={handleSubmit}>Confirm</DialogButton>
      </DialogActions>
    </Dialog>
  )
}