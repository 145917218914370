import React, { useCallback, useEffect } from "react";
import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { Description, Error } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

import { BannerType, Spinner } from "components/common";
import { FileUploadStatus, ChunkSize, uploadFileByChunks } from "Helpers";

const DocumentFile = ({ document, setFiles, uploadFile, setBannerState }) => {
  useEffect(() => {
    let onUploadArguments = { fileId: document.id, fileName: document.file.name };

    setFiles(prevFiles => {
      let updatedFiles = prevFiles.map(file => {
        if (file.id === document.id && file.upload?.status === FileUploadStatus.notStarted) {
          let uploadPromise = document.content.byteLength > ChunkSize.chunk
            ? uploadFileByChunks(document, uploadFile, onUpload, onUploadArguments)
            : uploadDocument();
    
          let updatedUpload = { status: FileUploadStatus.inProgress, promise: uploadPromise };
          return { ...file, upload: updatedUpload };
        }
        return file;
      });
      return updatedFiles;
    });
  }, []);

  const onUpload = ({ fileId, fileName, uploadPath }) => {
    let success = !!uploadPath;

    setFiles(prevFiles => {
      let updatedFiles = prevFiles.map(file => {
        if (file.id === fileId) {
          let updatedUpload = {
            ...file.upload,
            status: success ? FileUploadStatus.success : FileUploadStatus.failed,
            path: uploadPath
          };
          return { ...file, upload: updatedUpload };
        }
        return file;
      });
      return updatedFiles;
    });

    let bannerType = success ? BannerType.success : BannerType.error;
    let message = success ? `${fileName} is uploaded successfully.` : "Error occurred. File is not uploaded";
    setBannerState({ show: true, type: bannerType, message });
  }

  const uploadDocument = async () => {
    let fileName = document.file.name;
    let fileId = document.id;

    let response = await uploadFile(fileName, document.content);

    if (response.success) {
      onUpload({ fileId, fileName, uploadPath: [response.uploadPath] });
    } else {
      onUpload({ fileId, fileName });
      throw response.error;
    }
  }

  const handleDocumentFileDeleted = useCallback(id => {
    setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
  }, [setFiles]);

  const renderDocumentIcon = () => {
    if (document.upload.status === FileUploadStatus.inProgress) {
      return <ListItemIcon><Spinner size="1.5rem" /></ListItemIcon>;
    }

    if (document.upload.status === FileUploadStatus.success) {
      return <ListItemIcon><Description /></ListItemIcon>;
    }

    if (document.upload.status === FileUploadStatus.failed) {
      return <ListItemIcon><Error color="error" /></ListItemIcon>;
    }
  };

  return (
    <List component="div" disablePadding>
      <ListItem>
        {renderDocumentIcon()}
        <ListItemText primary={document.file.name} className="overflow-wrap-anywhere" />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={() => handleDocumentFileDeleted(document.id)} >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export default DocumentFile;
