import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { getAppConfig } from 'config.js';
import {
  HWCProvider,
  Dashboard,
  DashboardProps,
  SelectFormFieldsToAutoPopulate,
  SelectFormFieldsLabels,
  MintFormFieldsToAutoPopulate,
  MintFormFieldsLabels,
  MintFormInitialValues,
  MintForm,
  FormsTypes,
  DefaultFormType,
  InternationalFormFieldsToAutoPopulate,
  InternationalFormFieldsLabels
} from 'star-ai';
import 'star-ai/dist/style.css';
import { getAuthorizationProvider } from 'Authentication';
import { authorizedApiClient, fetchCurrentUser } from 'Api';
import { initialFormState as SelectFormInitialValues } from 'components/SubmissionForm/State';
import {
  Form as SelectForm,
  SubmissionWizard as InternationalForm,
  initialValuesForStarAi as InternationalFormInitialValues,
} from 'components';

const getValueBasedOnType = <M, S, I>(
  formType: FormsTypes | string,
  mintValue: M,
  selectValue: S,
  intValue: I,
) => {
  if (formType === FormsTypes.Select) {
    return selectValue;
  }
  if (formType === FormsTypes.International) {
    return intValue;
  }

  return mintValue;
};

interface AITimeflowProps {
  setLoading: boolean;
  children: NonNullable<React.ReactNode>;
}

const AITimeflow: React.FC<AITimeflowProps> = ({ children, setLoading }) => {
  // Pick up the form
  const { formType = DefaultFormType } = useParams();
  const [pageTitle, form, initialValues, fieldsToAutoPopulate, fieldsLabels] =
    useMemo(
      () => [
        `${formType.charAt(0).toUpperCase()}${formType.slice(1)} AI`,
        getValueBasedOnType(
          formType,
          <MintForm />,
          <SelectForm setLoading={setLoading} />,
          <InternationalForm setLoading={setLoading} />,
        ),
        getValueBasedOnType(
          formType,
          MintFormInitialValues,
          SelectFormInitialValues,
          InternationalFormInitialValues,
        ),
        getValueBasedOnType(
          formType,
          MintFormFieldsToAutoPopulate,
          SelectFormFieldsToAutoPopulate,
          InternationalFormFieldsToAutoPopulate,
        ),
        getValueBasedOnType(
          formType,
          MintFormFieldsLabels,
          SelectFormFieldsLabels,
          InternationalFormFieldsLabels,
        ),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [children, formType],
    );

  // Init API
  const [user, setUser] = useState<DashboardProps<unknown>['userAccount']>();
  const [apiSubmissionsUrl, setApiSubmissionsUrl] = useState('');
  const [apiMintClientsUrl, setApiMintClientsUrl] = useState('');
  const apiAiTimeflowUrl = 'api/aiprocessing';

  const fetchApiUrls = async () => {
    try {
      const apiSubmissionsUrlResponse = await authorizedApiClient.get(
        `${apiAiTimeflowUrl}/apiUrl`,
      );
      const apiMintClientsUrlResponse = await authorizedApiClient.get(
        `${apiAiTimeflowUrl}/mintclientsApiUrl`,
      );
      return [
        apiSubmissionsUrlResponse.data.apiUrl,
        apiMintClientsUrlResponse.data.apiUrl,
      ];
    } catch (error) {
      console.error(error);
      return ['', ''];
    }
  };

  // Init User
  useEffect(() => {
    fetchCurrentUser().then((azureUser) => {
      const onSignOut = async () => {
        var provider = await getAuthorizationProvider();
        provider.logout();
      };

      const isDevEnv = process.env.NODE_ENV !== 'production';
      const isDevStage =
        window.location.hostname.indexOf('-dev-') > 0 ||
        window.location.hostname.indexOf('-stg-') > 0;

      const isAdmin = isDevEnv || isDevStage; // admin access locally

      setUser(() => {
        const { mail: email, id, displayName: name } = azureUser;

        return {
          id,
          email,
          name,
          isAdmin,
          onSignOut,
        };
      });
    });

    fetchApiUrls().then(([apiS, apiM]) => {
      setApiSubmissionsUrl(apiS);
      setApiMintClientsUrl(apiM);
    });
  }, []);

  if (!user || !apiSubmissionsUrl || !apiMintClientsUrl || !formType) {
    return null;
  }

  const appConfig = getAppConfig();

  return (
    <HWCProvider>
      <Dashboard
        pageTitle={pageTitle}
        routes={{
          records: `/ai/${DefaultFormType}/records`,
          form: `/ai/${FormsTypes.Dynamic}/form`
        }}
        httpClient={authorizedApiClient}
        apiSubmissionsUrl={apiSubmissionsUrl}
        apiMintClientsUrl={apiMintClientsUrl}
        userAccount={user}
        initialValues={initialValues}
        fieldsToAutoPopulate={fieldsToAutoPopulate}
        fieldsLabels={fieldsLabels}
        dateInputFormat="MM/dd/yyyy"
        hasDotsIndicator={false}
        environmentName={appConfig.environment?.toLowerCase() || 'production'}
      >
        {form}
      </Dashboard>
    </HWCProvider>
  );
};

export default AITimeflow;
