export const ModellingPriorities = {
  NotRequired: "No Modelling Required",
  Low: "Low",
  Medium: "Medium",
  High: "High"
};

export const QueryParametersConstants = {
  Entity: "entity",
  MajorClass: "majorClass",
  ModellingPriority: "modellingPriority",
  DealName: "dealName",
  Yoa: "yoa",
  Cedent: "cedent",
  UnderwriterEmail: "uwEmail",
  BusinnesId: "businessId",
  IsInitialCasualtyRequest: "IsInitialCasualtyRequest"
};

export const TilesNames = {
  SubmissionData: "Submission Data",
  Decline: "Decline",
  FirmOrderTerms: "Firm Order Terms",
  Draft: "Draft or Firm Order Contract",
  Authorization: "Authorization - Signed",
  SignedLines: "Signed Lines",
  ILSigned: "I&L Signed",
  FinalContract: "Final Contract Wording or Signed Slip",
  ClientSigned: "Client Signed",
  Endorsement: "Endorsement or Addendum",
  Quotes: "Quotes",
  Binders: "Binders",
  FinalPolicy: "Final Policy Docs",
  Endorsements: "Endorsements"
};

export const ChunkSize = {
  chunk: 28311552 // max default file size for uploading (.NET core web app)
};

export const FileUploadStatus = {
  notStarted: "notStarted",
  inProgress: "inProgress",
  success: "success",
  failed: "failed"
};

export const FileType = {
  document: "Document",
  message: "Message"
};