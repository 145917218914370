import React, { useCallback } from 'react';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export const BannerType = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info"
};

export const Banner = (props) => {
  const showBanner = props.showBanner;

  const onClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    showBanner(false);
  }, [showBanner]);

  return (
    <Snackbar
      open={props.show}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      autoHideDuration={props.expose ? null : 5000}
    >
      <MuiAlert onClose={onClose} severity={props.type} variant="filled">
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
};