export const QueryParametersConstants = {
  PolicyNo: "policy_reference_number",
  KissflowId: "instance_id",
  BrokerId: "Broker_ID",
  BrokerBranchId: "Broker_Branch_ID",
  BrokerContactId: "Broker_Contact_ID",
  BrokerContactEmail: "Broker_Contact_Email",
  Insured: "Insured_Name",
  AdressLine1: "Addr_Ln_1",
  AdressLine2: "Addr_Ln_2",
  AdressLine3: "Addr_Ln_3",
  City: "City",
  State: "State",
  ZipCode: "Zip",
  EffectiveDate: "Eff_Date",
  ProcessFlow: "process_ID",
  ProductId: "product_ID"
};