import React from 'react';

import { OrganisationTypes, PlacingBases } from 'components/SubmissionWizard/Helpers';

import { PolicyReferenceControl } from './PolicyReferenceControl';

export const DefaultSecondMasterPolicyReferenceControlName = "_secondMasterAssuredOrMasterReassured";

export const SecondMasterPolicyReferenceControl = ({
  entities,
  fields,
  control,
  errors,
  lookupType,
  forceClearDatasourceState,
  disabled,
  setBannerState,
  setLoading,
  setFormState,
  setValidationValue,
  inputRef
}) => {
  const getAssured = (_, assured) => {
    let masterAssuredData = {
      [DefaultSecondMasterPolicyReferenceControlName]: assured?.assured ?? '',
      secondMasterAssured: assured?.assured ?? '',
      secondMasterPolicyId: assured?.policyId ?? null,
      secondMasterPolicyReferenceNumber: assured?.policyReferenceNumber ?? '',
      secondUmr: assured?.umr ?? ''
    };

    if (assured?.assuredType.toLowerCase() === OrganisationTypes.Reassured) {
      masterAssuredData.secondMasterReassured = assured?.assured;
      masterAssuredData.secondMasterAssured = '';
    } else {
      masterAssuredData.secondMasterReassured = '';
      masterAssuredData.secondMasterAssured = assured?.assured;
    }

    return masterAssuredData;
  };

  const requestData = {
    businessEntities: entities,
    year: fields.yoa,
    placingBases: [
      PlacingBases.Binder,
      PlacingBases.BinderNonProportionalRI,
      PlacingBases.BinderProportionalRI,
      PlacingBases.ConsortiaNonProportionalRI,
      PlacingBases.ConsortiaProportionalRI,
      PlacingBases.LineslipNonProportionalRI,
      PlacingBases.LineslipProportionalRI,
      PlacingBases.LineSlip,
      PlacingBases.MasterBinder,
      PlacingBases.Consortium,
      PlacingBases.FacultativeCover,
      PlacingBases.ReportingReinsuranceMaster,
      PlacingBases.NonProportionalReportingReinsuranceMaster,
      PlacingBases.ProportionalReportingReinsuranceMaster
    ]
  };

  return (<PolicyReferenceControl
    controlName={DefaultSecondMasterPolicyReferenceControlName}
    getValue={fieldData => fieldData[DefaultSecondMasterPolicyReferenceControlName]}
    fields={fields}
    control={control}
    errors={errors}
    lookupType={lookupType}
    requestData={requestData}
    forceClearDatasourceState={forceClearDatasourceState}
    setBannerState={setBannerState}
    setLoading={setLoading}
    setFormState={setFormState}
    setValidationValue={setValidationValue}
    getAssuredData={getAssured}
    disabled={disabled}
    inputRef={inputRef}
  />);
}