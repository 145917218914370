import * as JSZip from 'jszip';

export class FileBlob extends Blob {
  constructor(data, options, id, name, parentId) {
    super(data, options);
    this.id = id;
    this.name = name;
    this.parentId = parentId;
  }
}

export const inspectZipFile = async (zipFile, zipName) => {
  const zip = await JSZip.loadAsync(zipFile);
  const fileBlobs = [];

  async function processZipItem(item, parentId) {
    const itemName = item.name;
    const itemExtension = itemName
      .slice(itemName.lastIndexOf('.'))
      .toLowerCase();
    const baseName = itemName.slice(itemName.lastIndexOf('/') + 1);
    const fullName = parentId.replace(/\.zip/g, "") + '/' + baseName;

    if (itemName.toUpperCase().startsWith('__MACOSX')) {
      // Skip entries with names that start with '__MACOSX'
      return;
    }

    if (item.dir) {
      // Process items in the directory
      return;
    } else if (itemExtension === '.zip') {
      // Process nested zip file
      const nestedZipData = await item.async('uint8array');
      // Add zip blob
      
      const zipBlob = new FileBlob([nestedZipData], { type: item.options.mimeType }, parentId + '/' + itemName, fullName, parentId);
      fileBlobs.push(zipBlob);

      const nestedZip = await JSZip.loadAsync(nestedZipData);

      for (const [nestedPath, nestedItem] of Object.entries(nestedZip.files)) {
        await processZipItem(nestedItem, parentId + '/' + itemName);
      }
    } else {
      const fileContent = await item.async('uint8array');
      if (fileContent.byteLength > 0) {
        const fileBlob = new FileBlob([fileContent], { type: item.options.mimeType }, parentId + '/' + itemName, fullName, parentId);
        fileBlobs.push(fileBlob);
      }
    }
  }

  for (const [path, item] of Object.entries(zip.files)) {
    await processZipItem(item, zipName);
  }

  return fileBlobs;
}

export const isZip = (filename) => {
  return filename.endsWith(".zip");
}
