import React, { useCallback } from 'react';
import { FormControl, Grid, TextField } from '@material-ui/core';
import { useStarAiContext } from 'star-ai';

import { ComponentController, Dropdown } from 'components/common';
import {
  isDualStampEntity, isMajorClassRequiredModelling,
  validatePlacingBasis, GeneralDetailsFields, resolveS4000EntityName, TotalNumberOfPolicyReferencesName
} from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';

const BulkItemSection = ({
  fields,
  dropdownsData,
  initialFormState,
  control,
  errors,
  setValidationValue,
  setFormState
}) => {

  // star ai
  const ai = useStarAiContext();

  const onEntityChange = useCallback(async event => {
    var selectedValue = event.target.value;

    if (selectedValue !== '') {
      let entityData = Object.values(dropdownsData.entities).find(x => x.displayName === selectedValue);
      let entityValue = entityData.name;
      let currentMajorClasses = {};
      if (entityData.parentEntities) {
        for (var i = 0; i < entityData.parentEntities.length; i++) {
          currentMajorClasses[entityData.parentEntities[i]] = "";
        }
      } else {
        currentMajorClasses[entityValue] = "";
      }

      let isDualStamp = isDualStampEntity(entityValue);
      let majorClassesList = entityData.parentEntities
        ? dropdownsData.entities[entityData.parentEntities[0]].majorClasses
        : entityData.majorClasses;
      let years = [...entityData.years].sort().reverse();
      let totalNumberOfPolicyReferences = entityData.defaultTotalNumberOfPolicyReferences || 1;
      let yoa = initialFormState.fields.yoa;

      setFormState(previous => ({
        ...previous,
        fields: {
          ...initialFormState.fields,
          ...previous.fields,
          businessEntity: entityValue,
          firstMajorClass: initialFormState.fields.firstMajorClass,
          majorClasses: currentMajorClasses,
          yoa,
          isDualStamp,
          secondYoa: isDualStamp ? yoa : null,
          assured: { assured: previous.fields.documentType },
          secondAssured: isDualStamp
            ? { assured: previous.fields.documentType }
            : null,
          totalNumberOfPolicyReferences
        },
        dropdownsData: {
          ...previous.dropdownsData,
          majorClasses: majorClassesList ? majorClassesList : [],
          years: years ? years : []
        }
      }));

      let _ = entityData.parentEntities?.forEach(
        entity => setValidationValue(`${GeneralDetailsFields.majorClass} (${entity})`, ""));

      setValidationValue("majorClass", "");
      setValidationValue("yoa", yoa);
      setValidationValue("totalNumberOfPolicyReferences", totalNumberOfPolicyReferences, { shouldValidate: true });
    }
  }, [dropdownsData, initialFormState, setFormState, setValidationValue]);

  const onFirstMajorClassChange = useCallback((value, entity, currentMajorClasses) => {
    currentMajorClasses[entity.parentEntities[0]] = value;

    let firstMajorClass = currentMajorClasses[entity.parentEntities[0]];
    let requiresModelling = isMajorClassRequiredModelling(firstMajorClass, entity.name, dropdownsData)
      ? dropdownsData.modellingPriorities[0] : '';

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        firstMajorClass,
        majorClasses: currentMajorClasses,
        requiredModellingPriority: requiresModelling
      }
    }));
  }, [dropdownsData, setFormState]);

  const onSecondMajorClassChange = useCallback((value, entity, parentEntity, currentMajorClasses) => {
    currentMajorClasses[parentEntity] = value;
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        firstMajorClass: currentMajorClasses[entity.parentEntities[0]],
        majorClasses: currentMajorClasses
      }
    }));
  }, [setFormState]);

  const onMajorClassChange = useCallback((event, parentEntity, index) => {
    let value = event.target.value;
    let entity = dropdownsData.entities[fields.businessEntity];
    let currentMajorClasses = { ...fields.majorClasses };

    if (parentEntity) {
      if (index === 0) {
        onFirstMajorClassChange(value, entity, currentMajorClasses);
      } else {
        onSecondMajorClassChange(value, entity, parentEntity, currentMajorClasses);
      }
    } else {
      let requiresModelling = isMajorClassRequiredModelling(value, fields.businessEntity, dropdownsData)
        ? dropdownsData.modellingPriorities[0] : '';
      currentMajorClasses[fields.businessEntity] = value;

      setFormState(previous => ({
        ...previous,
        fields: {
          ...previous.fields,
          firstMajorClass: value,
          majorClass: { majorClass: value },
          requiredModellingPriority: requiresModelling,
          majorClasses: currentMajorClasses
        }
      }));
    }
  }, [fields.businessEntity, fields.majorClasses, dropdownsData,
    setFormState, onFirstMajorClassChange, onSecondMajorClassChange]);

  const onYoaChange = useCallback(value => {
    let yoa = value || isDualStampEntity(fields.businessEntity) ? value : null;

    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, yoa, secondYoa: yoa }
    }));
  }, [fields.businessEntity, setFormState]);

  const onPlacingBasisChange = useCallback(value => {
    let placingBasis = value ?? '';

    const placingBasisCode = dropdownsData?.placingBases?.find(x => x.name === placingBasis)?.code;
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        placingBasis,
        placingBasisCode: placingBasisCode,
        secondPlacingBasis: placingBasis,
        secondPlacingBasisCode: placingBasisCode
      }
    }));
  }, [dropdownsData, setFormState]);

  const getRequiredError = name => {
    let fieldName = GeneralDetailsFields[name] ?? name;

    return errors[name]?.type === 'required' &&
      <span role="alert">"{fieldName}" is required</span>;
  }

  const getPlacingBasisError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">Selected "{GeneralDetailsFields.documentType}" cannot be associated with selected "{GeneralDetailsFields[name]}"</span>;
  }

  const renderEntity = () => {
    let data = Object.values(dropdownsData.entities).map(x => ({ Key : x.name, Value : x.displayName}));
    let selectedValue = data.find(x => x.Key === fields.businessEntity)?.Value ?? '';

    return (
      <Grid item>
        <ComponentController
          name="businessEntity"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                value={selectedValue}
                data={data.map(x => x.Value)}
                onChange={e => { onEntityChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderMajorClasses = () => {
    let entity = dropdownsData.entities[fields.businessEntity];
    if (entity && entity.parentEntities && entity.parentEntities.length > 0) {
      return entity.parentEntities.map((parentEntity, index) => {
        let entityName = isDualStampEntity(fields.businessEntity) ? resolveS4000EntityName(parentEntity) : parentEntity;
        let name = `${GeneralDetailsFields.majorClass} (${entityName})`;
        let value = fields.majorClasses[parentEntity] ?? '';
        let options = dropdownsData.entities[parentEntity].majorClasses;

        return (
          <Grid key={name} item>
            <ComponentController
              name={name}
              control={control}
              required
              render={({ field: { name, onBlur, onChange } }) =>
                <FormControl fullWidth error={!!errors[name]}>
                  <Dropdown
                    id={name}
                    name={name}
                    label={name}
                    disabled={!fields.businessEntity}
                    value={value}
                    data={options}
                    onChange={e => { onMajorClassChange(e, parentEntity, index); onChange(e); }}
                    onBlur={onBlur}
                    errorText={getRequiredError(name)}
                    inputRef={ai?.fieldsRef?.["firstMajorClass"]}
                  />
                </FormControl>
              }
            />
          </Grid>
        );
      });
    } else {
      let value = fields.majorClasses[fields.businessEntity] ?? '';

      return (
        <Grid item>
          <ComponentController
            name="majorClass"
            control={control}
            required
            render={({ field: { name, onBlur, onChange } }) =>
              <FormControl fullWidth error={!!errors[name]}>
                <Dropdown
                  id={name}
                  name={name}
                  label={GeneralDetailsFields[name]}
                  disabled={!fields.businessEntity}
                  value={value}
                  data={dropdownsData.majorClasses}
                  onChange={e => { onMajorClassChange(e); onChange(e); }}
                  onBlur={onBlur}
                  errorText={getRequiredError(name)}
                  inputRef={ai?.fieldsRef?.["firstMajorClass"]}
                />
              </FormControl>
            }
          />
        </Grid>
      );
    }
  }

  const renderRequiredModellingPriority = () => {
    return isMajorClassRequiredModelling(fields.firstMajorClass, fields.businessEntity, dropdownsData) && (
      <Grid item>
        <TextField
          fullWidth
          disabled
          id="requiredModellingPriority"
          label="Required Modelling Priority"
          value={fields.requiredModellingPriority}
          inputRef={ai?.fieldsRef?.requiredModellingPriority}
        />
      </Grid>
    );
  }

  const renderYearOfAccount = () => {
    return (
      <Grid item>
        <ComponentController
          name="yoa"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                disabled={!fields.businessEntity}
                value={fields.yoa}
                data={dropdownsData.years}
                onChange={e => { onYoaChange(e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderAssured = () => {
    let label = isDualStampEntity(fields.businessEntity)
      ? `${GeneralDetailsFields.assured} (4000)`
      : GeneralDetailsFields.assured;
    
    return (
      <Grid item>
        <TextField
          id="assured"
          label={label}
          disabled
          fullWidth
          value={fields.assured?.assured ?? ""}
          inputRef={ai?.fieldsRef?.assured}
        />
      </Grid>
    );
  }

  const renderSecondAssured = () => {
    let label = `${GeneralDetailsFields.assured} (HIDAC)`;

    return isDualStampEntity(fields.businessEntity) && (
      <Grid item>
        <TextField
          id="secondAssured"
          label={label}
          disabled
          fullWidth
          value={fields.secondAssured?.assured ?? ""}
          inputRef={ai?.fieldsRef?.secondAssured}
        />
      </Grid>
    );
  }

  const renderPlacingBasis = () => {
    return (
      <Grid item>
        <ComponentController
          name="placingBasis"
          control={control}
          required
          rules={{
            validate: placingBasis => validatePlacingBasis(placingBasis, fields.documentType, dropdownsData.documentTypes, dropdownsData.placingBases)
          }}
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                value={fields.placingBasis}
                data={dropdownsData.placingBases.map(p => p.name)}
                onChange={e => { onPlacingBasisChange(e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name) || getPlacingBasisError(name)}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    return (
      <Grid item>
        <TotalPoliciesField
          name={TotalNumberOfPolicyReferencesName}
          required={true}
          label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
          control={control}
          value={fields[TotalNumberOfPolicyReferencesName]}
          errors={errors}
          setFormState={setFormState}
          setValidationValue={setValidationValue}
          getRequiredError={getRequiredError}
          inputRef={ai?.fieldsRef?.[TotalNumberOfPolicyReferencesName]}
        />
      </Grid>
    );
  }

  return <>
    {renderEntity()}
    {renderMajorClasses()}
    {renderRequiredModellingPriority()}
    {renderYearOfAccount()}
    {renderAssured()}
    {renderSecondAssured()}
    {renderPlacingBasis()}
    {renderTotalNumberOfReferences()}
  </>;
}

export default BulkItemSection;