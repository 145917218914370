import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const Progress = withStyles((theme) => ({
  colorPrimary: {
    color: "#1FB6AD"
  }
}))(CircularProgress);

export const Spinner = (props) => {
  return <Progress size={props.size} />;
};