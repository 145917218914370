import { QueryParametersConstants } from "./QueryParameters";

const QuoteMapper = {
  mapQueryParametersToForm: parameters => {
    let kissflowIdParameter = QueryParametersConstants.KissflowId;
   

    return {
      kissflowId: searchParameter(parameters, kissflowIdParameter),
    };
  },
  mapFormToRequest: (data) => {
    let formData = new FormData();

    formData.append("policyNumber", data.policyNo ?? "");
    formData.append("policyId", data.policyId ?? 0);    
    formData.append("brokerContactName", data.brokerContact.name ?? "");
    formData.append("brokerBranchName", data.brokerBranch.name ?? "");
    formData.append("brokerName", data.broker.name ?? "");
    formData.append("kissflowId", data.kissflowId ?? "");
    formData.append("productId", data.product.productId ?? 0);
    formData.append("companyId", data.companyId ?? 0);
    formData.append("customerId", data.customerId ?? 0);
    formData.append("addressLine1", data.insuredAddressLine1 ?? "");
    formData.append("addressLine2", data.insuredAddressLine2 ?? "");
    formData.append("addressLine3", data.insuredAddressLine3 ?? "");
    formData.append("city", data.insuredCity ?? "");
    formData.append("state", data.insuredState ?? "");
    formData.append("zipCode", data.insuredZipCode ?? "");
    formData.append("processId", data.processFlow ?? "");
    formData.append("companyName", data.companyName ?? "");
    formData.append("brokerContactEmail", data.brokerContactEmail ?? "");

    return formData;
  }
}

const searchParameter = (object, key) => {
  return object[Object.keys(object)
    .find(k => k.toLowerCase() === key.toLowerCase())
  ];
}

export default QuoteMapper;