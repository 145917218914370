import { v4 as uuidv4 } from 'uuid';

import MsgReader from 'components/RpaDropzones/EmailReader';
import { FileUploadStatus, ChunkSize, FileType } from './Constants';

export const parseMessage = (message, file) => {

  var attachments = []; 
  const imageExtensions = [".bmp", ".png", ".jpg", ".jpeg", ".gif"];

  var reader = new MsgReader(message);
  var email = reader.getFileData();
  var attachments = email.attachments.filter(file => file.extension != "")?.map(a => {
    var att = reader.getAttachment(a);
    return {
      id: uuidv4(),
      name: (a.innerMsgContent ? `${a.name}.msg` : a.name).replace(":", "").replace(new RegExp("/", "g"), "_"),
      mimeType: imageExtensions.includes(a.extension) ? `image/${a.extension}`: "",
      isAttached: false,
      size: a.contentLength,
      content: att?.content
    };
  });

  return {
    id: uuidv4(),
    type: FileType.message,
    content: message,
    messageFile: { name: file.name },
    title: email.subject,
    body: email.body,
    sender: email.senderEmail,
    recipients: email.recipients.map(r => r.email),
    attachments: attachments,
    upload: { status: FileUploadStatus.notStarted },
    file: file
  };
};

export const initDocument = (document, file) => {
  return {
    id: uuidv4(),
    type: FileType.document,
    content: document,
    upload: { status: FileUploadStatus.notStarted },
    file: file
  };
};

export const uploadFileByChunks = async (document, uploadFile, onUpload, onUploadArguments) => {
  let file = document.file;

  // Total count of chunks will have been upload to finish the file
  let totalCount = file.size % ChunkSize.chunk === 0
    ? file.size / ChunkSize.chunk
    : Math.floor(file.size / ChunkSize.chunk) + 1;

  let beginingOfTheChunk = 0;
  let endOfTheChunk = ChunkSize.chunk;
  let uploadPath = [];

  for (var i = 0; i < totalCount; i++) {
    let chunk = file.slice(beginingOfTheChunk, endOfTheChunk);

    let response = await uploadFile(document.file.name, chunk);
    if (response.success) {
      uploadPath.push(response.uploadPath);
      beginingOfTheChunk = endOfTheChunk;
      endOfTheChunk = endOfTheChunk + ChunkSize.chunk;
    } else {
      onUpload(onUploadArguments);
      throw response.error;
    }
  }

  onUploadArguments.uploadPath = uploadPath;
  onUpload(onUploadArguments);
}