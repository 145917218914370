import React, { useCallback } from 'react';
import { useStarAiContext } from 'star-ai'
import { FormControl, Grid, TextField } from '@material-ui/core';

import { Dropdown, ComponentController } from 'components/common';
import { AssuredAndPolicyReferenceControl } from './common';
import { GeneralDetailsFields, createMajorClassStructure } from 'components/SubmissionWizard/Helpers';

import '../GeneralDetails.css';

const ModellingInfoSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  modellingMajorClasses
}) => {

  // star ai
  const ai = useStarAiContext();

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        majorClass: majorClass,
        requiredModellingPriority: majorClass?.isModellingPriority
          ? previous.dropdownsData.modellingPriorities[0]
          : ''
      }
    }));
  }, [dropdownsData.modellingPriorities, setFormState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const renderModellingPriority = () => {
    let name = "requiredModellingPriority";
    return fields.majorClass?.isModellingPriority &&
      (
        <Grid item>
          <FormControl fullWidth>
            <Dropdown
              id={name}
              label={GeneralDetailsFields[name]}
              value={fields[name]}
              data={dropdownsData.modellingPriorities}
              onChange={e => onFieldChange(name, e.target.value)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          </FormControl>
        </Grid>
      );
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      additionalRequestData={{ businessEntities: dropdownsData.entitiesForFilter }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderAssured = () => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = () => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.policyReferenceNumber}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderTypeOfModellingRisk = () => {
    let name = "typeOfModellingRisk"
    return (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields.typeOfModellingRisk}
                value={fields.typeOfModellingRisk}
                emptyValue={true}
                data={dropdownsData.typesOfModellingRisk}
                onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  return <>
    {renderModellingPriority()}
    {renderLookupForAssuredAndPolicyReference()}
    {renderPolicyReferenceNumber()}
    {renderAssured()}
    {renderTypeOfModellingRisk()}
  </>;
}

export default ModellingInfoSection;