import { Grid } from "@material-ui/core";

import { TypesOfReassured } from "../Helpers";

export const renderAssuredLookupHeader = (entityType, typeOfReassured) => {
  return ({ className }) => {
    let entityHeader = `${entityType.charAt(0).toUpperCase()}${entityType.slice(1)}`;

    return (
      <Grid container direction="row" spacing={0} className={className}>
        <Grid item xs={6}><div>{entityHeader}</div></Grid>
        <Grid item xs={1}><div>Country</div></Grid>
        {
          typeOfReassured === TypesOfReassured.Individual
            ? <Grid item xs={4}><div>Address</div></Grid>
            : <>
              <Grid item xs={3}><div>Address</div></Grid>
              <Grid item xs={1}><div>OrbisId</div></Grid>
            </>
        }
        <Grid item xs={1}><div>Source</div></Grid>
      </Grid>
    );
  };
}
