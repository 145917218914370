export const initialFormState = {
  businessEntity: '',
  firstMajorClass: '',
  majorClasses: {},
  minorClass: '',
  product: null,
  companyId: null,
  class: '',
  producingTeam: '',
  yoa: '',
  documentType: '',
  justificationForEpiUpdate: '',
  placingBasis: '',
  defaultPlacingBasis: {},
  broker: null,
  brokerBranch: null,
  brokerContact: null,
  assignedUW: '',
  assignedUWName: '',
  effectiveDate: null,
  quoteBy: null,
  policyEntryInformation: '',
  assured: '',
  assuredId: null,
  isNewAssured: false,
  isNewSecondAssured: false,
  secondAssured: '',
  assuredAddress: null,
  underwriterEmail: '',
  documentTypesList: [],
  critical: false,
  renewedPolicy: false,
  requiredModellingPriority: '',
  typeOfModellingRisk: '',
  finalPricingCompleted: false,
  peerReviewNotRequired: false,
  pricingNotRequired: false,
  policyReferenceNumber: '',
  secondPolicyReferenceNumber: '',
  totalNumberOfPolicyReferences: 1,
  masterPolicyReferenceNumber: '',
  secondMasterPolicyReferenceNumber: '',
  expiringPolicyReferenceNumber: '',
  secondExpiringPolicyReferenceNumber: '',
  premiumEndorsement: false,
  eeaBusiness: false,
  placementMethod: '',
  comments: '',
  dealName: '',
  blockedSubmission: false,
  assuredMiami: '',
  miamiCedant: '',
  miamiCedantCountry: '',
  miamiCedantCountryCode: '',
  miamiCedantText: '',
  hasExpiringPolicies: false,
  preRenewal: false,
  typeOfBusiness: '',
  territory: '',
  requestInitiatorName: '',
  requestInitiatorEmail: '',
};
