import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { parse } from 'query-string';

import { Divider, Grid, List, ListItem, ListItemText, ListItemIcon, TextField} from '@material-ui/core';
import { Error, Send } from "@material-ui/icons";

import { Banner, BannerType, Footer, FormButton } from '../common';
import QuoteMapper from './Helpers';
import { fetchPolicy, fetchPolicyBrokerInfo, fetchEditableInfo, sendData, fetchKissflowInfo } from './EditableFields.Api';
import { fetchBrokerContacts } from 'components/SubmissionForm/Form.api';

import BrokerAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerAutocomplete";
import BrokerBranchAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerBranchAutocomplete";
import BrokerContactAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerContactAutocomplete";

import './EditableFields.css';

import { validateRequiredField } from "components/SubmissionForm/Validation";

export const EditableFields = ({ setLoading }) => {
  const initialFormState = {
    effectiveDate: null,
    policyNo: '',
    policyId: '',
    broker: null,
    brokerBranch: null,
    brokerContact: null,
    product: null,
    companyId: null,
    kissflowId: '',
    insuredName: '',
    insuredAddressLine1: '',
    insuredAddressLine2: '',
    insuredAddressLine3: '',
    insuredCity: '',
    insuredState: '',
    insuredZipCode: '',
    customerId: '',
    processFlow: '',
    companyName: ''
  };

  const initialValidationState = {
    fields: {
      broker: { valid: true, message: "" },
      brokerBranch: { valid: true, message: "" },
      brokerContact: { valid: true, message: "" },
    },
    valid: true,
    start: true
  };

  const location = useLocation();

  const [formState, setFormState] = useState(initialFormState);
  const [validationState, setValidationState] = useState(initialValidationState);
  const [bannerState, setBannerState] = useState({});

  useEffect(() => {
    let initializeForm = async () => {
      let queryParams = QuoteMapper.mapQueryParametersToForm(parse(location.search));
      var validationResult = validateQueryParams(queryParams);
      if (validationResult.length > 0) {
        setValidationState({
          errors: validationResult
        });
      } else {
        if (queryParams.policyNo === null || queryParams.policyNo === '') {

          let kissflowInfo = await fetchKissflowInfo(queryParams.processFlow, queryParams.kissflowId);
          if (kissflowInfo.success) {
            setFormState({
              ...formState,
              ...queryParams,
              companyId: 1,              
              product: {
                productId: kissflowInfo.data.productId
              },
              broker: {
                id: kissflowInfo.data.brokerId,
                name: kissflowInfo.data.brokerName,
                code: kissflowInfo.data.brokerCode
              },
              brokerBranch: {
                id: kissflowInfo.data.brokerBranchId,
                name: kissflowInfo.data.brokerBranch,
                code: kissflowInfo.data.brokerBranchCode,
              },
              brokerContact: {
                id: kissflowInfo.data.brokerContactId,
                name: kissflowInfo.data.brokerContact,
                code: kissflowInfo.data.brokerContactCode,
              },
              policyId: null,
              policyNo: null,
              insuredName: kissflowInfo.data.insuredName,
              insuredAddressLine1: kissflowInfo.data.addressLine1,
              insuredAddressLine2: kissflowInfo.data.addressLine2,
              insuredAddressLine3: kissflowInfo.data.addressLine3,
              insuredCity: kissflowInfo.data.city,
              insuredState: kissflowInfo.data.state,
              insuredZipCode: kissflowInfo.data.zipCode,
              customerId: kissflowInfo.data.customerId,
              effectiveDate: new Date(kissflowInfo.data.effectiveDate),              
              processFlow: queryParams.processFlow
            });
          } else {
            setBannerState({
              show: true,
              type: BannerType.error,
              message: kissflowInfo.errorMessage
            });
          }

        }
        else {
          let policyResponse = await fetchPolicy(queryParams.policyNo);
          if (policyResponse.success) {
            setFormState({
              ...formState,
              ...queryParams,
              companyId: 1,
              product: {
                productId: policyResponse.data.productId
              }
            });
            let policyBrokerResponse = await fetchPolicyBrokerInfo(policyResponse.data.policyId);
            let customerInfoResponse = await fetchEditableInfo(policyResponse.data.policyId);
            if (policyBrokerResponse.success && customerInfoResponse.success) {
              if (customerInfoResponse.data.IsPolicyLocked) {
                  setBannerState({
                    show: true,
                    type: BannerType.error,
                    message: "Policy Locked, unable to edit it"
                  });
              } else {
                  setFormState({
                    ...formState,
                    ...queryParams,
                    companyId: policyBrokerResponse.data.companyId,
                    product: {
                      productId: policyResponse.data.productId
                    },
                    broker: {
                      id: policyBrokerResponse.data.brokerId,
                      name: policyBrokerResponse.data.brokerName,
                      code: policyBrokerResponse.data.brokerCode
                    },
                    brokerBranch: {
                      id: policyBrokerResponse.data.brokerBranchId,
                      name: policyBrokerResponse.data.brokerBranchName,
                      code: policyBrokerResponse.data.brokerBranchCode,
                    },
                    brokerContact: {
                      id: policyBrokerResponse.data.brokerContactId,
                      name: policyBrokerResponse.data.brokerContactName,
                      code: policyBrokerResponse.data.brokerContactCode,
                    },
                    policyId: policyBrokerResponse.data.policyId,
                    policyNo: queryParams.policyNo,
                    insuredName: queryParams.insured,
                    insuredAddressLine1: customerInfoResponse.data.addressLine1,
                    insuredAddressLine2: customerInfoResponse.data.addressLine2,
                    insuredAddressLine3: customerInfoResponse.data.addressLine3,
                    insuredCity: customerInfoResponse.data.city,
                    insuredState: customerInfoResponse.data.state,
                    insuredZipCode: customerInfoResponse.data.zipCode,
                    customerId: policyResponse.data.customerId,
                    effectiveDate: new Date(customerInfoResponse.data.effectiveDate),
                    processFlow: queryParams.processFlow,
                    companyName: policyBrokerResponse.data.companyName
                  });
                }
            } else {
              setBannerState({
                show: true,
                type: BannerType.error,
                message: policyBrokerResponse.errorMessage
              });
            }
          } else {
            setBannerState({
              show: true,
              type: BannerType.error,
              message: policyResponse.errorMessage
            });
          }
        }
      }

      setLoading(false);
    }

    setLoading(true);
    initializeForm();
  }, []);

  const validateQueryParams = params => {
    let result = [];

    if (!params.kissflowId) {
      result.push({
        parameter: 'instance_id',
        message: 'instance_id value should be set.'
      })
    }
    return result;
  }

  const onSubmit = async () => {
    let result = validateForm(formState);
    setValidationState(_ => result);

    if (!result.valid) {
      setBannerState({
        show: true,
        type: BannerType.warning,
        message: "Form contains validation errors. Please provide valid information."
      });
      return;
    }

    // validate that the broker combination is the right one
    let brokerId = formState.broker?.id;
    let branchId = formState.brokerBranch?.id;
    let productId = formState.product?.productId;
    let companyId = formState.companyId;
    let filter = formState.brokerContact?.name;    

    let response = await fetchBrokerContacts(brokerId, productId, companyId, branchId, filter);

    if (!response.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }

    if (response.data.length < 1) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: "Broker information is wrong. Please select it again."
      });
    } else {
      setLoading(true);

      let formData = QuoteMapper.mapFormToRequest(formState);
      let submitResponse = await sendData(formData);
      if (!submitResponse.success) {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: submitResponse.errorMessage
        });
      }
      else {
        setBannerState({
          type: BannerType.success,
          message: "Information has been updated successfully.",
          show: true
        });
      }
    }
    setLoading(false);
  };


  const validateForm = (form) => {
    const brokerResult = validateRequiredField("Broker Name", form.broker?.name);
    const brokerBranchResult = validateRequiredField("Broker Branch", form.brokerBranch?.name);
    const brokerContactResult = validateRequiredField("Broker Contact", form.brokerContact?.name);
    const brokerIdResult = validateRequiredField("Broker Id", form.broker?.id);


    return {
      fields: {
        broker: brokerResult,
        brokerBranch: brokerBranchResult,
        brokerContact: brokerContactResult,
        brokerId: brokerIdResult,
      },
      valid: brokerResult.valid & brokerBranchResult.valid & brokerContactResult.valid,
      start: true
    };
  };

  const renderPolicy = () => {
    let render = false;
    if (formState.policyNo != null && formState.policyNo !== '') {
      render = true;
    }

    return render && (
      <Grid item container xs={10} spacing={2}>
        <Grid item xs={5}>
          <TextField
            id="txtPolicyId"
            label="Policy Id"
            readOnly
            fullWidth
            value={formState.policyId ?? formState.policyId}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            id="txtPolicyNo"
            label="Policy Number"
            readOnly
            fullWidth
            value={formState.policyNo ?? formState.policyNo}
          />
        </Grid>
      </Grid>
    );
  };


  const renderEditor = () => {
    return (
      <Grid container className="form-section" direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
        <Grid container>
          {renderPolicy()}
          <Grid container>
            <Grid item xs={10}>
              <TextField
                id="txtInsuredName"
                label="Insured Name"
                readOnly
                fullWidth
                value={formState.insuredName ?? formState.insuredName}
              />
            </Grid>
            <Grid item xs={10}>            
                <BrokerAutocomplete
                  fieldsState={formState}
                  validationState={validationState}
                  setFieldsState={setFormState}
                  setValidationState={setValidationState}
                  setBannerState={setBannerState}
                />
            </Grid>
            <Grid item xs={10}>
              <BrokerBranchAutocomplete
                fieldsState={formState}
                validationState={validationState}
                setFieldsState={setFormState}
                setValidationState={setValidationState}
                setBannerState={setBannerState}
              />
            </Grid>
            <Grid item xs={10}>
              <BrokerContactAutocomplete
                fieldsState={formState}
                validationState={validationState}
                setFieldsState={setFormState}
                setValidationState={setValidationState}
                setBannerState={setBannerState}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={10} direction="column">
          <div className="form-footer">
            <FormButton endIcon={<Send />} onClick={onSubmit}>
              Submit
            </FormButton>
          </div>
        </Grid>
      </Grid>
    );
  }
  const renderValidationMessages = () => {
    return <>
      <h3 className="validation-errors-header">
        Query parameters validation is failed. Please take a look at the errors below
      </h3>
      <List component="div" disablePadding>
        {validationState.errors?.map(error => {
          return (
            <ListItem key={error.parameter}>
              <ListItemIcon>
                <Error color="error" />
              </ListItemIcon>
              <ListItemText primary={error.message} />
            </ListItem>
          );
        })}
      </List>
    </>;
  }

  const renderForm = () => {
    return (
      <div className="broker-info-container">
        <h2>Edit Broker Information for Policy </h2>
        <Divider />
        <Banner
          type={bannerState.type}
          message={bannerState.message}
          show={bannerState.show}
          showBanner={show => setBannerState({
            ...bannerState,
            show: show
          })}
        />
        {validationState.start ? renderEditor() : renderValidationMessages()}
        <Footer />
      </div>
    );
  }

  return renderForm();
};