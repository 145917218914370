import axios from 'axios';

import { getAuthorizationProvider } from '../Authentication';

export const authorizedApiClient = axios.create();

const addRequestInterceptors = () => {
  const beforeRequest = async (config) => {
    var authorizationProvider = await getAuthorizationProvider();
    let { auth } = authorizationProvider.getCurrentConfiguration();

    const checkTokenRequest = {
      scopes: [auth.clientId]
    };

    try {
      const response = await authorizationProvider.acquireTokenSilent(checkTokenRequest);
      config.headers.Authorization = `Bearer ${response.idToken.rawIdToken}`;
      return config;
    } catch (error) {
      authorizationProvider.acquireTokenRedirect(checkTokenRequest);
    }
  }

  authorizedApiClient.interceptors.request.use(beforeRequest);
};

export const configureInterceptors = () => {
  addRequestInterceptors();
};