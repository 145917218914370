import { validateFieldLength, validateTextField } from "components/SubmissionForm/Validation";

const fieldsMaxLength = {
  "Line 1": 50,
  "Line 2": 50,
  "Line 3": 50,
  "City": 30,
  "State": 2,
  "ZIP Code": 13,
  "ZIP Code US": 5,
  "Country Code": 2,
  "State US": 2
};

export const validateAddressLine1 = (value) => {
  return validateTextField("Line 1", value, fieldsMaxLength["Line 1"]);
}

export const validateAddressLine2 = (value) => {
  return validateFieldLength("Line 2", value, fieldsMaxLength["Line 2"]);
}

export const validateAddressLine3 = (value) => {
  return validateFieldLength("Line 3", value, fieldsMaxLength["Line 3"]);
}

export const validateCity = (value) => {
  return validateTextField("City", value, fieldsMaxLength["City"]);
}

export const validateState = (value, addressStates, countryCode) => {

  if (countryCode === "US") {
    let result = validateTextField("State", value, fieldsMaxLength["State US"]);
    if (!result.valid) {
      return result;
    }

    if (!/^[A-Za-z]{2}$/.test(value) || !addressStates.map(x => x.code).includes(value.toUpperCase())) {
      return { valid: false, message: "\"State\" value should be a valid two-letter US state code." }
    }
  }

  return { valid: true, message: "" };
}

export const validateZipCode = (value, countryCode) => {
  if (countryCode === "US") {
    if (value.length !== 5) {
      return { valid: false, message: "\"Zip Code\" value should be a valid five-digit US zip code." }
    }
    let result = validateTextField("ZIP Code", value, fieldsMaxLength["ZIP Code US"]);

    if (!result.valid) {
      return result;
    }

    if (!/^\d{0,5}$/.test(value)) {
      return { valid: false, message: "\"Zip Code\" value should be a valid five-digit US zip code." }
    }

  }
  else {
    return validateFieldLength("ZIP Code", value, fieldsMaxLength["ZIP Code"]);
  }

  return { valid: true, message: "" };
}

export const validateAddAssuredDialog = (dialogState, addressStates, countryCode) => {
  let insuredResult = validateTextField("Insured Name", dialogState.insured);
  let addressLine1Result = validateAddressLine1(dialogState.address.line1);
  let addressLine2Result = validateAddressLine2(dialogState.address.line2);
  let addressLine3Result = validateAddressLine3(dialogState.address.line3);
  let cityResult = validateCity(dialogState.address.city);
  let stateResult = validateState(dialogState.address.state, addressStates, countryCode);
  let zipCodeResult = validateZipCode(dialogState.address.zipCode, countryCode);

  return {
    fields: {
      insured: insuredResult,
      address: {
        line1: addressLine1Result,
        line2: addressLine2Result,
        line3: addressLine3Result,
        city: cityResult,
        state: stateResult,
        zipCode: zipCodeResult
      }
    },
    valid: insuredResult.valid && addressLine1Result.valid && addressLine2Result.valid &&
      addressLine3Result.valid && cityResult.valid && stateResult.valid && zipCodeResult.valid
  };
}
