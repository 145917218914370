import React from "react";

import { Grid, TextField, FormControl } from "@material-ui/core";

import { Dropdown, CheckboxElement, FormControlWrapper, BannerType } from "components/common";

import { fetchClasses, fetchMinorClasses } from "components/SubmissionForm/Form.api";
import {
  validateRequiredField, validateRenewedPolicy, validateDocumentTypeAndPlacingBasis, validateTextField, validateNumericField
} from "components/SubmissionForm/Validation";
import {
  getPlacingBasisDefaultValue, isPlacingBasisDisabled, isMasterPolicyReferenceNumberEnabled,
  isTotalNumberOfPolicyReferencesEnabled, isPolicyReferenceNumberEnabled, isExpiringPolicyReferenceNumberEnabled,
  getMinorClassDefaultValue, isTypeOfModellingRiskEnabled, areMinorClassesUsed,
  isFinalPricingCompletedEnabled, isPremiumEndorsement, isEeaBusinessEnabled, isPlacementMethodEnabled,
  isBulkItemDocumentType, isPricingNotRequiredEnabled, isRenewedPolicyEnabled,
  isMajorClassRequiredModelling, isPeerReviewNotRequiredEnabled, isQcErrorDocumentType, disableDocumentType
} from "components/SubmissionForm/Helpers";

export const ShanghaiSection = props => {
  const {
    fieldsState,
    dropdownsData,
    validationState,
    setFieldsState,
    setValidationState,
    setDropdownsData,
    setBannerState
  } = { ...props };

  const validateMajorClass = (value, entityName) => {
    var result = validateRequiredField("Major Class", value);
    var majorClassesValidationState = { ...validationState.fields.majorClasses };
    majorClassesValidationState[entityName] = result;

    setValidationState(prevState => {
      return {
        ...prevState,
        fields: {
          ...prevState.fields,
          majorClasses: majorClassesValidationState
        }
      }
    });
  };

  const validateYearOfAccount = value => {
    var result = validateRequiredField("YOA", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        yearOfAccount: result
      }
    });
  };

  const validateMinorClass = value => {
    let result = validateRequiredField("Minor Class", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        minorClass: result
      }
    });
  };

  const validateClass = value => {
    let result = validateRequiredField("Class", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        class: result
      }
    });
  };

  const onMajorClassChanged = (event) => {
    let value = event.target.value;
    let currentMajorClasses = { ...fieldsState.majorClasses };
    currentMajorClasses[fieldsState.businessEntity] = value;

    let modellingRequired = dropdownsData.majorClasses?.find(d => d.name === value)?.modellingRequired;

    let placingBasis = getPlacingBasisDefaultValue(fieldsState.businessEntity, value, dropdownsData.placingBases);
    setFieldsState({
      ...fieldsState,
      firstMajorClass: value,
      minorClass: '',
      class: '',
      requiredModellingPriority: modellingRequired ? 'No Modelling Required' : '',
      majorClasses: currentMajorClasses,
      documentType: '',
      placingBasis: placingBasis,
      assured: '',
      isNewAssured: false
    });

    validateMajorClass(value, fieldsState.businessEntity);
  };

  const onMajorClassBlur = (event, entityName) => {
    validateMajorClass(event?.target?.value, entityName);
  };

  const onYearOfAccountChanged = event => {
    setFieldsState({
      ...fieldsState,
      yoa: event.target.value,
      isNewAssured: false
    });

    validateYearOfAccount(event.target.value);
  };

  const onYearOfAccountBlur = event => {
    validateYearOfAccount(event?.target?.value);
  };

  const onDocumentTypeChanged = async event => {
    let newDocumentType = event.target.value;
    let minorClass = "";
    let minorClassesResponse = {};
    let classesResponse = {};

    let firstMajorClass = fieldsState.firstMajorClass;

    let placingBasis = isPlacingBasisDisabled(fieldsState.documentTypesList, newDocumentType) ? '' : fieldsState.placingBasis;

    let newPolicyReferenceNumber = isPolicyReferenceNumberEnabled(fieldsState.documentTypesList, newDocumentType, fieldsState.isNewAssured)
      ? fieldsState.policyReferenceNumber : '';

    let newTotalNumberOfPolicyReferences = isTotalNumberOfPolicyReferencesEnabled(fieldsState.documentTypesList, newDocumentType)
      ? fieldsState.totalNumberOfPolicyReferences : 1;

    let newMasterPolicyReferenceNumber =
      isMasterPolicyReferenceNumberEnabled(fieldsState.documentTypesList, dropdownsData, newDocumentType, placingBasis)
        ? fieldsState.masterPolicyReferenceNumber : '';

    let newPeerReviewNotRequired =
      isPeerReviewNotRequiredEnabled(fieldsState.documentTypesList, dropdownsData, fieldsState.businessEntity, newDocumentType, fieldsState.placingBasis)
        ? fieldsState.peerReviewNotRequired : false;

    let newTypeOfModellingRisk = isTypeOfModellingRiskEnabled(newDocumentType, fieldsState.documentTypesList)
      ? "Pre-Live Risk" : "";

    let isBulkItem = isBulkItemDocumentType(newDocumentType);
    let notRequireModelling = isBulkItem || isQcErrorDocumentType(newDocumentType);

    let result = validateRenewedPolicy(fieldsState.renewedPolicy, newDocumentType, fieldsState.documentTypesList);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        renewedPolicy: result
      }
    }));

    result = validateRequiredField("Type Of Document", newDocumentType);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(newDocumentType, placingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result,
          assured: isBulkItem
            ? { valid: true, message: "" } : prevState.fields.assured
        }
      }));
    }

    if (areMinorClassesUsed(newDocumentType, fieldsState.businessEntity, firstMajorClass, dropdownsData)) {
      minorClassesResponse = await fetchMinorClasses(fieldsState.businessEntity, firstMajorClass);
      if (!minorClassesResponse.success) {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: minorClassesResponse.errorMessage
        });
        return;
      }

      minorClass = getMinorClassDefaultValue(
        fieldsState.businessEntity, firstMajorClass, minorClassesResponse.data);

      if (minorClass) {
        classesResponse = await fetchClasses(fieldsState.businessEntity, firstMajorClass, minorClass);
        if (!classesResponse.success) {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: classesResponse.errorMessage
          });
          return;
        }
      }

      setValidationState(prevState => ({
        ...prevState,
        minorClass: { valid: true, message: "" },
        class: { valid: true, message: "" }
      }));
    }

    setDropdownsData({
      ...dropdownsData,
      minorClasses: minorClassesResponse.data ?? [],
      classes: classesResponse.data ?? []
    });

    setFieldsState(previousState => ({
      ...previousState,
      placingBasis: placingBasis,
      documentType: newDocumentType,
      minorClass: minorClass,
      class: '',
      policyReferenceNumber: newPolicyReferenceNumber,
      totalNumberOfPolicyReferences: newTotalNumberOfPolicyReferences,
      masterPolicyReferenceNumber: newMasterPolicyReferenceNumber,
      typeOfModellingRisk: newTypeOfModellingRisk,
      assured: isBulkItem ? newDocumentType : '',
      isNewAssured: false,
      premiumEndorsement: isPremiumEndorsement(fieldsState) ? fieldsState.premiumEndorsement : false,
      renewedPolicy: isRenewedPolicyEnabled(fieldsState.documentTypesList, newDocumentType)
        ? fieldsState.renewedPolicy : false,
      requiredModellingPriority: notRequireModelling && isMajorClassRequiredModelling(fieldsState.firstMajorClass, fieldsState.businessEntity, dropdownsData)
        ? "No Modelling Required" : fieldsState.requiredModellingPriority,
      finalPricingCompleted: isFinalPricingCompletedEnabled(fieldsState)
        ? fieldsState.finalPricingCompleted : false,
      pricingNotRequired: isPricingNotRequiredEnabled(fieldsState)
        ? fieldsState.pricingNotRequired : false,
      peerReviewNotRequired: newPeerReviewNotRequired,
      placementMethod: isPlacementMethodEnabled(dropdownsData, fieldsState.businessEntity, newDocumentType) ? "N/A" : ""
    }));
  };

  const onPremiumEndorsementChecked = checked => {
    setFieldsState({
      ...fieldsState,
      premiumEndorsement: checked
    });
  };

  const onDocumentTypeBlur = event => {
    const documentType = event?.target?.value;
    let result = validateRequiredField("Type Of Document", documentType);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(documentType, fieldsState.placingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }
  };

  const onMinorClassChanged = event => {
    let minorClass = event.target?.value;

    setFieldsState({
      ...fieldsState,
      minorClass: minorClass,
      class: ''
    });

    getClasses(minorClass);
    validateMinorClass(minorClass);
  };

  const onMinorClassBlur = event => {
    let minorClassValue = event?.target?.value;
    validateMinorClass(minorClassValue);
  };

  const onClassChanged = event => {
    let newClass = event.target?.value;
    setFieldsState({
      ...fieldsState,
      class: newClass
    });

    validateClass(newClass);
  };

  const onClassBlur = event => {
    let classValue = event?.target?.value;
    validateClass(classValue);
  };

  const onPlacingBasisChanged = event => {
    const newPlacingBasis = event.target.value;

    const newPeerReviewNotRequired = isPeerReviewNotRequiredEnabled(
      fieldsState.documentTypesList, dropdownsData, fieldsState.businessEntity, fieldsState.documentType, newPlacingBasis)
      ? fieldsState.peerReviewNotRequired : false;

    setFieldsState({
      ...fieldsState,
      placingBasis: newPlacingBasis,
      peerReviewNotRequired: newPeerReviewNotRequired
    });

    let result = validateRequiredField("Placing Basis", newPlacingBasis);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          placingBasis: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(fieldsState.documentType, newPlacingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState, fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }
  };

  const onPlacingBasisBlur = event => {
    const placingBasis = event?.target?.value;
    let result = validateRequiredField("Placing Basis", placingBasis);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          placingBasis: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(fieldsState.documentType, placingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }
  };

  const onPolicyReferenceNumberChanged = event => {
    setFieldsState({
      ...fieldsState,
      policyReferenceNumber: event.target.value
    });
  };

  const onPolicyReferenceNumberBlur = event => {
    const result = validateTextField("Policy Reference Number", event.target?.value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        policyReferenceNumber: result
      }
    });
  };

  const onTotalNumberOfPolicyReferencesChanged = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setFieldsState({
        ...fieldsState,
        totalNumberOfPolicyReferences: event.target.value
      });
    }
  }

  const onTotalNumberOfPolicyReferencesBlur = event => {
    var result = validateNumericField("Total Number of Policy References/Declarations", event.target?.value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        totalNumberOfPolicyReferences: result
      }
    });
  }

  const onMasterPolicyReferenceNumberChanged = event => {
    setFieldsState({
      ...fieldsState,
      masterPolicyReferenceNumber: event.target.value
    });
  };

  const onMasterPolicyReferenceNumberBlur = event => {
    var result = validateTextField("Master Policy Reference Number", event.target?.value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        masterPolicyReferenceNumber: result
      }
    });
  };

  const onExpiringPolicyReferenceNumberChanged = event => {
    let value = event?.target?.value;
    setFieldsState(prevState => ({
      ...prevState,
      expiringPolicyReferenceNumber: value
    }));
  };

  const onExpiringPolicyReferenceNumberBlur = event => {
    let result = validateTextField("Expiring Policy Reference Number", event?.target?.value);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        expiringPolicyReferenceNumber: result
      }
    }));
  };

  const onRequiredModellingPriorityChanged = event => {
    setFieldsState({
      ...fieldsState,
      requiredModellingPriority: event.target.value
    });
  };

  const onFinalPricingCompletedChecked = checked => {
    setFieldsState({
      ...fieldsState,
      finalPricingCompleted: checked
    });
  };

  const onPeerReviewNotRequiredChecked = checked => {
    setFieldsState({
      ...fieldsState,
      peerReviewNotRequired: checked
    });
  };

  const onPricingNotRequiredChecked = checked => {
    setFieldsState({
      ...fieldsState,
      pricingNotRequired: checked
    });
  };

  const onEeaBusinessChecked = checked => {
    setFieldsState({
      ...fieldsState,
      eeaBusiness: checked
    });
  };

  const onPlacementMethodChanged = event => {
    let value = event?.target?.value;
    setFieldsState({
      ...fieldsState,
      placementMethod: value
    });
  };

  const onTypeOfModellingRiskChanged = event => {
    setFieldsState({
      ...fieldsState,
      typeOfModellingRisk: event.target.value
    });
  };

  const onAssuredChanged = event => {
    setFieldsState({
      ...fieldsState,
      assured: event.target?.value
    });
  };

  const onAssuredBlur = event => {
    var result = validateTextField("Assured / Master Assured", event.target?.value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        assured: result
      }
    });
  };

  const getClasses = async minorClass => {
    let classesResponse = await fetchClasses(fieldsState.businessEntity, fieldsState.firstMajorClass, minorClass);
    if (!classesResponse.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: classesResponse.errorMessage
      });

      return;
    }

    setDropdownsData({
      ...dropdownsData,
      classes: classesResponse.data
    });
  };

  const renderMajorClasses = () => {
    return (
      <Grid item>
        <FormControl
          fullWidth
          required
          error={validationState.fields.majorClasses[fieldsState.businessEntity]?.valid === false}>
          <Dropdown
            id="majorClass"
            label="Major Class"
            value={
              fieldsState?.majorClasses?.[fieldsState.businessEntity]
                ? fieldsState.majorClasses[fieldsState.businessEntity]
                : ''}
            data={dropdownsData.majorClasses?.map(d => d.name) || []}
            onChange={(event) => onMajorClassChanged(event)}
            onBlur={(event) => onMajorClassBlur(event, fieldsState.businessEntity)}
            disabled={fieldsState.businessEntity === ''}
            errorText={validationState.fields.majorClasses[fieldsState.businessEntity]?.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderMinorClass = () => {
    return areMinorClassesUsed(fieldsState.documentType, fieldsState.businessEntity, fieldsState.firstMajorClass, dropdownsData) && (
      <Grid item>
        <FormControl
          fullWidth
          required
          error={validationState.fields.minorClass.valid === false}>
          <Dropdown
            id="minorClass"
            label="Minor Class"
            value={fieldsState.minorClass}
            data={dropdownsData.minorClasses?.map(m => m.minorClass) || []}
            disabled={!dropdownsData.minorClasses || dropdownsData.minorClasses?.length === 0}
            onChange={onMinorClassChanged}
            onBlur={onMinorClassBlur}
            errorText={validationState.fields.minorClass.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderClass = () => {
    return areMinorClassesUsed(fieldsState.documentType, fieldsState.businessEntity, fieldsState.firstMajorClass, dropdownsData) && (
      <Grid item>
        <FormControl
          fullWidth
          required
          error={validationState.fields.class.valid === false}>
          <Dropdown
            id="class"
            label="Class"
            value={fieldsState.class}
            data={dropdownsData.classes}
            disabled={!dropdownsData.classes || dropdownsData.classes.length === 0}
            onChange={onClassChanged}
            onBlur={onClassBlur}
            errorText={validationState.fields.class.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderRequiredModellingPriority = () => {
    return isMajorClassRequiredModelling(fieldsState.firstMajorClass, fieldsState.businessEntity, dropdownsData) && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="requiredModellingPriority"
            label="Required Modelling Priority"
            value={fieldsState.requiredModellingPriority}
            data={["No Modelling Required", "Low", "Medium", "High"]}
            onChange={onRequiredModellingPriorityChanged}
            disabled={isBulkItemDocumentType(fieldsState.documentType) ||
              isQcErrorDocumentType(fieldsState.documentType)}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderFinalPricingCompleted = () => {
    let render = isFinalPricingCompletedEnabled(fieldsState);
    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onFinalPricingCompletedChecked(checked)}
              checked={fieldsState.finalPricingCompleted}
            />
          }
          label="Has Final Pricing Been Completed"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderPeerReviewNotRequired = () => {
    const render = isPeerReviewNotRequiredEnabled(
      fieldsState.documentTypesList, dropdownsData, fieldsState.businessEntity, fieldsState.documentType, fieldsState.placingBasis);

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onPeerReviewNotRequiredChecked(checked)}
              checked={fieldsState.peerReviewNotRequired}
            />
          }
          label="Peer Review Not Required"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderPricingNotRequired = () => {
    return isPricingNotRequiredEnabled(fieldsState) && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onPricingNotRequiredChecked(checked)}
              checked={fieldsState.pricingNotRequired}
            />
          }
          label="Pricing Not Required"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderEeaBusiness = () => {
    let render = isEeaBusinessEnabled(dropdownsData, fieldsState.businessEntity, fieldsState.documentType);
    if (!render && fieldsState.eeaBusiness) {
      onEeaBusinessChecked(false);
    }

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(_event, checked) => onEeaBusinessChecked(checked)}
              checked={fieldsState.eeaBusiness}
            />
          }
          label="EEA Business"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderPlacementMethod = () => {
    let render = isPlacementMethodEnabled(dropdownsData, fieldsState.businessEntity, fieldsState.documentType);
    return render && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="placementMethod"
            label="E-Placement method"
            value={fieldsState.placementMethod}
            data={["N/A", "PPL", "Whitespace", "AB Connect", "GC Marketplace", "GlobalRe market", "Ebix (TBC)", "Willis (TBC)"]}
            onChange={onPlacementMethodChanged}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderTypeOfModellingRisk = () => {
    const render = isTypeOfModellingRiskEnabled(fieldsState.documentType, fieldsState.documentTypesList);
    return render && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="typeOfModellingRisk"
            label="Type Of Modelling Risk"
            value={fieldsState.typeOfModellingRisk}
            data={["Live Risk", "Pre-Live Risk"]}
            onChange={onTypeOfModellingRiskChanged}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderAssured = () => {
    return (
      <Grid item>
        <TextField
          id="assured"
          label="Assured / Master Assured"
          required
          fullWidth
          disabled={isBulkItemDocumentType(fieldsState.documentType)}
          onChange={onAssuredChanged}
          onBlur={onAssuredBlur}
          value={fieldsState.assured}
          error={!validationState.fields.assured.valid}
          helperText={validationState.fields.assured.message}
        />
      </Grid>
    );
  };

  const renderPolicyReferenceNumber = () => {
    let render = isPolicyReferenceNumberEnabled(fieldsState.documentTypesList, fieldsState.documentType, fieldsState.isNewAssured);
    return render && (
      <Grid item>
        <TextField
          id="policyReferenceNumber"
          label="Policy Reference Number"
          required
          fullWidth
          onChange={onPolicyReferenceNumberChanged}
          onBlur={onPolicyReferenceNumberBlur}
          value={fieldsState.policyReferenceNumber}
          error={!validationState.fields.policyReferenceNumber.valid}
          helperText={validationState.fields.policyReferenceNumber.message}
        />
      </Grid>
    );
  };

  const renderTotalNumberOfPolicyReferences = () => {
    const render = isTotalNumberOfPolicyReferencesEnabled(fieldsState.documentTypesList, fieldsState.documentType);
    return render && (
      <Grid item>
        <TextField
          id="totalNumberOfPolicyReferences"
          label="Total Number of Policy References/Declarations"
          type="text"
          required
          fullWidth
          onChange={onTotalNumberOfPolicyReferencesChanged}
          onBlur={onTotalNumberOfPolicyReferencesBlur}
          value={fieldsState.totalNumberOfPolicyReferences}
          error={!validationState.fields.totalNumberOfPolicyReferences.valid}
          helperText={validationState.fields.totalNumberOfPolicyReferences.message}
        />
      </Grid>
    );
  };

  const renderMasterPolicyReferenceNumber = () => {
    let render = isMasterPolicyReferenceNumberEnabled(
      fieldsState.documentTypesList, dropdownsData, fieldsState.documentType, fieldsState.placingBasis);

    return render && (
      <Grid item>
        <TextField
          id="masterPolicyReferenceNumber"
          label="Master Policy Reference Number"
          required
          fullWidth
          onChange={onMasterPolicyReferenceNumberChanged}
          onBlur={onMasterPolicyReferenceNumberBlur}
          value={fieldsState.masterPolicyReferenceNumber}
          error={!validationState.fields.masterPolicyReferenceNumber.valid}
          helperText={validationState.fields.masterPolicyReferenceNumber.message}
        />
      </Grid>
    );
  };

  const renderExpiringPolicyReferenceNumber = () => {
    let render = isExpiringPolicyReferenceNumberEnabled(
      fieldsState.documentTypesList, fieldsState.documentType, fieldsState.renewedPolicy);

    return render && (
      <Grid item>
        <TextField
          id="expiringPolicyReferenceNumber"
          label="Expiring Policy Reference Number"
          required
          fullWidth
          onChange={onExpiringPolicyReferenceNumberChanged}
          onBlur={onExpiringPolicyReferenceNumberBlur}
          value={fieldsState.expiringPolicyReferenceNumber}
          error={!validationState.fields.expiringPolicyReferenceNumber.valid}
          helperText={validationState.fields.expiringPolicyReferenceNumber.message}
        />
      </Grid>
    );
  };

  const renderDocumentType = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.typeOfDocument.valid}>
          <Dropdown
            id="documentType"
            label="Type Of Document"
            value={fieldsState.documentType}
            data={fieldsState.documentTypesList?.map(x => x.name) || []}
            onChange={onDocumentTypeChanged}
            onBlur={onDocumentTypeBlur}
            errorText={validationState.fields.typeOfDocument.message}
            disabled={disableDocumentType(fieldsState)}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderPremiumEndorsement = () => {
    return isPremiumEndorsement(fieldsState, dropdownsData) && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onPremiumEndorsementChecked(checked)}
              checked={fieldsState.premiumEndorsement}
            />
          }
          label="Premium Bearing"
          labelPlacement="start" />
      </Grid>
    );
  };

  const renderYearOfAccount = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.yearOfAccount.valid}>
          <Dropdown
            id="yoa"
            label="YOA"
            value={fieldsState.yoa}
            data={dropdownsData.years}
            onChange={onYearOfAccountChanged}
            onBlur={onYearOfAccountBlur}
            disabled={fieldsState.businessEntity === ''}
            errorText={validationState.fields.yearOfAccount.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderPlacingBasis = () => {
    let render = !isPlacingBasisDisabled(fieldsState.documentTypesList, fieldsState.documentType);
    return render && (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.placingBasis.valid}>
          <Dropdown
            id="placingBasis"
            label="Placing Basis"
            disabled={!fieldsState.businessEntity}
            value={fieldsState.placingBasis}
            data={dropdownsData.placingBases?.map(x => x.name) || []}
            onChange={onPlacingBasisChanged}
            onBlur={onPlacingBasisBlur}
            errorText={validationState.fields.placingBasis.message}
          />
        </FormControl>
      </Grid>
    );
  };

  return <>
    {renderMajorClasses()}
    {renderRequiredModellingPriority()}
    {renderDocumentType()}
    {renderPremiumEndorsement()}
    {renderMinorClass()}
    {renderClass()}
    {renderYearOfAccount()}
    {renderFinalPricingCompleted()}
    {renderAssured()}
    {renderPolicyReferenceNumber()}
    {renderPlacingBasis()}
    {renderTotalNumberOfPolicyReferences()}
    {renderMasterPolicyReferenceNumber()}
    {renderExpiringPolicyReferenceNumber()}
    {renderPeerReviewNotRequired()}
    {renderPricingNotRequired()}
    {renderEeaBusiness()}
    {renderPlacementMethod()}
    {renderTypeOfModellingRisk()}
  </>;
};