import React, { useEffect, useCallback } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Divider, Checkbox, FormControlLabel
} from '@material-ui/core';
import { DialogButton } from 'components/common';
import { isEngineeringFile } from './engineeringFilesHelpers';
import { isZip } from 'components/RpaDropzones/Helpers';

export const EngineeringFilesSelectionPopup = ({ popupState, setFormState, submit, submitArguments, formState }) => {
  useEffect(() => {
    setFormState(state => ({
      ...state,
      popup: { ...state.popup, show: true },
      files: state.files.map((file, i) => ({
        ...file,
        uid: `${i}`,
        isEngineering: isEngineeringFile(file.name),
        attachments: file.attachments?.map((att, j) => ({
          ...att,
          uid: `${i}-${j}`,
          isEngineering: isEngineeringFile(att.name)
        }))
      }))
    }));
  }, []);

  const handleClose = useCallback(() => {
    setFormState(state => ({
      ...state,
      popup: { ...state.popup, show: false },
      droppedTile: ''
    }));
  }, [setFormState]);

  const handleChange = useCallback(({ target }) => {
    setFormState(state => {
      const file = state.files.find(f => f.uid === target.value);
      if (file) {
        return {
          ...state,
          files: state.files.map(f => {
            if (f.uid === target.value) {
              return {
                ...f,
                isEngineering: target.checked
              }
            }
            return f;
          })
        }
      }
      const attachment = state.files
        .filter(f => f.attachments)
        .find(f => f.attachments.find(att => att.uid === target.value));
      if (attachment) {
        return {
          ...state,
          files: state.files.map(f => {
            if (f.uid === attachment.uid) {
              return {
                ...f,
                attachments: f.attachments.map(att => {
                  if (att.uid === target.value) {
                    return {
                      ...att,
                      isEngineering: target.checked
                    }
                  }
                  return att;
                })
              }
            }
            return f;
          })
        }
      }
    });
  }, [setFormState]);

  const tile = formState.tiles.find(e => e.content === formState.droppedTile);

  const handleSubmit = useCallback(() => {
    if (formState.data.modellingPriority === "Medium"
      || formState.data.modellingPriority === "High"
      || formState.data.modellingPriority === "Low"
      || formState.data.modellingPriority === "No Modelling Required"
      || tile.isLocked) {
      submit({ ...submitArguments });
    } else {
      setFormState(state => ({
        ...state,
        engineeringFilesSelected: true
      }));
    }
  }, [setFormState, submit, submitArguments]);

  const renderFiles = (files, parentId = null, indent = 0) => {
    const children = files.filter(file => file.parentId === parentId || (!parentId && !file.parentId));
    return (
      <>
        {children.map(file => (
          <div key={file.id} style={{ paddingLeft: `${indent}rem` }}>
            <FormControlLabel
              style={{ display: 'flex', flexDirection: 'row' }}
              value={file.uid}
              control={
                <Checkbox checked={file.isEngineering} onChange={handleChange} disabled={(isZip(file.name) && !tile.keepZipFile)} />}
              label={file.name}
              labelPlacement='start'
            />
            {file.attachments?.map(({ uid, name, isEngineering }) => (
              <FormControlLabel
                key={name}
                value={uid}                
                control={<Checkbox checked={isEngineering} onChange={handleChange} />}
                style={{ display: 'flex', flexDirection: 'row', paddingLeft: 20 }}
                label={name}
                labelPlacement='start'
              />
            ))}
            {file.id ? renderFiles(files, file.id, indent + 1): null}
          </div>
        ))}
      </>
    );
  };

  return (
    <Dialog open={popupState?.show} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Engineering Files</DialogTitle>
      <Divider style={{ backgroundColor: "#1FB6AD", padding: "0.04rem" }} />
      <DialogContent style={{ padding: 30, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
        {renderFiles(formState.files)}
      </DialogContent>
      <DialogActions className="dialog-buttons">
        <DialogButton onClick={handleClose}>Cancel</DialogButton>
        <DialogButton onClick={handleSubmit}>Confirm</DialogButton>
      </DialogActions>
    </Dialog>
  )
}