import React, { useEffect } from "react";

import { Grid, TextField, FormControl } from "@material-ui/core";

import { BannerType, Dropdown } from "components/common";
import { fetchUWGroupMembers } from "components/SubmissionForm/Form.api";
import { validateRequiredField, validateTextField } from "components/SubmissionForm/Validation";
import { isMajorClassRequiredModelling } from "components/SubmissionForm/Helpers";

export const BermudaSection = props => {
  const {
    fieldsState,
    dropdownsData,
    validationState,
    setFieldsState,
    setValidationState,
    setDropdownsData,
    setBannerState
  } = { ...props };

  useEffect(() => {
    const loadUnderwriters = async () => {
      let uwGroupMembersResponse = await fetchUWGroupMembers(fieldsState.businessEntity);
      if (uwGroupMembersResponse.success) {
        setDropdownsData(prevState => ({
          ...prevState,
          assignedUWs: uwGroupMembersResponse.data ?? []
        }));
      } else {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: uwGroupMembersResponse.errorMessage
        });
      }
    }

    loadUnderwriters();
  }, [setBannerState, setDropdownsData, fieldsState.businessEntity]);

  const validateMajorClass = (value, entityName) => {
    var result = validateRequiredField("Major Class", value);
    var majorClassesValidationState = { ...validationState.fields.majorClasses };
    majorClassesValidationState[entityName] = result;

    setValidationState(prevState => {
      return {
        ...prevState,
        fields: {
          ...prevState.fields,
          majorClasses: majorClassesValidationState
        }
      }
    });
  };

  const validateYearOfAccount = value => {
    var result = validateRequiredField("YOA", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        yearOfAccount: result
      }
    });
  };

  const validateAssignedUW = value => {
    let result = validateRequiredField("Assigned UW", value);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        assignedUW: result
      }
    }));
  };

  const onMajorClassChanged = event => {
    let value = event.target.value;

    let currentMajorClasses = { ...fieldsState.majorClasses };
    currentMajorClasses[fieldsState.businessEntity] = value;

    setFieldsState({
      ...fieldsState,
      firstMajorClass: value,
      requiredModellingPriority: isMajorClassRequiredModelling(value, fieldsState.businessEntity, dropdownsData) ? 'No Modelling Required' : '',
      majorClasses: currentMajorClasses
    });

    validateMajorClass(value, fieldsState.businessEntity);
  };

  const onMajorClassBlur = (event, entityName) => {
    validateMajorClass(event?.target?.value, entityName);
  };

  const onRequiredModellingPriorityChanged = event => {
    setFieldsState({
      ...fieldsState,
      requiredModellingPriority: event?.target?.value
    });
  };

  const onDealNameChange = event => {
    setFieldsState({
      ...fieldsState,
      dealName: event.target?.value
    });
  };

  const onDealNameBlur = event => {
    var result = validateTextField("Deal Name", event.target?.value);

    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        dealName: result
      }
    });
  };

  const onDocumentTypeChanged = async event => {
    let newDocumentType = event.target.value;
    let result = validateRequiredField("Type Of Document", newDocumentType);

    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        typeOfDocument: result
      }
    }));

    setDropdownsData({ ...dropdownsData });

    setFieldsState(previousState => ({
      ...previousState,
      documentType: newDocumentType
    }));
  };

  const onDocumentTypeBlur = event => {
    const documentType = event?.target?.value;

    let result = validateRequiredField("Type Of Document", documentType);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        typeOfDocument: result
      }
    }));
  };

  const onYearOfAccountChanged = event => {
    setFieldsState({
      ...fieldsState,
      yoa: event.target.value
    });

    validateYearOfAccount(event.target.value);
  };

  const onYearOfAccountBlur = event => {
    validateYearOfAccount(event?.target?.value);
  };

  const onAssignedUWChanged = event => {
    let value = event?.target?.value;
    let assignedUWName = dropdownsData.assignedUWs.find(a => a.mail === value)?.displayName;

    setFieldsState({
      ...fieldsState,
      assignedUW: value,
      assignedUWName
    });

    validateAssignedUW(value);
  };

  const onAssignedUWBlur = event => {
    validateAssignedUW(event?.target?.value);
  };

  const onCedentChange = event => {
    setFieldsState({
      ...fieldsState,
      assured: event.target?.value
    });
  };

  const onCedentBlur = event => {
    var result = validateTextField("Cedent", event.target?.value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        assured: result
      }
    });
  };

  const renderMajorClasses = () => {
    return (
      <Grid item>
        <FormControl
          fullWidth
          required
          error={validationState.fields.majorClasses[fieldsState.businessEntity]?.valid === false}>
          <Dropdown
            id="majorClass"
            label="Major Class"
            value={
              fieldsState.majorClasses[fieldsState.businessEntity]
                ? fieldsState.majorClasses[fieldsState.businessEntity]
                : ''}
            data={dropdownsData.majorClasses.map(d => d.name)}
            onChange={(event) => onMajorClassChanged(event)}
            onBlur={(event) => onMajorClassBlur(event, fieldsState.businessEntity)}
            disabled={fieldsState.businessEntity === ''}
            errorText={validationState.fields.majorClasses[fieldsState.businessEntity]?.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderRequiredModellingPriority = () => {
    return isMajorClassRequiredModelling(fieldsState.firstMajorClass, fieldsState.businessEntity, dropdownsData) && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="requiredModellingPriority"
            label="Required Modelling Priority"
            value={fieldsState.requiredModellingPriority}
            data={["No Modelling Required", "Low", "Medium", "High"]}
            onChange={onRequiredModellingPriorityChanged}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderDealName = () => {
    return (
      <Grid item>
        <TextField
          id="dealName"
          label="Deal Name"
          required
          fullWidth
          onChange={onDealNameChange}
          onBlur={onDealNameBlur}
          value={fieldsState.dealName}
          error={!validationState.fields.dealName.valid}
          helperText={validationState.fields.dealName.message}
        />
      </Grid>
    );
  };

  const renderDocumentType = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.typeOfDocument.valid}>
          <Dropdown
            id="documentType"
            label="Type Of Document"
            value={fieldsState.documentType}
            data={fieldsState.documentTypesList.map(x => x.name)}
            onChange={onDocumentTypeChanged}
            onBlur={onDocumentTypeBlur}
            errorText={validationState.fields.typeOfDocument.message}
            disabled={!fieldsState.businessEntity}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderYearOfAccount = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.yearOfAccount.valid}>
          <Dropdown
            id="yoa"
            label="YOA"
            value={fieldsState.yoa}
            data={dropdownsData.years}
            onChange={onYearOfAccountChanged}
            onBlur={onYearOfAccountBlur}
            disabled={fieldsState.businessEntity === ''}
            errorText={validationState.fields.yearOfAccount.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderAssignedUW = () => {
    return <Grid item>
      <FormControl fullWidth required error={!validationState.fields.assignedUW.valid}>
        <Dropdown
          id="assignedUW"
          label="Assigned UW"
          value={fieldsState.assignedUW}
          data={dropdownsData.assignedUWs.map(a => a.mail)}
          onChange={onAssignedUWChanged}
          onBlur={onAssignedUWBlur}
          errorText={validationState.fields.assignedUW.message}
        />
      </FormControl>
    </Grid>;
  };

  const renderCedant = () => {
    return (
      <Grid item>
        <TextField
          id="assured"
          label="Cedent"
          required
          fullWidth
          onChange={onCedentChange}
          onBlur={onCedentBlur}
          value={fieldsState.assured}
          error={!validationState.fields.assured.valid}
          helperText={validationState.fields.assured.message}
        />
      </Grid>
    );
  };

  return <>
    {renderMajorClasses()}
    {renderRequiredModellingPriority()}
    {renderDealName()}
    {renderDocumentType()}
    {renderYearOfAccount()}
    {renderAssignedUW()}
    {renderCedant()}
  </>;
};