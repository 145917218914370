import "date-fns";

import DateFnsUtils from "@date-io/date-fns";

const endorsementsDocumentType = "Endorsements";
const bulkItemDocumentType = "Bulk Item";
const qcErrorDocumentType = "QC Error";
const politicalRisksMajorClass = "Political Risks";
const dualStampEntity = "Dual Stamp S4000/HIDAC";

const checkProducingTeamFlag = (documentType, businessEntity, firstMajorClass, dropdownsData) => {
  let documentTypeData = dropdownsData.documentTypes?.find(d => d.name === documentType);

  // https://dev.azure.com/Hamilton-development/gupe/_workitems/edit/10809/
  if (documentType === endorsementsDocumentType && firstMajorClass === politicalRisksMajorClass) {
    return true;
  }

  return dropdownsData.entities[businessEntity]?.allowsProducingTeam &&
    documentTypeData?.allowsProducingTeam;
};

export const isPremiumEndorsement = (form) => {
  return form.documentType === endorsementsDocumentType;
};

export const getFormattedDate = date => {
  if (!date) {
    return date;
  }

  let utils = new DateFnsUtils();
  return utils.format(date, "yyyy-MM-dd");
}

export const disableDocumentType = form => {
  return !form.businessEntity;
};

export const isPlacingBasisDisabled = (documentTypesList, documentType) => {
  return documentTypesList
    ?.find(x => x.name === documentType)?.isPlacingBasisDisabled;
};

export const areMinorClassesUsed = (documentType, businessEntity, firstMajorClass, dropdownsData) => {
  return checkProducingTeamFlag(documentType, businessEntity, firstMajorClass, dropdownsData)
    && !dropdownsData.entities[businessEntity]?.disableMinorClasses;
};

export const isProducingTeamUsed = (documentType, businessEntity, firstMajorClass, dropdownsData) => {
  return checkProducingTeamFlag(documentType, businessEntity, firstMajorClass, dropdownsData);
};

export const isNewYorkMgaSectionEnabled = (documentType, documentTypesList) => {
  return documentTypesList?.find(x => x.name === documentType)?.isNewYorkMgaSectionEnabled;
};

export const isBrokerSectionEnabled = product => {
  return !!product?.productCode;
};

export const isStatesSectionEnabled = entity => {
  return false;
};

export const isPolicyReferenceNumberEnabled = (documentTypesList, documentType, isNewAssured) => {
  let isPolicyRefEnabled = documentTypesList
    ?.find(x => x.name === documentType)?.isPolicyReferenceNumberEnabled;

  return isPolicyRefEnabled && !isNewAssured;
};

export const isTotalNumberOfPolicyReferencesEnabled = (documentTypesList, documentType) => {
  return documentTypesList?.find(x => x.name === documentType)?.isTotalNumberOfPolicyReferencesEnabled;
};

export const isMasterPolicyReferenceNumberEnabled = (documentTypesList, dropdownsData, documentType, placingBasis) => {
  return (
    dropdownsData.placingBases?.find(x => x.name === placingBasis)?.isMasterPolicyReferenceNumberEnabled &&
    documentTypesList?.find(x => x.name === documentType)?.isMasterPolicyReferenceNumberEnabled);
};

export const isRenewedPolicyEnabled = (documentTypesList, documentType) => {
  return documentTypesList?.find(x => x.name === documentType)?.isPolicyRenewalEnabled;
};

export const isExpiringPolicyReferenceNumberEnabled = (documentTypesList, documentType, renewedPolicy) => {
  return isRenewedPolicyEnabled(documentTypesList, documentType) && renewedPolicy;
};

export const isPeerReviewNotRequiredEnabled = (documentTypesList, dropdownsData, entity, documentType, placingBasis) => {
  return (
    dropdownsData.entities[entity]?.allowsPeerReview &&
    dropdownsData.placingBases?.find(x => x.name === placingBasis)?.isPeerReviewNotRequiredEnabled &&
    documentTypesList?.find(x => x.name === documentType)?.isPeerReviewNotRequiredEnabled);
};

export const isFinalPricingCompletedEnabled = form => {
  return form.documentTypesList?.find(x => x.name === form.documentType)?.isFinalPricingCompletedEnabled;
};

export const isPricingNotRequiredEnabled = form => {
  return form.documentTypesList?.find(document => document.name === form.documentType)?.isPricingNotRequiredEnabled;
};

export const isTypeOfModellingRiskEnabled = (documentType, documentTypesList) => {
  return documentTypesList?.find(x => x.name === documentType)?.isTypeOfModellingRiskEnabled;
};

export const isEeaBusinessEnabled = (dropdownsData, entity, documentType) => {
  return dropdownsData.entities[entity]?.isEeaBusinessEnabled &&
    dropdownsData.documentTypes?.find(a => a.name === documentType)?.isEeaBusinessEnabled;
};

export const isPlacementMethodEnabled = (dropdownsData, entity, documentType) => {
  return dropdownsData.entities[entity]?.isPlacementMethodEnabled &&
    dropdownsData.documentTypes?.find(a => a.name === documentType)?.isPlacementMethodEnabled;
};

export const isCommentEnabled = (dropdownsData, entity, documentType) => {
  return dropdownsData.entities[entity]?.isCommentEnabled &&
    dropdownsData.documentTypes?.find(a => a.name === documentType)?.isCommentEnabled;
};

export const isBulkItemDocumentType = documentType => {
  return documentType === bulkItemDocumentType;
};

export const isQcErrorDocumentType = documentType => {
  return documentType === qcErrorDocumentType;
};

export const isDualStampEntity = entity => {
  return entity === dualStampEntity;
};

export const hideRenewedPolicy = (documentType) => {
  return isBulkItemDocumentType(documentType) || isQcErrorDocumentType(documentType);
};

export const isMajorClassRequiredModelling = (majorClass, entity, dropdownsData) => {
  return dropdownsData.majorClasses?.find(d => d.name === majorClass)?.modellingRequired &&
    !dropdownsData.entities?.[entity]?.isRequiredModellingPriorityDisabled;
};

export const getMinorClassDefaultValue = (entity, majorClass, minorClasses) => {
  if (entity === "S4000" && majorClass === "Excess Casualty") {
    return minorClasses?.find(mc => mc === "Excess Casualty") ?? "";
  }

  return "";
};

export const getPlacingBasisDefaultValue = (entity, majorClass, placingBasis) => {
  if (entity === "S4000" && majorClass === "Excess Casualty") {
    return placingBasis?.find(mt => mt.name === "Direct")?.name ?? "";
  }

  return "";
};

export const isNYEnergyEnviroWT = (entity, majorClass) => {
  return entity.includes("New York") && (majorClass === "Environmental" || majorClass === "Energy" || majorClass === "War & Terrorism");
}

export const isHSEnergyEnviroWT = (entity, majorClass) => {
  return entity.includes("Hamilton Select") && (majorClass === "Environmental" || majorClass === "Energy" || majorClass === "War & Terrorism");
}

export const isMiamiSubmission = entity => {
  return entity.includes('Miami');
}

export const isNYSubmission = (entity) => {
  return entity.includes("New York");
}

export const isAssuredAddressFilled = (address) => {
  return !!address;
}

export const isHamiltonSelectSubmission = entity => {
  return entity.includes('Hamilton Select');
}