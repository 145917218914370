import React, { useCallback, useState } from 'react';
import { useStarAiContext } from 'star-ai';
import { FormControl, Grid, TextField } from '@material-ui/core';

import { ComponentController, Dropdown, SwitchComponent, FormControlWrapper } from 'components/common';
import { MonthsAndYears, DefaultMonthsAndYearsName } from './common';
import { createMajorClassStructure, GeneralDetailsFields, S1947Entity, DualStampEntity, HidacEntity, S4000Entity, TotalNumberOfMonthsRequiredName, dualStampEntity } from 'components/SubmissionWizard/Helpers';
import { AssuredAndPolicyReferenceControl, SecondAssuredAndPolicyReferenceControl } from './common';
import '../GeneralDetails.css';

const BordereauxBookingSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  trigger,
  validationRef,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  setForceClearDatasourceState,
  modellingMajorClasses,
  resetFieldValidationState
}) => {

  // star ai
  const ai = useStarAiContext();

  const [abortController, setAbortController] = useState(new AbortController());

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const onDualStampSwitch = useCallback(checked => {
    resetFieldValidationState("assured");
    resetFieldValidationState("secondAssured");
    setForceClearDatasourceState({ "assured": true });

    setFormState(previous => {
      const totalNumberOfPolicyReferences = previous.fields[DefaultMonthsAndYearsName];
      return {
        ...previous,
        fields: {
          ...previous.fields,
          _assuredOrReassured: '',
          _secondAssuredOrReassured: '',
          isDualStamp: checked,
          businessEntity: checked ? dualStampEntity : '',
          majorClass: null,
          assured: null,
          reassured: '',
          policyId: null,
          policyReferenceNumber: '',
          secondAssured: null,
          secondReassured: '',
          secondPolicyId: null,
          secondPolicyReferenceNumber: '',
          renewedPolicyS4000: null,
          renewedPolicyHIDAC: null,
          totalNumberOfPolicyReferences: checked ? 2 * totalNumberOfPolicyReferences : totalNumberOfPolicyReferences
        }
      };
    });

    abortController.abort();
  }, [abortController, setFormState, resetFieldValidationState, setForceClearDatasourceState]);

  const onYearPeriodChange = useCallback(year => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        period: { ...previous.fields.period, year }
      }
    }));

  }, [setFormState]);

  const onMonthPeriodChange = useCallback(monthValue => {
    let month = dropdownsData.bordereauxPeriods.months.find(x => x.displayValue === monthValue).value;
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        period: { ...previous.fields.period, month }
      }
    }));

  }, [setFormState]);

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);
    let _prevMajorclassCode = true;

    setFormState(previous => {
      const prevMajorclassCode = previous.fields.majorClass?.majorClassCodes?.includes(majorClass?.majorClassCodes[0]);
      _prevMajorclassCode = prevMajorclassCode;

      if (!_prevMajorclassCode) {
        setForceClearDatasourceState({ "secondAssured": true });
        resetFieldValidationState("secondAssured");
      }

      const _secondAssured = prevMajorclassCode ? previous.fields.secondAssured : null;
      const secondAssuredOrReassured = prevMajorclassCode ? previous.fields._secondAssuredOrReassured : '';
      const _secondPolicyReferenceNumber = prevMajorclassCode ? previous.fields.secondPolicyReferenceNumber : '';
      const _secondPolicyId = prevMajorclassCode ? previous.fields.secondPolicyId : null;
      const _secondReassured = prevMajorclassCode ? previous.fields.secondReassured : null;
      const _renewedPolicyHIDAC = prevMajorclassCode ? previous.fields.renewedPolicyHIDAC : null;
      return {
        ...previous,
        fields: {
          ...previous.fields,
          _secondAssuredOrReassured: secondAssuredOrReassured,
          majorClass: majorClass,
          secondAssured: _secondAssured,
          secondReassured: _secondReassured,
          secondPolicyId: _secondPolicyId,
          secondPolicyReferenceNumber: _secondPolicyReferenceNumber,
          renewedPolicyHIDAC: _renewedPolicyHIDAC,
          requiredModellingPriority: majorClass?.isModellingPriority
            ? previous.dropdownsData.modellingPriorities[0]
            : ''
        }
      }
    });

    abortController.abort();
  }, [abortController, dropdownsData.modellingPriorities, setFormState, resetFieldValidationState, setForceClearDatasourceState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const renderPeriod = () => {
    let render = fields[DefaultMonthsAndYearsName] === 1;
    let monthsDataSource = dropdownsData.bordereauxPeriods.months;
    let months = monthsDataSource.map(x => x.displayValue);
    let selectedValue = monthsDataSource.find(x => x.value === fields.period?.month)?.displayValue;
   
    return (render &&
      <Grid container item spacing={5}>
        <Grid item xs={6}>
          <ComponentController
            name="periodMonth"
            control={control}
            required
            render={({ field: { name, onBlur, onChange } }) =>
              <FormControl fullWidth error={!!errors[name]}>
                <Dropdown
                  id={name}
                  name={name}
                  label={GeneralDetailsFields[name]}
                  disabled={monthsDataSource.length === 0}
                  value={selectedValue}
                  data={months}
                  onChange={e => { onMonthPeriodChange(e.target?.value); onChange(e); }}
                  onBlur={onBlur}
                  errorText={getRequiredError(name)}
                  shouldSetDefaultValue={true}
                  inputRef={ai?.fieldsRef?.[name]}
                />
              </FormControl>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <ComponentController
            name="periodYear"
            control={control}
            required
            render={({ field: { name, onBlur, onChange } }) =>
              <FormControl fullWidth error={!!errors[name]}>
                <Dropdown
                  id={name}
                  name={name}
                  label={GeneralDetailsFields[name]}
                  disabled={dropdownsData.bordereauxPeriods.years.length === 0}
                  value={fields.period?.year}
                  data={dropdownsData.bordereauxPeriods.years}
                  onChange={e => { onYearPeriodChange(e.target?.value); onChange(e); }}
                  onBlur={onBlur}
                  errorText={getRequiredError(name)}
                  shouldSetDefaultValue={true}
                  inputRef={ai?.fieldsRef?.[name]}
                />
              </FormControl>
            }
          />
        </Grid>
      </Grid>
    );
  }

  const renderDualStampSwitch = () => {
    const name = "dualStampSwitch";
    const valueKey = "isDualStamp";
    let isDualStampVisible = [S4000Entity.DisplayValue, HidacEntity.DisplayValue].every(el => {
      return dropdownsData.entitiesForFilter.includes(el)
    });

    return isDualStampVisible && (
      <Grid item>
        <FormControlWrapper
          control={
            <SwitchComponent
              name={name}
              checked={fields[valueKey]}
              onChange={e => onDualStampSwitch(e.target?.checked)}
              inputRef={ai?.fieldsRef?.[valueKey]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {    
    let businessEntities = [S4000Entity.DisplayValue, HidacEntity.DisplayValue, S1947Entity.DisplayValue, DualStampEntity.DisplayValue].filter((el) => {
      return dropdownsData.entitiesForFilter.includes(el)
    });

    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      abortController={abortController}
      setAbortController={setAbortController}
      additionalRequestData={{ documentType: fields.documentType, businessEntities: fields.isDualStamp ? [S4000Entity.DisplayValue] : businessEntities }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderAssured = (assuredType = "assured") => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[assuredType]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = (policyReferenceNumberType = "policyReferenceNumber") => {
    let name = "policyReferenceNumber";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[policyReferenceNumberType]}
          value={fields.policyReferenceNumber}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderMonthsAndYears = () => {
    let render = true;

    return render && (
      <MonthsAndYears
        dropdownsData={dropdownsData}
        totalNumberOfMonthsRequired={fields[DefaultMonthsAndYearsName]}
        label={GeneralDetailsFields[TotalNumberOfMonthsRequiredName]}
        fields={fields}
        control={control}
        errors={errors}
        trigger={trigger}
        setFormState={setFormState}
        setBannerState={setBannerState}
        validationRef={validationRef}
        isRequired={true}
        setForceClearDatasourceState ={setForceClearDatasourceState}
        resetFieldValidationState={resetFieldValidationState}
        inputRef={ai?.fieldsRef?.[DefaultMonthsAndYearsName]}
      />
    );
  }


  const renderSecondLookupForAssuredAndPolicyReference = (lookupType = "secondLookupForAssuredAndPolicyReference") => {
    let businessEntities = ["HDAC", "IDAC"].filter((el) => {
      return dropdownsData.entitiesForFilter.includes(el)
    });

    return (fields.isDualStamp && <SecondAssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      additionalRequestData={{ documentType: fields.documentType, businessEntities: businessEntities }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      includePlacingBasis={true}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderSecondAssured = () => {
    let name = "secondAssured";
    return (
      fields.isDualStamp &&
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.secondAssured?.assured ?? fields.secondReassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderSecondPolicyReferenceNumber = () => {
    let name = "secondPolicyReferenceNumber";
    return (
      fields.isDualStamp &&
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.secondPolicyReferenceNumber}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderTypeOfBordereaux = () => {
    const name = "typeOfBordereaux";
    return (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields.typeOfBordereaux}
                value={fields.typeOfBordereaux}
                data={dropdownsData.typesOfBordereaux}
                onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  return <>
    {renderMonthsAndYears()}
    {renderPeriod()}
    {renderDualStampSwitch()}
    {renderLookupForAssuredAndPolicyReference(fields.isDualStamp ? "lookupForAssuredAndPolicyReference4000" : "lookupForAssuredAndPolicyReference")}
    {renderPolicyReferenceNumber(fields.isDualStamp ? "policyReferenceNumber4000" : "policyReferenceNumber")}
    {renderAssured(fields.isDualStamp ? "assuredOrMasterAssured4000" : "assured")}
    {renderSecondLookupForAssuredAndPolicyReference()}
    {renderSecondPolicyReferenceNumber()}
    {renderSecondAssured()}
    {renderTypeOfBordereaux()}
  </>;
}

export default BordereauxBookingSection;