import { QueryParametersConstants } from "./QueryParameters";
import { getFormattedDate } from 'components/SubmissionForm/Helpers';

const QuoteMapper = {
  mapQueryParametersToForm: parameters => {
    let policyNoParameter = QueryParametersConstants.PolicyNo;
    let statusParameter = QueryParametersConstants.Status;
    let brokerContactParameter = QueryParametersConstants.BrokerContact;
    let brokerContactEmailParameter = QueryParametersConstants.BrokerContactEmail;
    let brokerNameParameter = QueryParametersConstants.BrokerName;
    let brokerBranchParameter = QueryParametersConstants.BrokerBranch;
    let insuredParameter = QueryParametersConstants.Insured;
    let sharepointParameter = QueryParametersConstants.Sharepoint;
    let targetParameter = QueryParametersConstants.Target;

    return {
      policyNo: searchParameter(parameters, policyNoParameter),
      status: searchParameter(parameters, statusParameter),
      brokerContact: searchParameter(parameters, brokerContactParameter),
      brokerContactEmail: searchParameter(parameters, brokerContactEmailParameter),
      brokerName: searchParameter(parameters, brokerNameParameter),
      brokerBranch: searchParameter(parameters, brokerBranchParameter),
      insured: searchParameter(parameters, insuredParameter),
      sharepoint: searchParameter(parameters, sharepointParameter),
      target: searchParameter(parameters, targetParameter)
    };
  },
  mapFormToRequest: (data) => {
    let formData = new FormData();

    formData.append("message", data.message);
    formData.append("message2", data.message2);
    formData.append("policyNumber", data.policyNo);
    formData.append("subject", data.status);
    formData.append("effectiveDate", getFormattedDate(data.effectiveDate));
    formData.append("waitingPeriod", data.waitingPeriod);
    formData.append("underwriterEmail", data.underwriterEmail);
    formData.append("underwriterName", data.notificationRequest.UnderwriterName);
    formData.append("underwriterTitle", data.notificationRequest.Title);
    formData.append("underwriterPhoneNumber", data.notificationRequest.PhoneNumber);
    formData.append("brokerEmail", data.brokerContactEmail);
    formData.append("brokerContact", data.notificationRequest.BrokerName);
    formData.append("brokerBranch", data.notificationRequest.BrokerBranch);
    formData.append("brokerName", data.brokerName);
    formData.append("insured", data.notificationRequest.Insured);
    formData.append("productId", data.product.productId);
    formData.append("companyId", data.companyId);
    formData.append("newBrokerId", data.broker.id);
    formData.append("newBranchId", data.brokerBranch.id);
    formData.append("newBrokerContact", data.brokerContact.id);
    formData.append("newBrokerEmail", data.newBrokerEmail);
    formData.append("newBrokerName", data.broker.name);
    formData.append("sharepoint", data.sharepoint);
    formData.append("target", data.target);
    return formData;
  }
}

const searchParameter = (object, key) => {
  return object[Object.keys(object)
    .find(k => k.toLowerCase() === key.toLowerCase())
  ];
}

export default QuoteMapper;