import React, { useEffect, useCallback } from 'react';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import { fetchCandidatesMtg } from 'components/RpaDropzones/RpaDropzones.Api';

const theme = createTheme({
  typography: {
    body1: {      
      fontSize: '0.8rem',
    }
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 500,
    maxHeight: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const MTGSearchComponent = ({ mTGRelatedPopup, setFormState, filterFromCurrent }) => {
  const classes = useStyles();

  useEffect(async () => {
    let candidatesData = await fetchCandidatesMtg(mTGRelatedPopup.id, mTGRelatedPopup.yoa, mTGRelatedPopup.majorClass, mTGRelatedPopup.entity);
    let candidatesSorted = [
      ...candidatesData.data.filter(candiate => candiate.clientId === mTGRelatedPopup.clientId).sort((a, b) => (a.name.localeCompare(b.name) || a.closeDate - b.closeDate)),
      ...candidatesData.data.filter(candiate => candiate.clientId !== mTGRelatedPopup.clientId).sort((a, b) => (a.name.localeCompare(b.name) || a.closeDate - b.closeDate))
    ];

    let candidates = candidatesSorted.map(x => {
      let displayName = `${x.mtg.replace("MTG", "")} ${x.name} ${x.closeDate}`;
      return { ...x, displayName: displayName };
    })

    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        candidates: candidates,
        candidatesToShow: filterFromCurrent(candidates)
      }
    }));
  }, []);

  const renderRow = (props) => {
    const { index, style } = props;

    return (
      <ListItem button style={style} key={index}>
        <ThemeProvider theme={theme}>
          <ListItemText primary={mTGRelatedPopup.candidatesToShow[index].displayName} onClick={() => handleCandidateToShow(mTGRelatedPopup.candidatesToShow[index].id)} />
        </ThemeProvider>
      </ListItem>
    );
  } 

  const handleCandidateToShow = useCallback(id => {
    let relatedOpprotunities = mTGRelatedPopup.relatedOpprotunities;
    let candidate = mTGRelatedPopup.candidatesToShow.find(x => x.id === id);
    let candidatesToShow = mTGRelatedPopup.candidatesToShow.filter(x => x.id !== id);

    if (!mTGRelatedPopup.relatedOpprotunities.find(x => x.relationStatus === 'Primary')) {
      let primary = mTGRelatedPopup.candidates.find(x => x.isCurrent === true);
      primary.relationStatus = 'Primary';
      candidatesToShow = candidatesToShow.filter(x => x.id !== primary.id);
      relatedOpprotunities = [...relatedOpprotunities, primary];
      relatedOpprotunities = [...relatedOpprotunities, candidate];

      setFormState(state => ({
        ...state,
        mTGRelatedPopup: {
          ...state.mTGRelatedPopup,
          relatedOpprotunities: relatedOpprotunities,
          candidatesToShow: candidatesToShow
        }
      }));
      return;
    }
    else if (candidate.isCurrent !== true) {
      candidate.relationStatus = 'Related';
    }

    relatedOpprotunities = [...relatedOpprotunities, candidate];

    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        relatedOpprotunities: relatedOpprotunities,
        candidatesToShow: filterFromCurrent(candidatesToShow)
      }
    }));
  }, [mTGRelatedPopup, setFormState]);


  const handleTextChange = useCallback(event => {
    let value = event.target.value;
    let related = mTGRelatedPopup.relatedOpprotunities
    let candidates = mTGRelatedPopup.candidates.filter(x => x.displayName.toLowerCase().includes(value.toLowerCase()));
    let result = candidates.filter(c => {
      return !related.find(r => {
        return r.id === c.id;
      });
    });

    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        candidatesToShow: filterFromCurrent(result)
      }
    }));
  }, [mTGRelatedPopup, setFormState]);

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  return (
    <div className={classes.root}>
      <TextField id="outlined-basic" label="Select or search for related MTGs." variant="outlined" fullWidth onChange={handleTextChange} style={{ padding: '10px', marginTop: '10px' }} />
      <FixedSizeList height={400} itemSize={40} itemCount={mTGRelatedPopup.candidatesToShow.length}>
        {renderRow}
      </FixedSizeList>
    </div>
  );
}
