import { QueryParametersConstants, ModellingPriorities } from "./Constants";

export const RpaMapper = {
  mapQueryParametersToForm: parameters => {
    let entityParameter = QueryParametersConstants.Entity;
    let majorClassParameter = QueryParametersConstants.MajorClass;
    let modellingPriorityParameter = QueryParametersConstants.ModellingPriority;
    let dealNameParameter = QueryParametersConstants.DealName;
    let yoaParameter = QueryParametersConstants.Yoa;
    let uwParameter = QueryParametersConstants.UnderwriterEmail;
    let cedentParameter = QueryParametersConstants.Cedent;
    let businessIdParameter = QueryParametersConstants.BusinnesId;
    let IsInitialCasualtyRequest = QueryParametersConstants.IsInitialCasualtyRequest;

    return {
      [entityParameter]: parameters[entityParameter],
      [majorClassParameter]: parameters[majorClassParameter],
      [modellingPriorityParameter]: parameters[modellingPriorityParameter],
      [dealNameParameter]: parameters[dealNameParameter],
      [yoaParameter]: parameters[yoaParameter],
      [uwParameter]: parameters[uwParameter],
      [cedentParameter]: parameters[cedentParameter],
      [businessIdParameter]: parameters[businessIdParameter],
      [IsInitialCasualtyRequest]: parameters[IsInitialCasualtyRequest]
    };
  },
  mapFormToRequest: form => {
    let formData = form.data;
    let popupData = form.popup?.data;

    let entity = formData[QueryParametersConstants.Entity];
    let modellingPriority = formData[QueryParametersConstants.ModellingPriority];

    let requiredModellingPriority = popupData?.requiredModellingPriority ?? modellingPriority;

    let majorClass = formData[QueryParametersConstants.MajorClass];
    let IsInitialCasualtyRequest = formData[QueryParametersConstants.IsInitialCasualtyRequest];
    let isInitialModellingRequest = majorClass != "Casualty Reinsurance" && majorClass != "Casualty Insurance" ? !modellingPriority || modellingPriority == ModellingPriorities.NotRequired : IsInitialCasualtyRequest;

    let BusinessIdsToDecline = [];
    if (form.droppedTile == "Decline") {
      BusinessIdsToDecline = form.mTGRelatedPopup.relatedOpprotunitiesToDecline.filter((item) => item.IsDeclined).map(x => {
        return x.id;
      });
    }
    

    return {
      ...popupData,
      businessEntity: entity,
      majorClasses: { [entity]: formData[QueryParametersConstants.MajorClass] },
      requiredModellingPriority,
      dealName: formData[QueryParametersConstants.DealName],
      businessId: formData[QueryParametersConstants.BusinnesId],
      yoa: parseInt(formData[QueryParametersConstants.Yoa]),
      assignedUW: formData[QueryParametersConstants.UnderwriterEmail],
      assured: { name: formData[QueryParametersConstants.Cedent] },
      underwriterEmail: formData.underwriterEmail,
      documentType: form.droppedTile,
      totalNumberOfPolicyReferences: 1,
      peerReviewNotRequired: true,
      isInitialModellingRequest,
      businessIdsToDecline: BusinessIdsToDecline
    };
  }
}