import { authorizedApiClient } from "Api";

const api = {
  brokers: {
    buildMessageModel: "api/brokersNotifications/build-message",
    submit: "api/brokersNotifications/sendMails"
  },
  policy: {
    get: "api/policy/{policyNo}"
  }
};

export const fetchPolicy = async policyNo => {
  try {
    let response = await authorizedApiClient.get(api.policy.get.replace("{policyNo}", policyNo));
    return {
      success: true,
      data: response.data
    }
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occured during fetching policy details: " + error
    }
  }
};

export const fetchEmailTemplate = async request => {
  try {
    let response = await authorizedApiClient.post(api.brokers.buildMessageModel, request);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during getting email template: " + error
    };
  }
};

export const sendData = async formData => {
  try {
    await authorizedApiClient.post(api.brokers.submit, formData);
    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during sending email: " + error
    };
  }
};