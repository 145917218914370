import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Grid, FormControl, TextField } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

import { BannerType, ComponentController, Dropdown } from 'components/common';
import { TotalNumberOfMonthsRequiredName } from 'components/SubmissionWizard/Helpers';

export const DefaultMonthsAndYearsName = "totalNumberOfMonthsRequired"

export const MonthsAndYears = ({
  dropdownsData,
  fields,
  label,
  control,
  errors,
  setFormState,
  setBannerState,
  validationRef,
  trigger,
  setForceClearDatasourceState,
  resetFieldValidationState,
  inputRef
}
) => {
  const columnsConfig = [
    {
      field: 'month',
      headerName: 'Month',
      minWidth: 250,
      datasource: 'months',
      type: "singleSelect",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      renderEditCell: {},
      forceRerender: false,
      mandatory: true
    },
    {
      field: 'year',
      headerName: 'Year',
      minWidth: 200,
      type: "singleSelect",
      datasource: 'years',
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      renderEditCell: {},
      mandatory: true
    }];

  const [selectedCell, setSelectedCell] = useState();
  const [columns, setColumns] = useState(columnsConfig);
  const fieldsRef = React.useRef();
  const baseValidationRef = validationRef.current;
  const minValue = 1;
  const maxValue = 12;

  fieldsRef.current = fields;

  const monthsAndYearsFieldValue = fieldsRef.current[DefaultMonthsAndYearsName]

  useEffect(() => {
    let columns = columnsConfig.map(c => {
      if (c.dropdownContainer) {
        c.renderEditCell = dropdownContainer;
      }
      if (c.mandatory) {
        c.headerName = `${c.headerName}*`;
      }
      return c;
    });

    setColumns(columns);

    let initialmonthsAndYears = [];
    initialmonthsAndYears.push(
      {
        id: uuidv4(),
        month: '',
        year: ''
      });

    setFormState(previous => {
      let totalNumberOfMonthsRequired = previous.fields[DefaultMonthsAndYearsName] != null ? previous.fields[DefaultMonthsAndYearsName] : 1
      let monthsAndYears = initialmonthsAndYears;
      if (totalNumberOfMonthsRequired === 1) {
        monthsAndYears = [];
      }

      return {
        ...previous,
        fields: {
          ...previous.fields,
          monthsAndYears: monthsAndYears,
          [DefaultMonthsAndYearsName]: totalNumberOfMonthsRequired
        }
      };
    });
  }, []);

  let validateGrid = () => {
    let conditionCodeValidationStatus = fieldsRef.current.monthsAndYears.map(x => {
      var hasEmpty = columnsConfig
        .filter(c => c.mandatory)
        .some(c => x[c.field] === '');
      return !hasEmpty;
    });
    return conditionCodeValidationStatus.every(x => x);
  }

  useEffect(() => {
    validationRef.current = async () => {
      const isBaseFormValid = await baseValidationRef();
      if (!isBaseFormValid) return false;

      let isGridVisible = fieldsRef.current.monthsAndYears.length > 0;

      if (!isGridVisible) {
        return true;
      }

      let isValid = validateGrid();

      if (!isValid) {
        let message = "Please complete all available Months and Years";

        setBannerState({
          show: true,
          type: BannerType.warning,
          message: message
        });
      }

      return isValid;
    };
  }, [setBannerState, trigger, validationRef, fieldsRef.current, fieldsRef.current.monthsAndYears])


  const getDropdownContainerData = useCallback(params => {
    let data = {};
    let source = params.field;
    let id = params.id;
    let value = fieldsRef.current.monthsAndYears?.find(x => x.id === id)?.[source];;

    if (source === 'month') {
      data = dropdownsData.bordereauxPeriods.months.map(x => x.displayValue);
    }
    else {
      data = dropdownsData.bordereauxPeriods.years;
    }

    return {
      source: source,
      data: data,
      id: id,
      value: value
    }
  }, [dropdownsData.conditionCodes]);

  const dropdownContainer = params => {
    let data = getDropdownContainerData(params);
    const { id, field, api } = params;

    return data && (
      <ComponentController
        name={data.source}
        control={control}
        required
        render={({ field: { name, onBlur, onChange } }) =>
          <FormControl fullWidth error={!!errors[name]}>
            <Dropdown
              id={data.id}
              name={name}
              label={data.value}
              value={data.value}
              data={data.data}
              onChange={e => { handleDropDownChange(e.target.name, e.target.value, data.id); onChange(e); api.setCellMode(id, field, 'view'); }}
              onBlur={onBlur}
              hideLabel={true}
            />
          </FormControl>
        }
      />
    );
  }

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{label}" is required</span>;
  }

  const onCellClick = useCallback(cell => {
    setSelectedCell(cell);
  }, [setSelectedCell]);

  const handleDropDownChange = useCallback((editedRowsName, editedRowsValue, id) => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        monthsAndYears: fieldsRef.current.monthsAndYears.map(p => {
          if (p.id === id) {
            p[editedRowsName] = editedRowsValue;
          }

          return p;
        })
      }
    }));

    setColumns(previous => {
      return previous.map(p => {
        if ('forceRerender' in p) {
          p.forceRerender = !p.forceRerender;
        }
        return p;
      });
    });
  }, [setFormState, setColumns]);

  const onTotalNumberOfMonthsRequiredChange = useCallback(value => {
    let totalNumberOfMonths = Number(value.target.value);
    let totalNumberOfPolicyReferences = fieldsRef.current.isDualStamp ? 2 * totalNumberOfMonths : totalNumberOfMonths;
    let _monthsAndYears = fieldsRef.current.monthsAndYears;
    let numbers = totalNumberOfMonths - monthsAndYearsFieldValue;

    if (totalNumberOfMonths < minValue || totalNumberOfMonths > maxValue) {
      return;
    }

    // In case if user changes totalNumberOfMonthsRequired from 1 to 2 then 2 rows should appear as it was before bug fix 131649
    if (monthsAndYearsFieldValue === 1) {
      let initialMonthsAndYears = [];
      initialMonthsAndYears.push(
        {
          id: uuidv4(),
          month: '',
          year: ''
        });

      _monthsAndYears = [...initialMonthsAndYears];
    }

    // In case if user changes totalNumberOfMonthsRequired to 1 then month and year fields should be refer in order to submit
    if (totalNumberOfMonths === 1) {
      _monthsAndYears = [];
    }
    else if (totalNumberOfMonths > monthsAndYearsFieldValue) {
      _monthsAndYears = [..._monthsAndYears, ...Array.from({ length: numbers }, (_, i) => i + 1).map(i => ({
        id: uuidv4(),
        month: '',
        year: ''
      }))];
    }
    else if (totalNumberOfMonths < monthsAndYearsFieldValue) {
      let tempArr = [];
      for (let i = 0; i < totalNumberOfMonths; i++) {
        tempArr = [...tempArr, _monthsAndYears[i]]
      }
      _monthsAndYears = tempArr;
    }
    resetFieldValidationState("periodMonth");
    setForceClearDatasourceState({ "periodMonth": true });
    resetFieldValidationState("periodYear");
    setForceClearDatasourceState({ "periodYear": true });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        monthsAndYears: _monthsAndYears,
        [DefaultMonthsAndYearsName]: totalNumberOfMonths,
        period: null,
        totalNumberOfPolicyReferences: totalNumberOfPolicyReferences
      }
    }));
  }, [setFormState, resetFieldValidationState, setForceClearDatasourceState])

  const getTotalNumberofMonthsError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">"{name}" must be between 1 and 12</span>;
  }

  
  const validateTotalNumberOfMonths = useCallback(() => {
    // When user does not change totalNumberOfMonthsRequired and it is 1  then total is undefined
    let totalNumberOfMonthsRequired = monthsAndYearsFieldValue;
    return totalNumberOfMonthsRequired <= maxValue && totalNumberOfMonthsRequired >= minValue;
  }, [monthsAndYearsFieldValue]);

  const renderTotalNumberOfMonthsRequired = () => {
    return (
      <Grid item>
        <ComponentController
          name={TotalNumberOfMonthsRequiredName}
          control={control}
          rules={{ validate: validateTotalNumberOfMonths }}
          render={({ field: { name, onChange, onBlur } }) =>
            <TextField
              id={name}
              name={name}
              type="number"
              label={TotalNumberOfMonthsRequiredName}
              fullWidth
              value={monthsAndYearsFieldValue}
              onChange={e => {
                onTotalNumberOfMonthsRequiredChange(e);
                onChange(e);
              }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={getRequiredError(name) || getTotalNumberofMonthsError(TotalNumberOfMonthsRequiredName)}
              inputRef={inputRef}
            />
          }
        />
      </Grid>
    );
  }

  const renderMonthsAndYears = () => {
    let render = monthsAndYearsFieldValue > 1;

    return render && (
      <Grid item>
        <DataGrid
          rowHeight={55}
          rows={fieldsRef.current.monthsAndYears}
          columns={columns}
          density="compact"
          hideFooter={true}
          autoHeight={true}
          onCellClick={e => { onCellClick(e) }}
          onEditRowsModelChange={handleDropDownChange}
        />
      </Grid>
    );
  }

  return <>
    {renderTotalNumberOfMonthsRequired()}
    {renderMonthsAndYears()}
  </>;
}
