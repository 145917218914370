import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import { Grid, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Error } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';

import { fetchCurrentUser } from 'Api';
import { Footer, Banner, BannerType } from '../common';
import { DragAndDropArea } from './DragAndDrop';
import { ErrorPage } from '../navigation';
import { ActivateFolderLink, ShowFolderLink, SetRpaStatus } from '../navigation/NavMenu';

import { RpaMapper, TilesNames, ModellingPriorities, parseMessage, initDocument, uploadFileByChunks, inspectZipFile, isZip, ChunkSize, FileUploadStatus, FileType, FileBlob } from './Helpers';
import { PopupsFactory } from './Popups';
import { fetchDropzones, uploadFile, sendData, updateMTG } from './RpaDropzones.Api';

import './RpaDropzones.css';

const preventSubmission = (tileName, formState, declined) => {

  let modellingPriority = formState.data.modellingPriority;
  let majorClass = formState.data.majorClass;
  let tile = formState.tiles.find((t) => { return t.content == tileName; });

  if (tileName == TilesNames.SubmissionData) {
    if ((majorClass == "Casualty Reinsurance" || majorClass == "Casualty Insurance")) {
      return false;
    }

    let isModellingPerformed = [
      ModellingPriorities.High,
      ModellingPriorities.Medium,
      ModellingPriorities.Low,
      ModellingPriorities.NotRequired
    ].includes(modellingPriority);

    return majorClass == "Property Insurance" || (!tile.isLocked && !isModellingPerformed);
  }

  if (tileName == TilesNames.Decline) {
    return !declined && !tile.isLocked;
  }

  if (tileName == TilesNames.Endorsements && majorClass == "Property Insurance") {
    return true;
  }

  if (majorClass == "Financial Lines Insurance" &&
    [
      TilesNames.Quotes,
      TilesNames.Binders,
      TilesNames.FinalPolicy
    ].includes(tileName)) {
    return true;
  }

  return false;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export const RpaDropzones = ({ setLoading }) => {
  const classes = useStyles();
  const initialFormState = {
    data: {},
    files: [],
    tiles: [],
    droppedTile: '',
    mTGRelatedPopup: {
      id: '',
      yoa: '',
      entity: '',
      mainMTG: '',
      originalOpportunity: [],
      relatedOpprotunities: [],
      candidates: [],
      candidatesToShow: [],
      show: false,
      isSearch: false,
      clientId: '',
      majorClass: '',
      disableChanges: false,
      relatedOpprotunitiesToDecline: [],
    },
    isModellingTask: false,
    isMTGRelated: false,
    popup: {
      data: {},
      show: false
    }
  };

  const location = useLocation();

  const [formState, setFormState] = useState(initialFormState);

  const [validationState, setValidationState] = useState({
    valid: true,
    errors: []
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [bannerState, setBannerState] = useState({});

  useEffect(() => {
    let initializeForm = async () => {
      let queryParameters = parse(location.search);
      let formData = RpaMapper.mapQueryParametersToForm(queryParameters);

      let dropzonesResponse = await fetchDropzones(formData);

      if (dropzonesResponse.success) {
        var isUsed = dropzonesResponse.data.tiles.some(x => x.used);
        var isRelated = dropzonesResponse.data.tiles.some(x => x.related);
        ShowFolderLink(true);
        ActivateFolderLink(isUsed);
        SetRpaStatus(dropzonesResponse.data.rpaStatusIndicator, dropzonesResponse.data.rpaStatusDate, queryParameters.majorClass);

        let user = await fetchCurrentUser();
        setFormState(state => ({
          ...state,
          ...dropzonesResponse.data,
          data: {
            ...state.data,
            ...formData,
            underwriterEmail: user?.mail
          },
          mTGRelatedPopup: {
            ...state.mTGRelatedPopup,
            id: queryParameters.businessId,
            yoa: queryParameters.yoa,
            entity: queryParameters.entity,
            majorClass: queryParameters.majorClass,
            mainMTG: 'MTG' + queryParameters.dealName.split('MTG').pop(),
            clientId: dropzonesResponse.data.tiles[0].clientId
          },
          isMTGRelated: isRelated
        }));
      } else {
        if (dropzonesResponse.permissionError) {
          setErrorMessage(dropzonesResponse.errorMessage);
        } else if (dropzonesResponse.validationError) {
          setValidationState({
            ...dropzonesResponse.validationResult
          });
          setBannerState({
            show: true,
            type: BannerType.warning,
            message: 'Validation is not passed. Please take a look at the errors.'
          });
        } else {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: dropzonesResponse.errorMessage
          });
        }
      }

      setLoading(false);
    }

    setLoading(true);
    initializeForm();
  }, []);

  const onSuccessUpload = useCallback(async () => {
    let droppedTile = formState.droppedTile;

    //Add call for update MTG relations
    let originalMtgRelation = formState.mTGRelatedPopup.originalOpportunity.map(x => x.id);
    let updateMtgRelation = formState.mTGRelatedPopup.relatedOpprotunities.map(x => x.id);
    let formData = formState.data;
    let updateMtgResponse = await updateMTG(originalMtgRelation, updateMtgRelation, formData);

    if (!updateMtgResponse.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: `Error occurred on update of MTG relations. Submission is not completed for '${droppedTile}'`
      });
    } else {

      let filesToSubmit = !formState.files ? null : formState.files.map(fileData => {
        if (fileData.type === FileType.document) {
          return { name: fileData.file.name, path: fileData.upload.path, isEngineering: fileData.isEngineering, isFromZip: fileData.isFromZip };
        }
        else {
          return {
            name: fileData.file.name,
            path: fileData.upload.path,
            body: fileData.body,
            attachments: fileData.attachments.map(att => {
              return { id: att.id, name: att.name, isAttached: att.isAttached, isEngineering: att.isEngineering };
            }),
            title: fileData.title,
            sender: fileData.sender,
            isEngineering: fileData.isEngineering,
            isFromZip: fileData.isFromZip,
            isAttached: fileData.isAttached
          };
        }
      });

      formState.data.IsInitialCasualtyRequest = formState.data.IsInitialCasualtyRequest == undefined ? true : false;
      let request = RpaMapper.mapFormToRequest(formState);
      let response = await sendData(request, filesToSubmit);

      if (response.success) {
        ActivateFolderLink(true);
        if (formState.tiles[0].isLocked) {
          setBannerState({
            show: true,
            type: BannerType.warning,
            message: `Deal is LOCKED but files would be saved and relations updated if needed.`
          });
        }
        else {
          setBannerState({
            show: true,
            type: BannerType.success,
            message: `Upload successfully completed for '${droppedTile}'`
          });
        }
      } else {
        ActivateFolderLink(false);
        setBannerState({
          show: true,
          type: BannerType.error,
          message: `Error occurred. Submission is not completed for '${droppedTile}'`
        });
      }
    }
    setLoading(false);
  }, [setLoading, formState]);

  const onFailedUpload = useCallback((file) => {
    let errorMessage = file.upload?.error
      ? `Error occurred. Submission is not completed for '${formState.droppedTile}'. Error: '${file.upload.error}'`
      : `Error occurred. Submission is not completed for '${formState.droppedTile}'.  Error: This file has not been uploaded. Please try again. If the error persists, the file will need to be manually stored. If this is the case, also please email hamiltonre.submissions@hamiltongroup.com that you’ve had a problem. This is a known issue that is being actively investigated.`;

    setBannerState({
      show: true,
      type: BannerType.error,
      expose: true,
      message: errorMessage
    });

    setLoading(false);
  }, [setLoading, formState.droppedTile]);

  useEffect(() => {
    if (formState.files.length > 0) {
      let files = formState.files;
      let isUploadingFinished = !files.some(f => f.upload.status === FileUploadStatus.inProgress);

      if (isUploadingFinished) {
        const filesAreUploaded = files.every(f => f.upload.status === FileUploadStatus.success);
        if (filesAreUploaded) {
          onSuccessUpload();
        } else {
          let fileWithError = files.find(f => f.upload.status === FileUploadStatus.failed);
          onFailedUpload(fileWithError);
        }

        setFormState(state => ({
          ...state,
          engineeringFilesSelected: false,
          declineRelatedMtgSelected: false,
          popup: initialFormState.popup,
          droppedTile: '',
          files: []
        }));
      }
    }
  }, [formState.files, onSuccessUpload, onFailedUpload]);

  const startUploading = async (fileName, file) => {
    let onUploadArguments = { fileName };

    let uploadPromise = file.content.byteLength > ChunkSize.chunk
      ? uploadFileByChunks(file, uploadFile, onFileUpload, onUploadArguments)
      : uploadDocument(file);

    setFormState(previousState => {
      let updatedFiles = previousState.files.map(f => {
        if (f.name === fileName) {
          let updatedUpload = { status: FileUploadStatus.inProgress, promise: uploadPromise };
          return { ...f, ...file, attachments: f.attachments, upload: updatedUpload };
        }
        return f;
      });
      return { ...previousState, files: updatedFiles };
    });
  }

  const uploadDocument = async document => {
    let fileName = document.file.name;
    let isZeroSize = document.content.byteLength == 0;
    let containsZeroAttachment = document.attachments?.some(a => a.size == 0);
    let response;

    if (isZeroSize || containsZeroAttachment) {
      let error = isZeroSize
        ? `File '${fileName}' has zero size'`
        : `File '${fileName}' contains an attachement that has zero size'`

      response = { success: false, error: error };
    }
    else {
      response = await uploadFile(fileName, document.content);
    }

    if (response.success) {
      onFileUpload({ fileName, uploadPath: [response.uploadPath] });
    } else {
      onFileUpload({ fileName, uploadPath: null, error: [response.error] });
      throw response.error;
    }
  }

  const onFileUpload = ({ fileName, uploadPath, error }) => {
    let success = !!uploadPath;

    setFormState(previousState => {
      let updatedFiles = previousState.files.map(file => {
        if (file.name === fileName) {
          let updatedUpload = {
            ...file.upload,
            status: success ? FileUploadStatus.success : FileUploadStatus.failed,
            path: uploadPath,
            error: error
          };
          return { ...file, upload: updatedUpload };
        }
        return file;
      });
      return { ...previousState, files: updatedFiles };
    });
  }

  const submit = ({ filesList }) => {
    setLoading(true);
    setBannerState({
      show: true,
      type: BannerType.info,
      message: 'Submission has been started.'
    });

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        let file = filesList[i];
        let reader = new FileReader();
        let fileReadHandler = file.size != 0 && file.name.endsWith(".msg") ? onMailRead : onDocumentRead;
        reader.onload = event => fileReadHandler(event, file);
        reader.readAsArrayBuffer(file);
      }
    } else {
      onSuccessUpload();
    }
  }

  const onMailRead = async (event, file) => {
    const message = parseMessage(event.target.result, file);
    message.attachments.forEach(a => a.isAttached = !a.mimeType?.startsWith("image/"));
    await startUploading(message.messageFile.name, message);
  };

  const onDocumentRead = async (event, file) => {
    const document = initDocument(event.target.result, file);
    await startUploading(document.file.name, document);
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    })
  }

  const readZipAsync = (zipFile, zipName) => {
    return new Promise((resolve, reject) => {
      inspectZipFile(zipFile, zipName)
        .then((fileInfoList) => resolve(fileInfoList))
        .catch((error) => {
          console.error('Error on read zipFile', error);
          reject(error);
        });
    })
  }

  const getParentFolder = (str) => {
    const lastSlashIndex = str.lastIndexOf('/');
    if (lastSlashIndex === -1) {
      return "";
    } else {
      return str.slice(0, lastSlashIndex);
    }
  }

  const onTileDrop = async (event, droppedTile) => {
    let filesList = [];
    let fileNames = [];

    const tile = formState.tiles.find(e => e.content === droppedTile);

    async function checkFileAndPopupateAttachments(file, isFromZip, isAttached) {
      filesList.push(file);
      file.isFromZip = isFromZip;
      file.isAttached = isAttached;

      if (file.name.endsWith(".msg") && file.size > 0) {
        let buffer = await readFileAsync(file);
        let messageData = parseMessage(buffer, file);
        file.attachments = [];

        let attachments = messageData.attachments.filter(a => !a.mimeType?.startsWith("image/"));

        for (var i = 0; i < attachments.length; i++) {
          let att = attachments[i];
          const baseName = att.name.slice(att.name.lastIndexOf('/') + 1); 
          var parentName = getParentFolder(file.name);
          const fullName = parentName != ""
            ? (parentName + '/' + baseName)
            : baseName;

          if (att.name.endsWith(".msg") || att.name.endsWith(".zip")) {
            var fileBlob = new FileBlob([att.content], null, att.id, fullName, file.parentId);
            await checkFileAndPopupateAttachments(fileBlob, false, true);
          }
          else {
            fileNames.push({ name: att.name, isAttachment: true });
            file.attachments.push({ ...att, isAttached: true });
          }
        }
      }

      if (!isZip(file.name) || tile.keepZipFile) {
        fileNames.push({ name: file.name, isAttachment: false });
      }

      if (file.name.endsWith(".zip") && file.size > 0) {
        file.id = file.name;
        let buffer = await readFileAsync(file);
        let filesInZip = await readZipAsync(buffer, file.name);

        for (const fz of filesInZip) {
          await checkFileAndPopupateAttachments(fz, true, false);
        }
      }
    }

    let files = event.dataTransfer.files;
    for (let index in files) {
      if (index && files.hasOwnProperty(index)) {
        let file = files[index];
        await checkFileAndPopupateAttachments(file);
      }
    }

    setFormState(previousState => ({
      ...previousState,
      droppedTile,
      fileNames: fileNames,
      files: filesList.map(f => ({
        name: f.name,
        attachments: f.attachments,
        id: f.id,
        parentId: f.parentId,
        upload: { status: FileUploadStatus.inProgress },
        isFromZip: f.isFromZip,
        isAttached: f.isAttached
      })),
      originalFilesList: filesList
    }));

    let declined = formState.tiles.find((t) => { return t.declined; });

    if (!preventSubmission(droppedTile, formState, declined)) {
      submit({ filesList });
    }
  }

  const renderTiles = () => {
    const maxColumnsCount = 4;

    let tiles = formState.tiles;
    let tilesCount = tiles?.length;
    let columnsCount = tilesCount < maxColumnsCount ? tilesCount : maxColumnsCount;

    let totalSumGridXs = tiles.reduce(function (prev, current) {
      return prev + +current.gridXs
    }, 0);

    let rowsCount = columnsCount < maxColumnsCount ? 1 : Math.ceil(totalSumGridXs / tilesCount);

    let rows = [];
    for (var i = 0; i < rowsCount; i++) {
      rows.push(i);
    }

    let startPosition = 0;

    return rows.map((r, i) => {

      let endPosition = startPosition + columnsCount;
      let tilesInRow = tiles?.slice(startPosition, endPosition);
      let sumGridXs = tilesInRow.reduce(function (prev, current) {
        return prev + +current.gridXs
      }, 0);

      while (sumGridXs > 12) {
        tilesInRow.splice(-1);
        sumGridXs = tilesInRow.reduce(function (prev, current) {
          return prev + +current.gridXs
        }, 0);
      }

      startPosition += tilesInRow.length;

      return <Grid key={i} container className={classes.root} item xs={12} direction="row" spacing={3}>
        {
          tilesInRow?.map(t => {
            return <Grid key={t.content} item xs={t.gridXs}>
              <DragAndDropArea
                text={t.content}
                classes={t.used ? "tile used-tile" : "tile"}
                showModdelingLabel={t?.used}
                isRelated={t.related}
                onFileDrop={event => onTileDrop(event, t.content)}
                modellingTask={t?.modellingTask}
                setFormState={setFormState}
                majorClass={formState.data.majorClass}
                declined={t.declined}
                isLocked={formState.tiles[0].isLocked}
                businessId={formState.data.businessId}
              />
            </Grid>
          })
        }
      </Grid>
    });
  }

  const renderDropzones = () => {
    return (
      <Grid container direction="column" className="tiles-container" spacing={3}>
        {renderTiles()}
      </Grid>
    );
  }

  const renderValidationMessages = () => {
    return <>
      <h3 className="validation-errors-header">
        Query parameters validation is failed. Please take a look at the errors below
      </h3>
      <List component="div" disablePadding>
        {validationState.errors?.map(error => {
          return (
            <ListItem key={error.parameter}>
              <ListItemIcon>
                <Error color="error" />
              </ListItemIcon>
              <ListItemText primary={error.message} />
            </ListItem>
          );
        })}
      </List>
    </>;
  }

  const filterCurrentFromCandidates = (candidates) => {
    return candidates.filter(x => !x.isCurrent);
  }

  const renderPopup = () => {
    let popupProps = {
      popupState: formState.popup,
      mTGRelatedPopup: formState.mTGRelatedPopup,
      formState: formState,
      setFormState,
      submit,
      submitArguments: { filesList: formState.originalFilesList },
      setLoading,
      setBannerState,
      filterCurrentFromCandidates,
      disableChanges: false
    };

    return <PopupsFactory
      tile={formState.droppedTile}
      formData={formState.data}
      popupProps={popupProps}
      isModellingTask={formState.isModellingTask}
      isMTGRelated={formState.isMTGRelated}
      setFormState={setFormState} />;
  };

  const renderForm = () => {
    if (errorMessage.length > 0) {
      return <ErrorPage message={errorMessage} />;
    }

    return (
      <div className="rpa-dropzones-container">
        <Banner
          type={bannerState.type}
          message={bannerState.message}
          show={bannerState.show}
          expose={bannerState.expose}
          showBanner={show => setBannerState({
            ...bannerState,
            show: show
          })}
        />
        {validationState.valid ? renderDropzones() : renderValidationMessages()}
        <Footer />
      </div>
    );
  }

  return (
    <>
      {renderForm()}
      {renderPopup()}
    </>
  );
};