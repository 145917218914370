import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FormControl, Grid, TextField } from '@material-ui/core';
import { DataGrid  } from '@mui/x-data-grid';

import { BannerType, ComponentController, Dropdown, FormButton } from 'components/common';
import { GeneralDetailsFields } from 'components/SubmissionWizard/Helpers';

export const DefaultConditionName = "conditionCode"

export const ConditionCode = ({
  name = DefaultConditionName,
  dropdownsData,
  fields,
  control,
  trigger,
  errors,
  setFormState,
  setBannerState,
  validationRef,
  isRequired,
  inputRef
}
) => {
  const columnsConfig = [
    {
      field: 'description',
      headerName: 'Condition Code description',
      minWidth: 250,
      datasource: name,
      type: "singleSelect",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      renderEditCell: {},
      forceRerender: false,
      mandatory: true
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      type: "singleSelect",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'comment',
      headerName: 'Comment',
      minWidth: 200,
      type: "string",
      editable: true,
      commentContainer: true
    }];
  const recommendedConditionCodesLength = 6;
  const descriptionField = "description";
  const [selectedCell, setSelectedCell] = useState();
  const [columns, setColumns] = useState(columnsConfig);
  const fieldsRef = React.useRef();
  const baseValidationRef = validationRef.current;

  fieldsRef.current = fields;

  useEffect(() => {
    let columns = columnsConfig.map(c => {
      if (c.dropdownContainer) {
        c.renderEditCell = dropdownContainer;
      }
      if (c.commentContainer) {
        c.renderEditCell = commentContainer
      }
      if (c.mandatory) {
        c.headerName = `${c.headerName}*`;
      }
      return c;
    });

    setColumns(columns);
  }, []);

  let validateGrid = () => {
    let conditionCodeValidationStatus = fieldsRef.current.conditionCodeProperties.map(x => {
      var hasEmpty = columnsConfig
        .filter(c => c.mandatory)
        .some(c => x[c.field] === '');
      return !hasEmpty;
    });
    return conditionCodeValidationStatus.every(x => x);
  }

  useEffect(() => {
    validationRef.current = async () => {
      const isBaseFormValid = await baseValidationRef();
      if (!isBaseFormValid) return false;
      
      let isGridVisible = fieldsRef.current.conditionCodeProperties.length > 0;

      if (!isRequired && !isGridVisible) {
        return true;
      }

      let isValid = validateGrid();

      if (!isValid) {
        let message = isGridVisible ? "Please fill the condition code properties" : "Condition code is required field";

        setBannerState({
          show: true,
          type: BannerType.warning,
          message: message
        });
      }

      return isValid;
    };
  }, [setBannerState, trigger, validationRef, fieldsRef.current, fieldsRef.current.conditionCodeProperties])

  const getDropdownContainerData = useCallback(params => {
    let source = params.colDef.field;
    let id = params.id;
    let data = {};
    let value = {}
    let properties = dropdownsData.conditionCodes.find(z => z.properties !== null)?.properties;
    let currentValue = fieldsRef.current.conditionCodeProperties.find(x => x.id === id);
    if (source === 'description') {
      data = properties.map(y => y.name);
      value = currentValue.description ?? "";
    }
    else {
      let code = fieldsRef.current.conditionCodeProperties.find(x => x.id === id)?.description;
      data = properties.find(x => x.name === code)?.status ?? [];
      value = currentValue.status ?? "";
    }

    return {
      source: source,
      data: data,
      id: id,
      value: value
    }
  }, [dropdownsData.conditionCodes]);

  const dropdownContainer = params => {
    let data = getDropdownContainerData(params);
    const { id, field, api } = params;

    return data && (
      <ComponentController
        name={data.source}
        control={control}
        required
        render={({ field: { name, onBlur, onChange } }) =>
          <FormControl fullWidth error={!!errors[name]}>
            <Dropdown
              id={data.id}
              name={name}
              label={data.value}
              value={data.value}
              data={data.data}
              onChange={e => { handleDropDownChange(e.target.name, e.target.value, data.id); onChange(e); api.setCellMode(id, field, 'view');}}
              onBlur={onBlur}
              hideLabel={true}
            />
          </FormControl>
        }
      />
    );
  }

  const getCommentContainerData = params => {
    let id = params.id;
    let value = {}
    let currentValue = fieldsRef.current.conditionCodeProperties.find(x => x.id === id);
    value = currentValue.comment ?? "";

    return {
      id: id,
      value: value
    }
  }

  const commentContainer = params => {
    let data = getCommentContainerData(params);

    return data && (
      <Grid item>
        <TextField
          id={data.id}
          fullWidth
          onChange={(e) => { handleCommentChange(e.target.value, data.id) }}
          value={data.value}
        />
      </Grid>);
  }

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const onConditionCodeChange = useCallback(conditionCode => {
    let properties = dropdownsData.conditionCodes.find(z => z.code === conditionCode)?.properties;
    let initialConditionCodeProperties = [];

    if (properties && fields.conditionCodeProperties.length === 0) {
      initialConditionCodeProperties.push(
        {
          id: uuidv4(),
          description: '',
          status: '',
          comment: ''
        });
    }

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        conditionCode: conditionCode ?? '',
        conditionCodeProperties: initialConditionCodeProperties
      }
    }));
  }, [setFormState]);

  const conditionCodePropertiesCheck = (conditionCode) => {
    let properties = dropdownsData.conditionCodes.find(z => z.code === conditionCode)?.properties;
    if (properties) {
      return true;
    }
    return false;
  }

  const onCellClick = useCallback(cell => {
    setSelectedCell(cell);
  }, [setSelectedCell]);

  const removeConditionCode = useCallback(() => {
    let currentConditionCodes = fieldsRef.current.conditionCodeProperties;
    if (currentConditionCodes.length > 1) {
      let conditionCodeProperties = currentConditionCodes.filter(x => x.id != selectedCell.id);
      setFormState(previous => ({
        ...previous,
        fields: { ...previous.fields, conditionCodeProperties }
      }));
    }
  }, [fieldsRef.current.conditionCodeProperties.length, setFormState, selectedCell]);

  const addConditionCode = () => {
    if (fieldsRef.current.conditionCodeProperties.length >= recommendedConditionCodesLength) {
      setBannerState({
        show: true,
        type: BannerType.warning,
        message: `6 Condition Code rows have been created. Add row if a 7th distinct row is required`
      });
    }
    let initialConditionCodeProperties = [];
    fieldsRef.current.conditionCodeProperties.map(x => initialConditionCodeProperties.push(x));

    initialConditionCodeProperties.push(
      {
        id: uuidv4(),
        description: '',
        status: '',
        comment: ''
      });

    setFormState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          conditionCodeProperties: initialConditionCodeProperties
        }
      };
    });
  }

  const handleDropDownChange = useCallback((editedRowsName, editedRowsValue, id) => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        conditionCodeProperties: fieldsRef.current.conditionCodeProperties.map(p => {
          if (p.id === id) {
            if (editedRowsName === descriptionField) {
              p.status = '';
            }

            p[editedRowsName] = editedRowsValue;
          }
          return p;
        })
      }
    }));

    setColumns(previous => {
      return previous.map(p => {
        if ('forceRerender' in p) {
          p.forceRerender = !p.forceRerender;
        }
        return p;
      });
    });
  }, [setFormState, setColumns]);

  const handleCommentChange = useCallback((editedRowsValue, id) => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        conditionCodeProperties: fieldsRef.current.conditionCodeProperties.map(p => {
          if (p.id === id) {
            p.comment = editedRowsValue;
          }

          return p;
        })
      }
    }));

    setColumns(previous => {
      return previous.map(p => {
        if ('forceRerender' in p) {
          p.forceRerender = !p.forceRerender;
        }
        return p;
      });
    });
  }, [setFormState, setColumns]);

  const renderConditionCode = () => {
    return (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          required={isRequired}
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                emptyValue={true}
                label={GeneralDetailsFields[name]}
                value={fieldsRef.current[name]}
                data={dropdownsData.conditionCodes.map(x => x.code)}
                onChange={e => { onConditionCodeChange(e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                inputRef={inputRef}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderConditionCodeProperties = () => {
    let render = conditionCodePropertiesCheck(fieldsRef.current.conditionCode);

    return render && (
      <div>
        <DataGrid
          rowHeight={55}
          rows={fieldsRef.current.conditionCodeProperties}
          columns={columns}
          density="compact"
          hideFooter={true}
          autoHeight={true}
          onCellClick={e => {onCellClick(e)}}
          onEditRowsModelChange={handleDropDownChange}
        />        
        <FormButton onClick={addConditionCode}>Add</FormButton>       
        <FormButton onClick={removeConditionCode}>Remove</FormButton>
      </div>
    );
  }

  return <>
    {renderConditionCode()}
    {renderConditionCodeProperties()}    
  </>;
}
