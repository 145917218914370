import { StatusCodes } from "http-status-codes";
import { authorizedApiClient } from "Api";

const api = {
  rpaDropzones: {
    getModel: "api/rpaDropzones",
    getDeclinedReasons: "api/rpaDropzones/declinedReasons/list",
    getNonRenewalReasons: "api/rpaDropzones/nonRenewalReasons/list",
    getMTGList: "api/rpaDropzones/mtg/list",
    getCurrentMTG: "api/rpaDropzones/mtg/relation",
    updateMTG: "api/rpaDropzones/mtg/relation",
    getMtgFolderPath: "api/rpaDropzones/GetMtgFolderPath",
    getEndorsmentsFolders: "api/rpaDropzones/endorsments/folders",
    checkIfRpaFlowIsRunning: "api/rpaDropzones/rpaflow/check",
  },
  submission: {
    submit: "submission/submit",
    uploadFile: "submission/uploadFile"
  }
};

export const fetchDropzones = async request => {
  try {
    let response = await authorizedApiClient.post(api.rpaDropzones.getModel, request);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.response.status === StatusCodes.FORBIDDEN) {
      const message = "You do not have permission to access this application, please click on adjacent link to request access. Once page appears, follow instruction to request access. Access will be provided within a few hours.";
      return {
        success: false,
        errorMessage: message,
        permissionError: true
      };
    } else if (error.response.status === StatusCodes.BAD_REQUEST) {
      let errors;

      let data = error.response.data;
      if (Array.isArray(data)) {
        errors = data.map(e => ({
          parameter: e.propertyName,
          message: e.errorMessage
        }));
      }

      return {
        validationError: true,
        validationResult: {
          valid: false,
          errors: errors
        }
      };
    }

    return {
      success: false,
      errorMessage: "Error occurred during the page loading. Please, try again."
    };
  }
};

export const uploadFile = async (fileName, content) => {
  try {
    var response = await authorizedApiClient.post(
      api.submission.uploadFile,
      content,
      {
        params: { fileName }
      });

    return { success: true, uploadPath: response.data.uploadPath };
  } catch (error) {
    return { success: false, error };
  }
}

export const updateMTG = async (original, updated, formData) => {
  try {
    var response = await authorizedApiClient.post(
      api.rpaDropzones.updateMTG,      
      {
        original: original,
        updated: updated,
        formData: formData
      });

    return { success: true, uploadPath: response.data.uploadPath };
  } catch (error) {
    return { success: false, error };
  }
}

export const sendData = async (formData, files) => {
  try {
    await authorizedApiClient.post(
      api.submission.submit,
      {
        ...formData,
        files: files
      });

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const fetchCurrentMtg = async (id) => {
  try {
    let response = await authorizedApiClient.get(api.rpaDropzones.getCurrentMTG,
      {
        params: {
          id
        }
      });
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the declined reasons data loading. Please, try again later."
    };
  }
}

export const fetchCandidatesMtg = async (id, yoa, majorClass, entity) => {
  try {
    let response = await authorizedApiClient.get(api.rpaDropzones.getMTGList,
      {
        params: {
          id,
          yoa,
          majorClass,
          entity
        }
      });
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the declined reasons data loading. Please, try again later."
    };
  }
}

export const fetchDeclinedReasons = async () => {
  try {
    let response = await authorizedApiClient.get(api.rpaDropzones.getDeclinedReasons);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the declined reasons data loading. Please, try again later."
    };
  }
}

export const fetchNonRevewalReasons = async () => {
  try {
    let response = await authorizedApiClient.get(api.rpaDropzones.getNonRenewalReasons);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the declined reasons data loading. Please, try again later."
    };
  }
}

export const getMtgFolderPath = async request => {
  try {
    var response = await authorizedApiClient.post(api.rpaDropzones.getMtgFolderPath, request);

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
}

export const fetchEndorsmentsFolders = async request => {
  try {
    let response = await authorizedApiClient.post(api.rpaDropzones.getEndorsmentsFolders, request);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the Endorsments folders loading. Please, try again later."
    };
  }
}

export const checkIfRpaFlowIsRunning = async request => {
  try {
    let response = await authorizedApiClient.post(api.rpaDropzones.checkIfRpaFlowIsRunning, request);

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
}