import { authorizedApiClient } from "Api";

const api = {
  brokers: {
    buildMessageModel: "api/brokersNotifications/build-message",
    postModel: "api/brokersNotifications/preview",
    submit: "api/brokersNotifications/send"
  },
  documents: {
    getType: "api/documents/type/{productId}",
    create: "api/documents",
    get: "api/documents/{documentId}"
  },
  policy: {
    get: "api/policy/{policyNo}"
  }
};

export const fetchPolicy = async policyNo => {
  try {
    let response = await authorizedApiClient.get(api.policy.get.replace("{policyNo}", policyNo));
    return {
      success: true,
      data: response.data
    }
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occured during fetching policy details: " + error
    }
  }
};

export const generateDocument = async request => {
  try {
    let documentTypeResponse = await authorizedApiClient.get(api.documents.getType.replace("{productId}", request.productId));
    let documentTypeId = documentTypeResponse.data;

    let documentGenerationRequest = {
      ...request,
      documentTypeId: documentTypeId
    };
    let documentIdResponse = await authorizedApiClient.post(api.documents.create, documentGenerationRequest);
    let documentId = documentIdResponse.data;

    let documentResponse = await authorizedApiClient.get(api.documents.get.replace("{documentId}", documentId), { responseType: 'blob' });

    return {
      success: true,
      data: {
        file: documentResponse.data
      }
    }
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occured during document generation: " + error
    }
  }
};

export const fetchEmailPreview = async request => {
  try {
    let response = await authorizedApiClient.post(api.brokers.postModel, request);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during getting email preview: " + error
    }
  }
};

export const fetchEmailTemplate = async request => {
  try {
    let response = await authorizedApiClient.post(api.brokers.buildMessageModel, request);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during getting email template: " + error
    };
  }
};

export const sendData = async formData => {
  try {
    await authorizedApiClient.post(api.brokers.submit, formData);
    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during sending email: " + error
    };
  }
};