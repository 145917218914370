import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'hamilton-web-components';

export const ToastType = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

const useToast = (initialState = {}, showNotifications = true) => {
  let [toastState, setToastState] = useState(initialState);

  if (!showNotifications) {
    toastState = {};
    setToastState = () => {};
  }

  const resetToastState = () => {
    setToastState({
      show: false,
      type: null,
      message: null,
    });
  };

  useEffect(() => {
    if (toastState.show) {
      const toastOptions = {
        onClose: resetToastState, // Reset state when toast is closed
      };

      switch (toastState.type) {
        case ToastType.success:
          toast.success(toastState.message, toastOptions);
          break;
        case ToastType.error:
          toast.error(toastState.message, toastOptions);
          break;
        case ToastType.warning:
          toast.warning(toastState.message, toastOptions);
          break;
        case ToastType.info:
          toast.info(toastState.message, toastOptions);
          break;
        default:
          toast(toastState.message, toastOptions);
          break;
      }
    }
  }, [toastState]);

  return [toastState, setToastState, ToastContainer];
};

export default useToast;
