import React, { useCallback } from 'react';
import { useStarAiContext } from 'star-ai'
import { FormControl, Grid, TextField } from '@material-ui/core';

import { Datasource } from 'Api';
import { PickerComponent, Dropdown, ComponentController } from 'components/common';
import { AssuredAndPolicyReferenceControl } from './common';
import { convertToArray, GeneralDetailsFields } from 'components/SubmissionWizard/Helpers';

import '../GeneralDetails.css';

const ModellingSubmissionSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  setForceClearDatasourceState
}) => {

  // star ai
  const ai = useStarAiContext();

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const onMajorClassChange = useCallback(majorClass => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        _assuredOrReassured: '',
        majorClass: majorClass,
        assured: null,
        reassured: '',
        policyId: null,
        policyReferenceNumber: '',
        requiredModellingPriority: majorClass?.isModellingPriority
          ? previous.dropdownsData.modellingPriorities[0]
          : ''
      }
    }));

    setForceClearDatasourceState({ "assured": true });
    setValidationValue("assured", "");
  }, [setFormState, setForceClearDatasourceState, setValidationValue]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const renderMajorClass = () => {
    return (
      <Grid item>
        <ComponentController
          name="majorClass"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              inputRef={ai?.fieldsRef?.[name]}
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields.majorClass}
              datasource={Datasource[name]}
              requestData={{
                BusinessEntities: dropdownsData.entitiesForFilter
              }}
              abortController={null}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              onChange={value => { onMajorClassChange(value); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={4}><div>Major Class</div></Grid>
                    <Grid item xs={8}><div>Entities</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={4}><div>{option.majorClass}</div></Grid>
                    <Grid item xs={8}><div>{option.entities}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    );
  }

  const renderModellingPriority = () => {
    let name = "requiredModellingPriority";
    return fields.majorClass?.isModellingPriority &&
      (
        <Grid item>
          <FormControl fullWidth>
            <Dropdown
              id={name}
              label={GeneralDetailsFields.requiredModellingPriority}
              value={fields.requiredModellingPriority}
              data={dropdownsData.modellingPriorities}
              onChange={e => onFieldChange(name, e.target.value)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          </FormControl>
        </Grid>
      );
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      additionalRequestData={{
        businessEntities: convertToArray(fields.majorClass?.entities)
      }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      includePlacingBasis={true}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderAssured = () => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = () => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.policyReferenceNumber}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderTypeOfModellingRisk = () => {
    let name = "typeOfModellingRisk"
    return (
      <Grid item>
        <ComponentController
          name={name}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields.typeOfModellingRisk}
                value={fields.typeOfModellingRisk}
                emptyValue={true}
                data={dropdownsData.typesOfModellingRisk}
                onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  return <>
    {renderMajorClass()}
    {renderModellingPriority()}
    {renderLookupForAssuredAndPolicyReference()}
    {renderPolicyReferenceNumber()}
    {renderAssured()}
    {renderTypeOfModellingRisk()}
  </>;
}

export default ModellingSubmissionSection;