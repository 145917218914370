import React, { useCallback } from 'react';
import { ConfirmationDialog } from 'components/common';

export const SanctionWarning = ({
  sanctionsDialogConfig,
  setSanctionsDialogConfig,
  setValidationValue,
  setForceClearDatasourceState,
  newAssuredValue,
  sanctionWarningDialogState,
  setSanctionWarningDialogState,
}) => {

  const handleAcknowledge = useCallback((changeOrgDetails) => {
    if (changeOrgDetails) {
      changeOrgDetails();
    }
    setSanctionsDialogConfig(null);
  }, [setSanctionsDialogConfig]);

  const handleClose = useCallback((changeOrgDetails, typeOfOrg) => {
    setValidationValue(typeOfOrg, null);

    setForceClearDatasourceState({
      [typeOfOrg]: true
    });

    newAssuredValue.current = null;
    if (changeOrgDetails) {
      changeOrgDetails();
    }    
    setSanctionsDialogConfig(null);
  }, [setValidationValue, setForceClearDatasourceState, newAssuredValue, setSanctionsDialogConfig]);

  const renderAssuredSanctionWarningDialog = () => {
    let dialogContent = "Please note that there is sanctions risk exposure in relation to the selected (Re)Assured";
    return (
      <ConfirmationDialog
        isOpened={sanctionWarningDialogState}
        content={dialogContent}
        onConfirm={() => handleAcknowledge(sanctionsDialogConfig?.changeOrgDetails)}
        config={{
          cancelButtonLabel: "Cancel",
          confirmButtonLabel: "Acknowledge"
        }}
        onClose={() => handleClose(sanctionsDialogConfig?.changeOrgDetails, sanctionsDialogConfig?.typeOfOrg)}
        setIsOpened={setSanctionWarningDialogState}
      />
    );
  }

  return <>
    {renderAssuredSanctionWarningDialog()}
  </>;
}