import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { AppBar, Container, Toolbar, Typography, withStyles, Tooltip, Box } from '@material-ui/core';
import { FolderOpen, Backup } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import { UserInfo } from './UserInfo';
import './Navigation.css';
import { Banner, BannerType } from "../common";
import { getMtgFolderPath } from "../RpaDropzones/RpaDropzones.Api";
import { RpaMapper } from "../RpaDropzones/Helpers";

let enableOpenFolderLink = false;
let showFolderLinkIcon = false;
let mtgFolderPath = undefined;
let rpaStatusColor = "#A6A6A6";
let rpaStatusMessage = "RPA Not Started";

export const ActivateFolderLink = async request => {
  enableOpenFolderLink = request;
};

export const SetRpaStatus = async (status, statusDatetime, majorClass) => {
  var isReinsurance = majorClass && majorClass.includes("Reinsurance");
  switch (status) {
    case 1:
      rpaStatusColor = "rgb(128, 255, 255)";
      rpaStatusMessage = "RPA Queued";
      break;
    case 2:
      rpaStatusColor = "rgb(0, 255, 255)";
      rpaStatusMessage = "RPA Running";
      break;
    case 3:
      rpaStatusColor = "rgb(128, 255, 128)";
      rpaStatusMessage = "RPA Completed " + statusDatetime;
      break;
    case 4:
      rpaStatusColor = "rgb(204, 204, 204)";
      rpaStatusMessage = isReinsurance ? "RPA Error: See UA" : "RPA Unsupported";
      break;
    case 5:
      rpaStatusColor = "rgb(255, 255, 128)";
      rpaStatusMessage = isReinsurance ? "RPA Error: See UA" : "RPA Credentials Error";
      break;
    case 6:
      rpaStatusColor = "rgb(255, 255, 128)";
      rpaStatusMessage = isReinsurance ? "RPA Error: See UA" : "RPA No Companies/Packages";
      break;
    case 7:
      rpaStatusColor = "rgb(255, 192, 128)";
      rpaStatusMessage = isReinsurance ? "RPA Error: See UA" : "RPA No New Files";
      break;
    case 8:
      rpaStatusColor = "rgb(255, 192, 128)";
      rpaStatusMessage = isReinsurance ? "RPA Error: See UA" : "RPA No Files";
      break;
    case 9:
      rpaStatusColor = "rgb(255, 192, 128)";
      rpaStatusMessage = isReinsurance ? "RPA Error: See UA" : "RPA Tech Error";
      break;
    case 10:
      rpaStatusColor = "rgb(255, 192, 128)";
      rpaStatusMessage = isReinsurance ? "RPA Error: See UA" : "RPA Other Error";
      break;
    default:
      rpaStatusColor = "rgb(204, 204, 204)";
      rpaStatusMessage = "RPA Not Started";
      break;
  }
};

export const ShowFolderLink = async request => {
  showFolderLinkIcon = request;
}

const ToolbarElement = withStyles(() => ({
  root: {
    minHeight: "0px"
  }
}))(Toolbar);



export const NavMenu = () => {

  const [bannerState, setBannerState] = useState({});
  const location = useLocation();

  const getMtgFolderPathClick = async () => {
    let queryParameters = parse(location.search);
    let formData = RpaMapper.mapQueryParametersToForm(queryParameters);
    let response = await getMtgFolderPath(formData);
    if (response.success) {
      mtgFolderPath = response.data;
      handleCopy();
    }
    else {
      mtgFolderPath = null;
      setBannerState({
        type: BannerType.error,
        message: "MTG folder path could not be copied to clipboard",
        show: true
      });
    }
  }

  const handleCopy = () => {
    if (enableOpenFolderLink && (mtgFolderPath != undefined && mtgFolderPath != null)) {

      var copyTextarea = document.createElement("textarea");
      copyTextarea.style.position = "fixed";
      copyTextarea.style.opacity = "0";
      copyTextarea.textContent = mtgFolderPath;

      document.body.appendChild(copyTextarea);
      copyTextarea.select();

      try {
        var successful = document.execCommand('copy');

        if (successful) {
          setBannerState({
            type: BannerType.success,
            message: "MTG folder path was successfully copied to clipboard!",
            show: true
          });
        }
        else {
          setBannerState({
            type: BannerType.error,
            message: "MTG folder path could not be copied to clipboard",
            show: true
          });
        }
      } catch (err) {

        setBannerState({
          type: BannerType.error,
          message: "MTG folder path could not be copied to clipboard",
          show: true
        });
        console.error('unable to copy', err);
      }

      document.body.removeChild(copyTextarea);
    }
  };

  return (
    <>
      <AppBar position="fixed" color="white">
        <Container >
          <ToolbarElement disableGutters>
              <div className={showFolderLinkIcon ? 'folder-link-container' : 'folder-link-hidden'}>
                <FolderOpen className={enableOpenFolderLink ? 'folder-icon' : 'folder-icon-disabled'} onClick={getMtgFolderPathClick} ></FolderOpen>
                <Tooltip title={rpaStatusMessage} placement="top-start">
                  <Chip icon={<Backup variant="outlined" />} label={rpaStatusMessage} style={{ backgroundColor: rpaStatusColor }} size="small" />
                </Tooltip>          
              </div>
              <div className="application-title">
                <Typography variant="h5">TIME FLOW</Typography>
              </div>
              <UserInfo />
          </ToolbarElement>
        </Container>
        <Banner
          type={bannerState.type}
          message={bannerState.message}
          show={bannerState.show}
          expose={bannerState.expose}
          showBanner={show => setBannerState({
            ...bannerState,
            show: show
          })}
        ></Banner>
      </AppBar>
      <Box id="freeSpaceUnderTheBar" pt={8}></Box>
    </>
  );

}