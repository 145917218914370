import React, { useCallback } from 'react';

import { OrganisationTypes, DeclarationPlacingType } from 'components/SubmissionWizard/Helpers';
import { PolicyReferenceControl } from './PolicyReferenceControl';
import { getPolicy } from 'components/SubmissionWizard/SubmissionWizard.api';

export const SecondAssuredAndPolicyReferenceControl = ({
  fields,
  control,
  errors,
  lookupType,
  additionalRequestData,
  forceClearDatasourceState,
  setBannerState,
  setLoading,
  setFormState,
  setValidationValue,
  includePlacingBasis,
  inputRef
}) => {
  const getAssured = (_, assuredRow) => {
    let assuredData = {
      _secondAssuredOrReassured: assuredRow?.assured ?? '',
      secondPolicyReferenceNumber: assuredRow?.policyReferenceNumber ?? '',
      secondYoa: assuredRow?.yearOfAccount,
      secondPolicyId: assuredRow?.policyId,
      secondUmr: assuredRow?.umr,
      secondReassuredChangedAtFirmOrder: false,
      secondTypeOfReassured: '',
      secondDateOfBirth: null,
      secondAddress: '',
      secondCountryName: '',
      secondCountryCode: '',
      secondUpdatedTypeOfReassured: '',
      secondUpdatedAssured: null,
      secondUpdatedDateOfBirth: null,
      secondUpdatedAddress: '',
      secondUpdatedCountryName: '',
      secondUpdatedCountryCode: '',
      renewedPolicyHIDAC: assuredRow?.renewed
    };

    if (assuredRow?.assuredType.toLowerCase() === OrganisationTypes.Reassured) {
      assuredData.secondReassured = assuredRow?.assured;
      assuredData.secondAssured = null;
    } else {
      assuredData.secondReassured = "";
      assuredData.secondDeclarationReference = assuredRow?.declarationReference ?? '';
      assuredData.secondAssured = {
        assured: assuredRow?.assured ?? '',
        assuredId: assuredRow?.assuredId
      };
    }

    if (includePlacingBasis) {
      assuredData = {
        ...assuredData,
        secondAssured: assuredData.secondAssured,
        secondPlacingBasis: assuredRow?.placingBasis,
        secondPlacingBasisCode: assuredRow?.placingType
      };
    }

    return assuredData;
  };

  const setMasterAssured = useCallback(async assuredRow => {
    let masterPolicyData = {};

    if (assuredRow?.placingType === DeclarationPlacingType) {
      setLoading(true);
      let policy = await getPolicy(assuredRow.policyId);
      let masterPolicy = await getPolicy(policy.data.detail.parentPolicyId);
      setLoading(false);

      let assureds = masterPolicy.data.assuredOrganisations;
      let reassureds = masterPolicy.data.reassured;
      masterPolicyData.secondMasterAssured = !!assureds && assureds[0]?.orgName;
      masterPolicyData.secondMasterReassured = !!reassureds && reassureds[0]?.orgName;
      masterPolicyData.secondMasterPolicyReferenceNumber = masterPolicy.data.detail.policyRef;
    } else {
      masterPolicyData.secondMasterAssured = "";
      masterPolicyData.secondMasterReassured = "";
      masterPolicyData.secondMasterPolicyReferenceNumber = "";
    }

    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, ...masterPolicyData }
    }));
  }, [setFormState])

  const requestData = { ...additionalRequestData, majorClassCodes: fields.majorClass?.majorClassCodes };

  return (<PolicyReferenceControl
    controlName='secondAssured'
    getValue={fieldData => fieldData._secondAssuredOrReassured}
    fields={fields}
    control={control}
    errors={errors}
    lookupType={lookupType}
    requestData={requestData}
    forceClearDatasourceState={forceClearDatasourceState}
    setBannerState={setBannerState}
    setLoading={setLoading}
    setFormState={setFormState}
    setValidationValue={setValidationValue}
    getAssuredData={getAssured}
    setSecondMasterAssuredData={setMasterAssured}
    disabled={!fields.majorClass}
    inputRef={inputRef}
  />);
}