import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

import { DialogButton, BannerType, ComponentController } from 'components/common';
import { GeneralDetailsFields } from '../Helpers';
import { maxAddressLength, assuredMaxLength, maxPostCodeLength } from '../Helpers/Constants';

export const IndividualAssuredPopup = ({
  popupState,
  onPopupSubmit,
  setPopupState,
  setFormState,
  setValidationValue,
  requestData
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: { 
      givenName: popupState.newValue, 
      addressDetails: requestData.address,
      postCodeDetails: requestData.postCode
    }
  }, [requestData]);

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onAssuredSubmit = useCallback(response => {
    let givenName = response.data?.givenName ?? '';
    let familyName = response.data?.familyName ?? '';
    let address = response.data?.address;
    let postCode = response.data?.postCode;
    let assured = {
      assured: response.data?.assured,
    };

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        assured,
        secondAssured: previous.fields.isDualStamp ? assured : null,
        givenName,
        familyName,
        address,
        postCode,
        hamiltonId: null
      }
    }));

    setValidationValue("assured", assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onSubmit = useCallback(async popupData => {
    handleClose();

    const { givenName, familyName, addressDetails, postCodeDetails } = popupData
    let assured = `${popupData?.givenName} ${popupData?.familyName}`;
    
    let submitData = { data: { assured, givenName, familyName, address: addressDetails, postCode: postCodeDetails } };
    if (onPopupSubmit) {
      onPopupSubmit(submitData);
    } else {
      onAssuredSubmit(submitData);
    }

  }, [handleClose, onAssuredSubmit, onPopupSubmit]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {assuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="assured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="assured-dialog-title">Add a new Assured to Sequel Eclipse</DialogTitle>
        <DialogContent>
          <ComponentController
            name="givenName"
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="familyName"
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="addressDetails"
            control={control}
            rules={{ maxLength: maxAddressLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="postCodeDetails"
            control={control}
            rules={{ maxLength: maxPostCodeLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
