import { Controller } from 'react-hook-form';
import React, { useCallback } from 'react';

export const ComponentController = props => {
  const getProps = useCallback(() => {
    const required = props.required;
    
    const rules = { ...(props.rules ?? {}), required };
    const renderFn = renderProps => React.cloneElement(props.render(renderProps), { required });
    
    return {...props, rules: rules, render: renderFn};
  }, [props]);
  
  return <Controller {...getProps()}/>;
}