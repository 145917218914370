import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import { NavMenu, ApplicationUpdater } from '../components';

export default class DefaultLayout extends Component {
  static displayName = DefaultLayout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container>
          <ApplicationUpdater />
        </Container>
        <Container>{this.props.children}</Container>
      </div>
    );
  }
}
