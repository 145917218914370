import { StatusCodes } from "http-status-codes";
import { authorizedApiClient } from "Api";

const api = {
  submission: {
    submit: "submission/submit",
    entities: "submission/formData"
  },
  uploadFile: "submission/uploadFile",
  classes: {
    minorClassesList: "api/classes/minor/list",
    classesList: "api/classes/list"
  },
  assured: {
    search: "api/assured/search",
    add: "api/assured/add",
    addressStates: "api/assured/addressStates/list"
  },
  policyReference: {
    list: "api/policyReference/list",
    validation: "api/policyReference/validate"
  },
  brokers: {
    list: `api/brokers/{productId}`,
    branches: `api/brokers/{id}/{productId}/branches`,
    contacts: `api/brokers/{id}/{productId}/branches/{branchId}/contacts`
  }
}

export const fetchEntitiesData = async () => {
  try {
    let response = await authorizedApiClient.get(api.submission.entities);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.response.status === StatusCodes.FORBIDDEN) {
      const message = "You do not have permission to access this application, please contact your manager to get access.";
      return {
        success: false,
        errorMessage: error.response.data ?? message,
        permissionError: true
      };
    }

    return {
      success: false,
      errorMessage: "Error occurred during the page loading. Please, try again."
    };
  }
}

export const fetchAssuredList = async request => {
  try {
    let response = await authorizedApiClient.post(api.assured.search, request);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the assured data loading. Please, try again later."
    };
  }
}

export const submitForm = async (form, files) => {
  try {
    await authorizedApiClient.post(
      api.submission.submit,
      {
        ...form,
        files: files
      });

    return { success: true };
  } catch (e) {
    return {
      success: false,
      errorMessage: "Submission is failed. Errors occurred: " + e?.response?.data?.errors?.join("; ")
    };
  }
}

export const uploadFile = async (fileName, content) => {
  try {
    var response = await authorizedApiClient.post(
      api.uploadFile,
      content,
      {
        params: { fileName }
      });

    return { success: true, uploadPath: response.data.uploadPath };
  } catch (error) {
    return { success: false, error };
  }
}