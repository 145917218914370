import React from 'react';

import { Error } from '@material-ui/icons';

import './Navigation.css';

export const ErrorPage = (props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="error-icon">
        <Error color="error" />
      </div>
      <div className='error-message'>{props.message}</div>
      <p><a className='error-message' href=' https://openhamilton.com/hamiltons-web-apps/my-access/'>Request Access</a></p>
    </div>
  );
};
