import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import DateFnsUtils from "@date-io/date-fns";

import './DragAndDropArea.css';
import { useCallback } from 'react';

import { fetchMtg } from "components/RpaDropzones/RpaDropzones.Api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    size: 'small',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export const DragAndDropArea = (
  {
    text,
    onFileDrop,
    classes,
    onDragClasses,
    showModdelingLabel,
    isRelated,
    modellingTask,
    setFormState,
    majorClass,
    declined,
    fileToUpload    
  }) => {
  const [onHoverDragArea, setOnHoverDragArea] = useState(false);
  const submissionData = 'Submission Data';

  const chipClasses = useStyles();

  const getFormattedDate = date => {
    if (!date) {
      return null;
    }

    let utils = new DateFnsUtils();
    return utils.format(date, "MM-dd-yy");
  }

  const onDrop = async event => {
    event.preventDefault();
    event.stopPropagation();

    setOnHoverDragArea(false);
    onFileDrop(event);
  };

  const onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
    setOnHoverDragArea(true);
  };

  const onDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    setOnHoverDragArea(false);
  };

  const renderDropDown = () => {
    if (text === submissionData) {
      return <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <p>{text}</p>
          </Grid>
          <Grid item xs={6}>
            <div className={chipClasses.root}>
              {renderModellingTask()}
              {renderRelatedMTG()}
            </div>
          </Grid>
        </Grid>
      </>
    }
    else {
      return <p>{text}</p>
    }
  }

  const handleModellingTaskClick = useCallback(() => {
    setFormState(previousState => ({
      ...previousState,
      isModellingTask: true
    }));
  }, [setFormState]);

  const getMTGRelatedData = useCallback(() => {
    setFormState(previousState => ({
      ...previousState,
      mTGRelatedPopup: {
        ...previousState.mTGRelatedPopup,
        show: true
      }
    }));
  }, [setFormState]);


  const renderModellingTask = () => {
    if (showModdelingLabel) {
      if (modellingTask) {
        return <Chip label={`${modellingTask.priority} / ${getFormattedDate(new Date(modellingTask.date))}`} style={{ backgroundColor: '#00BFFF' }} />
      }
      else {
        let preventModelling = (majorClass == "Casualty Reinsurance" || declined )
        return <Chip label="Modelling not required " onClick={preventModelling ? '' : handleModellingTaskClick} style={{ backgroundColor: '#FA8072' }} />
      }
    }
  }

  const renderRelatedMTG = () => {
    if (isRelated) {
      return <Chip label='MTG Related' onClick={getMTGRelatedData} style={{ backgroundColor: '#7FFFD4' }} />
    }
    else {
      return <Chip label="MTG NOT Related +" onClick={getMTGRelatedData} style={{ backgroundColor: '#D3D3D3' }} />
    }
  }

  const applyStyles = () => {
    const dragDropAreaDefaultClass = "drag-drop-zone";
    const onDragDefaultClass = "drag-drop-zone-on-drag";

    let resultClasses = classes ? classes : dragDropAreaDefaultClass;

    if (onHoverDragArea) {
      return `${resultClasses} ${onDragClasses ? onDragClasses : onDragDefaultClass}`;
    }

    return resultClasses;
  }

  // The file prop is used to programmatically upload a file to the server.
  // The useEffect hook below triggers whenever the file prop changes.
  // When a new file is provided via the file prop, this useEffect hook will be invoked.
  React.useEffect(() => {
    if (!fileToUpload) return;

    const event = {
      dataTransfer: {
        files: [fileToUpload],
        types: ['Files'],
      },
      preventDefault: () => {},
      stopPropagation: () => {},
    };
    
    onDrop(event);
  }, [fileToUpload]);

  return (
    <div
      className={applyStyles()}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}>
      {renderDropDown()}
    </div>
  );
};