//  This popup is temporary solution according to the US 227054 TimeFlow - new Assured lookup on Line of Business tab
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

import { DialogButton, ComponentController } from 'components/common';
import { GeneralDetailsFields } from '../Helpers';
const assuredMaxLength = 130;

export const AssuredEclipsePopup = ({
  popupState,
  setPopupState,
  setFormState,
  setValidationValue,
  isRenewal
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: { assured: popupState.newValue }
  });

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onSubmit = useCallback(async popupData => {
    handleClose();

    let assured = {
      assured: popupData.assured
    };

    let assuredType = isRenewal ? 'renewalAssured' : 'assured';
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [assuredType]: assured,
        secondAssured: previous.fields.isDualStamp ? assured : null
      }
    }));

    setValidationValue("assured", assured, { shouldValidate: true });
  }, [handleClose, setFormState, setValidationValue]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {assuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="assured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="assured-dialog-title">Add New Assured (SharePoint folder naming)</DialogTitle>
        <DialogContent>
          <ComponentController
            name="assured"
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields["assuredOrMasterAssured"]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};