import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { configureInterceptors } from "./Api";

const rootElement = document.getElementById("root");

configureInterceptors();

ReactDOM.render(<App />, rootElement);
