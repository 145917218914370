import React, { useState, useCallback, useEffect } from 'react';
import { useGreyHint } from 'hamilton-web-components'
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import debounce from 'lodash/debounce';

import { BannerType } from 'components/common';
import { validateRequiredField } from 'components/SubmissionForm/Validation';
import { fetchBrokerContacts } from 'components/SubmissionForm/Form.api';

const BrokerContactAutocomplete = (props) => {
  const {
    fieldsState,
    validationState,
    setFieldsState,
    setValidationState,
    setBannerState,
    inputRef,
    hintText
  } = { ...props };

  const loadingTime = 1000;

  const [areContactsLoading, setAreContactsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(' ')
  const [contacts, setContacts] = useState([]);

  const { greyHintForStartAdornment } = useGreyHint({
    inputRef,
    inputValue,
    setInputValue,
    hintText,
    sx: {ml: '0.25rem', 'span': {fontFamily: "inherit", fontSize: "1rem"}}
  });

  const loadContactsAsync = useCallback(async (brokerId, productId, companyId, branchId, filter) => {
    let response = await fetchBrokerContacts(brokerId, productId, companyId, branchId, filter);

    if (response.success) {
      setContacts(response.data);
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }
  }, [setContacts, setBannerState]);

  useEffect(() => {
    let brokerId = fieldsState.broker?.id;
    let brokerBranchId = fieldsState.brokerBranch?.id;
    let productId = fieldsState.product?.productId;
    let companyId = fieldsState.companyId;

    if (brokerId && brokerBranchId) {
      loadContactsAsync(brokerId, productId, companyId, brokerBranchId);
    } else {
      setContacts([]);
    }
  }, [fieldsState.broker, fieldsState.brokerBranch, fieldsState.product, loadContactsAsync]);

  const getContactsQuery = useCallback(debounce(async (brokerId, productId, companyId, branchId, filter) => {
    await loadContactsAsync(brokerId, productId, companyId, branchId, filter);
    setAreContactsLoading(false);
  }, loadingTime), [setAreContactsLoading, loadContactsAsync]);

  const validateContact = contactName => {
    let result = validateRequiredField("Broker Contact", contactName);
    setValidationState(previousState => ({
      ...previousState,
      fields: {
        ...previousState.fields,
        brokerContact: result
      }
    }));
  };

  const handleChange = async (_, contact) => {
    const contactName = contact?.name;

    if (props.onChange != null) {
      props.onChange({ target: { value: contactName } }, contactName);
    }

    validateContact(contactName);

    setFieldsState(previousState => ({
      ...previousState,
      brokerContact: {
        id: contact?.id,
        name: contactName,
        code: contact?.code,
        relationshipId: contact?.relationshipId
      }
    }));
  };

  const handleTextChange = async event => {
    let contactName = event.target?.value;
    setAreContactsLoading(true);
    await getContactsQuery(
      fieldsState.broker?.id, fieldsState.product?.productId, fieldsState.companyId, fieldsState.brokerBranch?.id, contactName);
  };

  const handleBlur = () => {
    validateContact(fieldsState.brokerContact?.name);
  };

  const filterOptions = (options, params) => {
    let filter = createFilterOptions({
      ignoreCase: true,
      matchFrom: 'any',
      stringify: option => option.name,
      limit: 15
    });

    const filtered = filter(options, params);
    let rows = filtered.map(contact => ({
      name: contact?.name,
      id: contact?.id,
      code: contact?.code,
      relationshipId: contact?.relationshipId
    }));

    return rows;
  };

  const getOptionLabel = option => {
    return option?.name ?? '';
  };

  const renderInputElement = params => (
    <TextField
      {...params}
      inputRef={inputRef}
      label="Broker Contact"
      required
      onChange={handleTextChange}
      error={!validationState.fields.brokerContact.valid}
      helperText={validationState.fields.brokerContact.message}
      InputProps={{
        ...params.InputProps,
        ...greyHintForStartAdornment,
        endAdornment: (
          <>
            {areContactsLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  );

  return <Autocomplete
    id="brokerContact"
    disabled={!fieldsState.broker?.id || !fieldsState.brokerBranch?.id}
    value={fieldsState.brokerContact}
    onChange={handleChange}
    onBlur={handleBlur}
    filterOptions={filterOptions}
    loading={areContactsLoading}
    clearOnBlur
    clearOnEscape
    handleHomeEndKeys
    freeSolo
    options={contacts}
    getOptionLabel={getOptionLabel}
    renderOption={option => option.name}
    renderInput={renderInputElement}
    inputValue={inputValue}
    onInputChange={(e, newValue) => setInputValue(newValue)}
  />;
};

export default BrokerContactAutocomplete;