import React from 'react';

import { withStyles, Button } from '@material-ui/core';

const FormattedButton = withStyles(() => ({
  root: {
    backgroundColor: "#1FB6AD",
    color: "#ffffff",
    margin: "0rem 0.5rem 0rem 0.5rem"
  }
}))(Button);

export const FormButton = (props) => {
  return (
    <FormattedButton
      {...props}
      variant="contained"
      endIcon={props.endIcon}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </FormattedButton>
  );
};