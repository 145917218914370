import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { authorizedApiClient } from 'Api'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const HealthCheck = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const callHealth = async () => {
    const result = await authorizedApiClient.get(`api/CheckService/`);
    setData(result.data.data);
  }

  useEffect(() => { callHealth(); });

  return (
    <div>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Service Name</TableCell>
            <TableCell align="right">Status</TableCell>           
          </TableRow>
        </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="right">
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      </TableContainer>
      <NavLink to='/'>back</NavLink>
    </div>
  );
}