import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const SwitchComponent = withStyles({
  switchBase: {
    '&$checked': {
      color: "#1FB6AD"
    },
    '&$checked + $track': {
      backgroundColor: "#1FB6AD"
    },
  },
  checked: {},
  track: {}
})(Switch);