import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { Error, ExpandLess, ExpandMore, Mail } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

import { BannerType, Spinner } from "components/common";
import { FileUploadStatus, ChunkSize, uploadFileByChunks } from "Helpers";
import MessageFileAttachment from "./MessageFileAttachment";

const MessageFile = ({ mail, setFiles, uploadFile, setBannerState }) => {
  const [expandMail, setExpandMail] = useState(false);
  const [expandAttachments, setExpandAttachments] = useState(true);

  useEffect(() => {
    let onUploadArguments = { fileId: mail.id, fileName: mail.file.name };

    setFiles(prevFiles => {
      let updatedFiles = prevFiles.map(file => {
        if (file.id === mail.id && file.upload?.status === FileUploadStatus.notStarted) {
          let uploadPromise = mail.content.byteLength > ChunkSize.chunk
            ? uploadFileByChunks(mail, uploadFile, onUpload, onUploadArguments)
            : uploadMessage();

          let updatedUpload = { status: FileUploadStatus.inProgress, promise: uploadPromise };
          return { ...file, upload: updatedUpload };
        }
        return file;
      });
      return updatedFiles;
    });
  }, []);

  const onUpload = ({ fileId, fileName, uploadPath }) => {
    let success = !!uploadPath;

    setFiles(prevFiles => {
      let updatedFiles = prevFiles.map(file => {
        if (file.id === fileId) {
          let updatedUpload = {
            ...file.upload,
            status: success ? FileUploadStatus.success : FileUploadStatus.failed,
            path: uploadPath
          };
          return { ...file, upload: updatedUpload };
        }
        return file;
      });
      return updatedFiles;
    });

    let bannerType = success ? BannerType.success : BannerType.error;
    let message = success ? `${fileName} is uploaded successfully.` : "Error occurred. File is not uploaded";
    setBannerState({ show: true, type: bannerType, message });
  }

  const uploadMessage = async () => {
    let fileName = mail.messageFile.name;
    let fileId = mail.id;

    let response = await uploadFile(fileName, mail.content);
    if (response.success) {
      onUpload({ fileId, fileName, uploadPath: [response.uploadPath] });
    } else {
      onUpload({ fileId, fileName });
      throw response.error;
    }
  }

  const onExpandMailClick = useCallback(e => {
    e.stopPropagation()
    setExpandMail(!expandMail);
  }, [expandMail, setExpandMail]);

  const onExpandAttachmentsClick = useCallback(() => {
    setExpandAttachments(!expandAttachments);
  }, [expandAttachments, setExpandAttachments]);

  const handleMessageFileDeleted = useCallback(id => {
    setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
  }, [setFiles]);

  const handleDownloadEmail = useCallback(() => {
    const {file} = mail;
    const url = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);    
  }, [mail])

  const renderAttachmentsList = () => {
    if (mail.attachments && mail.attachments.length > 0) {
      return (
        <List>
          <ListItem button onClick={onExpandAttachmentsClick}>
            <ListItemText primary={expandAttachments ? "Collapse" : "Expand"} />
            {expandAttachments ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={expandAttachments} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                mail.attachments.map((attachment, index) => {
                  return (
                    <MessageFileAttachment
                      key={attachment.id}
                      attachment={attachment}
                      attachmentIndex={index}
                      mailId={mail.id}
                      loadingStatus={mail.upload.status}
                      setFiles={setFiles}
                    />
                  );
                })
              }
            </List>
          </Collapse>
        </List >
      );
    }

    return <div className="attachments-label no-attachments-color">No Attachments</div>;
  };

  const renderMessageIcon = () => {
    if (mail.upload.status === FileUploadStatus.inProgress) {
      return <ListItemIcon><Spinner size="1.5rem" /></ListItemIcon>;
    }

    if (mail.upload.status === FileUploadStatus.success) {
      return <ListItemIcon><Mail /></ListItemIcon>;
    }

    if (mail.upload.status === FileUploadStatus.failed) {
      return <ListItemIcon><Error color="error" /></ListItemIcon>;
    }
  };

  return (
    <List>
      <ListItem button onClick={handleDownloadEmail}>
        <ListItemIcon>{renderMessageIcon()}</ListItemIcon>
        <ListItemText primary={mail.messageFile.name} className="overflow-wrap-anywhere" />
        <IconButton edge="end" aria-label="expand" onClick={onExpandMailClick}>
          {expandMail ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={() => handleMessageFileDeleted(mail.id)} >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={expandMail} timeout="auto" unmountOnExit>
        <Grid container direction="row" spacing={1}>
          <Grid container item direction="row" spacing={3}>
            <Grid container item xs={3}>
              <div className="label">Title</div>
            </Grid>
            <Grid container item xs={9}>
              <div>{mail.title}</div>
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={3}>
            <Grid container item xs={3}>
              <div className="label">Sender</div>
            </Grid>
            <Grid container item xs={9}>
              <div>{mail.sender}</div>
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={3}>
            <Grid container item xs={3}>
              <div className="label">Recipients</div>
            </Grid>
            <Grid container item xs={9} direction="column">
              {mail.recipients.map((recipient, index) => {
                return <div key={index}>{recipient}</div>;
              })}
            </Grid>
          </Grid>
          <Grid container item direction="row" spacing={3}>
            <Grid container item xs={3}>
              <div className="label attachments-label">Attachments</div>
            </Grid>
            <Grid container item xs={9} direction="column">
              {renderAttachmentsList()}
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </List>
  );
}

export default MessageFile;
