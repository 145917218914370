import React, { useState, useCallback, useEffect } from 'react';
import { useGreyHint } from 'hamilton-web-components'
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import debounce from 'lodash/debounce';

import { BannerType } from 'components/common';
import { validateRequiredField } from 'components/SubmissionForm/Validation';
import { fetchBrokers } from 'components/SubmissionForm/Form.api';

const BrokerAutocomplete = (props) => {
  const {
    fieldsState,
    validationState,
    setFieldsState,
    setValidationState,
    setBannerState,
    inputRef,
    hintText
  } = { ...props };

  const loadingTime = 1000;

  const [areBrokersLoading, setAreBrokersLoading] = useState(false);
  const [inputValue, setInputValue] = useState(' ')
  const [brokers, setBrokers] = useState([]);

  const { greyHintForStartAdornment } = useGreyHint({
    inputRef,
    inputValue,
    setInputValue,
    hintText,
    sx: {ml: '0.25rem', 'span': {fontFamily: "inherit", fontSize: "1rem"}}
  });

  const loadBrokersAsync = useCallback(async (productId, companyId, filter) => {
    let response = await fetchBrokers(productId, companyId, filter);

    if (response.success) {
      setBrokers(response.data);
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }
  }, [setBrokers, setBannerState]);

  useEffect(() => {
    let productId = fieldsState.product?.productId;
    let companyId = fieldsState.companyId;
    if (productId) {
      loadBrokersAsync(productId, companyId);
    } else {
      setBrokers([]);
    }
  }, [fieldsState.product, loadBrokersAsync]);

  const getBrokersQuery = useCallback(debounce(async (productId, companyId, filter) => {
    await loadBrokersAsync(productId, companyId, filter);
    setAreBrokersLoading(false);
  }, loadingTime), [setBrokers, setBannerState]);

  const validateBroker = brokerName => {
    let result = validateRequiredField("Broker Name", brokerName);
    setValidationState(previousState => ({
      ...previousState,
      fields: {
        ...previousState.fields,
        broker: result,
        brokerBranch: { valid: true, message: "" }
      }
    }));
  };

  const handleChange = async (_, broker) => {
    const brokerName = broker?.name;

    if (props.onChange != null) {
      props.onChange({ target: { value: brokerName } }, brokerName);
    }

    validateBroker(brokerName);

    setFieldsState(previousState => ({
      ...previousState,
      broker: {
        id: broker?.id,
        name: brokerName,
        code: broker?.code,
        email: broker?.email
      },
      brokerBranch: null,
      brokerContact: null
    }));
  };

  const handleTextChange = async event => {
    let newValue = event.target?.value;
    if (newValue) {
      setAreBrokersLoading(true);
      await getBrokersQuery(fieldsState.product?.productId, fieldsState.companyId, newValue);
    }
  };

  const handleBlur = () => {
    validateBroker(fieldsState.broker?.name);
  };

  const filterOptions = (options, params) => {
    let filter = createFilterOptions({
      ignoreCase: true,
      matchFrom: 'any',
      stringify: option => option.name,
      limit: 15
    });

    const filtered = filter(options, params);
    let rows = filtered.map(broker => ({
      name: broker?.name,
      id: broker?.id,
      code: broker?.code,
      email: broker?.email
    }));

    return rows;
  };

  const getOptionLabel = option => {
    return option?.name ?? '';
  };

  const renderInputElement = params => (
    <TextField
      {...params}
      inputRef={inputRef}
      required
      label="Broker Name"
      onChange={handleTextChange}
      error={!validationState.fields.broker.valid}
      helperText={validationState.fields.broker.message}
      InputProps={{
        ...params.InputProps,
        ...greyHintForStartAdornment,
        endAdornment: (
          <>
            {areBrokersLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  );

  return <Autocomplete
    id="brokerName"
    disabled={!fieldsState.product?.productId}
    value={fieldsState.broker}
    onChange={handleChange}
    onBlur={handleBlur}
    filterOptions={filterOptions}
    loading={areBrokersLoading}
    clearOnBlur
    clearOnEscape
    freeSolo
    handleHomeEndKeys
    options={brokers}
    getOptionLabel={getOptionLabel}
    renderOption={option => option.name}
    renderInput={renderInputElement}
    inputValue={inputValue}
    onInputChange={(e, newValue) => setInputValue(newValue)}
  />;
};

export default BrokerAutocomplete;