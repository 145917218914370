import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { saveAs } from 'file-saver'
import { parse } from 'query-string';

import { Divider, FormControl, Grid, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { CloudDownload, Error, Send, Visibility } from "@material-ui/icons";

import { fetchCurrentUser } from 'Api';
import { Banner, BannerType, Dropdown, Footer, FormButton } from '../common';
import QuoteMapper from './Helpers';
import { fetchEmailPreview, fetchEmailTemplate, fetchPolicy, generateDocument, sendData } from './BrokerQuote.Api';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './BrokerQuote.css';

export const BrokerQuote = ({ setLoading }) => {
  const initialFormState = {
    status: '',
    message: '',
    emailPreview: '',
    policyNo: '',
    underwriterEmail: '',
    brokerContactEmail: '',
    policyDetails: '',
    file: '',
    notificationRequest: '',
  };

  const location = useLocation();

  const [formState, setFormState] = useState(initialFormState);
  const [validationState, setValidationState] = useState({
    valid: true,
    errors: []
  });
  const [bannerState, setBannerState] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    let initializeForm = async () => {
      let queryParams = QuoteMapper.mapQueryParametersToForm(parse(location.search));
      var validationResult = validateQueryParams(queryParams);
      if (validationResult.length > 0) {
        setValidationState({
          errors: validationResult
        });
      } else {
        setFormState({
          ...formState,
          ...queryParams
        });

        let policyResponse = await fetchPolicy(queryParams.policyNo);
        if (policyResponse.success && queryParams.status) {
          let user = await fetchCurrentUser();

          let notificationRequest = {
            Status: queryParams.status,
            BrokerName: queryParams.brokerContact,
            BrokerEmail: queryParams.brokerContactEmail,
            SubmissionNumber: queryParams.policyNo,
            UnderwriterName: user?.displayName,
            Title: user?.jobTitle,
            PhoneNumber: user?.mobilePhone,
            UnderwriterEmail: user?.mail
          };

          let emailTemplateResponse = await fetchEmailTemplate(notificationRequest);
          if (emailTemplateResponse.success) {
            setFormState({
              ...formState,
              ...queryParams,
              policyDetails: policyResponse.data,
              underwriterEmail: user?.mail,
              notificationRequest: notificationRequest,
              message: emailTemplateResponse.data
            });

            setEditorState(htmlToEditorState(emailTemplateResponse.data));
          } else {
            setBannerState({
              show: true,
              type: BannerType.error,
              message: emailTemplateResponse.errorMessage
            });
          }
        } else {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: policyResponse.errorMessage
          });
        }
      }

      setLoading(false);
    }

    setLoading(true);
    initializeForm();
  }, []);

  const htmlToEditorState = (html) => {
    let state = EditorState.createEmpty();

    try {
      let contentBlock = htmlToDraft(html);
      let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      state = EditorState.createWithContent(contentState);
    } catch (error) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: 'Error occured during email loading to editor: ' + error.message
      });
    }

    return state;
  }

  const validateQueryParams = params => {
    let result = [];

    if (!params.policyNo) {
      result.push({
        parameter: 'policy_reference_number',
        message: 'policy_reference_number value should be set.'
      })
    }
    if (!params.brokerContact) {
      result.push({
        parameter: 'broker_contact',
        message: 'broker_contact value should be set.'
      })
    }
    if (!params.brokerContactEmail) {
      result.push({
        parameter: 'broker_contact_email',
        message: 'broker_contact_email value should be set.'
      })
    }

    return result;
  }

  const generateFile = async () => {
    let documentResponse = await generateDocument(formState.policyDetails);
    let result = null;

    if (documentResponse.success) {
      setFormState({
        ...formState,
        file: documentResponse.data.file
      });

      result = documentResponse.data.file;
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: documentResponse.errorMessage
      });
    }

    return result;
  }

  const onStatusChanged = async event => {
    let status = event.target.value;

    let notificationRequest = formState.notificationRequest;
    notificationRequest.status = status;

    let emailTemplateResponse = await fetchEmailTemplate(notificationRequest);

    if (emailTemplateResponse.success) {
      setFormState({
        ...formState,
        status: status,
        message: emailTemplateResponse.data,
        notificationRequest: notificationRequest,
        emailPreview: '',
      });

      setEditorState(htmlToEditorState(emailTemplateResponse.data));
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: emailTemplateResponse.errorMessage
      });
    }
  }

  const onPreviewClick = async () => {
    let emailResponse = await fetchEmailPreview({
      Message: formState.message,
      Subject: formState.status
    });

    if (emailResponse.success) {
      setFormState({
        ...formState,
        emailPreview: emailResponse.data
      });
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: emailResponse.errorMessage
      });
    }
  }

  const onDocumentDownloadClick = async () => {
    setLoading(true);

    let document = !formState.file
      ? await generateFile()
      : formState.file;

    if (document != null) {
      var blobFile = new Blob([document], { type: "application/pdf" });
      saveAs(blobFile, formState.policyNo + '-quote.pdf');
    }

    setLoading(false);
  }

  const onEditorStateChange = async (state) => {
    setEditorState(state);
    setFormState({
      ...formState,
      message: draftToHtml(convertToRaw(state.getCurrentContent()))
    });
  };

  const onSubmit = async () => {
    setLoading(true);

    let document = !formState.file
      ? await generateFile()
      : formState.file;

    let formData = QuoteMapper.mapFormToRequest(formState, document);
    let submitResponse = await sendData(formData);
    if (!submitResponse.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: submitResponse.errorMessage
      });
    }

    setLoading(false);
  };

  const renderEmailEditor = () => {
    return (
      <Grid container className="form-section" direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid item container xs={6} direction="column" spacing={3} className="form-fields-container">
          <Grid item>
            <FormControl
              fullWidth
              required
            >
              <Dropdown
                id="status"
                label="Status"
                data={["Indication", "Inquiry", "Quoted", "Closed", "Decline", "Bound"]}
                value={formState.status}
                onChange={onStatusChanged}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              required
            >
              <Editor
                id="message"
                label="Email body"
                editorClassName="text-editor"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </FormControl>
          </Grid>
          <div className="form-footer">
            <FormButton endIcon={<Visibility />} onClick={onPreviewClick}>
              Preview
            </FormButton>
            <FormButton endIcon={<Send />} onClick={onSubmit}>
              Submit
            </FormButton>
          </div>
        </Grid>
        <Grid item container xs={6} direction="column" spacing={3} className="form-fields-container">
          <Grid item>
            <FormControl fullWidth>
              {renderPreview()}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const renderPreview = () => {
    if (formState.emailPreview) {
      let htmlText = () => ({ __html: formState.emailPreview });
      return (
        <div>
          <div className="preview-box">
            <div dangerouslySetInnerHTML={htmlText()}></div>
          </div>
          <div className="form-footer">
            <FormButton endIcon={<CloudDownload />} onClick={onDocumentDownloadClick}>
              Download Document
            </FormButton>
          </div>
        </div>
      );
    }

    return null;
  }

  const renderValidationMessages = () => {
    return <>
      <h3 className="validation-errors-header">
        Query parameters validation is failed. Please take a look at the errors below
      </h3>
      <List component="div" disablePadding>
        {validationState.errors?.map(error => {
          return (
            <ListItem key={error.parameter}>
              <ListItemIcon>
                <Error color="error" />
              </ListItemIcon>
              <ListItemText primary={error.message} />
            </ListItem>
          );
        })}
      </List>
    </>;
  }

  const renderForm = () => {
    return (
      <div className="broker-quote-container">
        <h2>Broker Quote</h2>
        <Divider />
        <Banner
          type={bannerState.type}
          message={bannerState.message}
          show={bannerState.show}
          showBanner={show => setBannerState({
            ...bannerState,
            show: show
          })}
        />
        {validationState.valid ? renderEmailEditor() : renderValidationMessages()}
        <Footer />
      </div>
    );
  }

  return renderForm();
};