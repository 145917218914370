import { QueryParametersConstants } from "./QueryParameters";

const QuoteMapper = {
  mapQueryParametersToForm: parameters => {
    let policyNoParameter = QueryParametersConstants.PolicyNo;
    let statusParameter = QueryParametersConstants.Status;
    let brokerContactParameter = QueryParametersConstants.BrokerContact;
    let brokerContactEmailParameter = QueryParametersConstants.BrokerContactEmail;

    return {
      policyNo: searchParameter(parameters, policyNoParameter),
      status: searchParameter(parameters, statusParameter),
      brokerContact: searchParameter(parameters, brokerContactParameter),
      brokerContactEmail: searchParameter(parameters, brokerContactEmailParameter)
    };
  },
  mapFormToRequest: (data, attachment) => {
    let formData = new FormData();

    formData.append("message", data.message);
    formData.append("attachment", attachment);
    formData.append("subject", data.status);
    formData.append("underwriterEmail", data.underwriterEmail);
    formData.append("brokerEmail", data.brokerContactEmail);

    return formData;
  }
}

const searchParameter = (object, key) => {
  return object[Object.keys(object)
    .find(k => k.toLowerCase() === key.toLowerCase())
  ];
}

export default QuoteMapper;