import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useEffect } from 'react';

const filter = createFilterOptions();

export default function AutocompleteOrAdd({ onChange, label, helperText, error, ...props }) {
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    onChange(value?.inputValue ?? value);
  }, [value])

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        if (newValue && newValue.inputValue) {
          setValue({
            inputValue: newValue.inputValue,
            title: newValue.inputValue
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && (!options || !options.includes(params.inputValue))) {
          filtered.push({
            inputValue: params.inputValue,
            title: `Create "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.title;
      }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} error={error} helperText={helperText} label={label} variant="outlined" />
      )}
      {...props}
    />
  );
}