import { ProducingTeam } from "Helpers";
import {
  isRenewedPolicyEnabled, isExpiringPolicyReferenceNumberEnabled, isDualStampEntity, isPlacingBasisDisabled,
  isProducingTeamUsed, areMinorClassesUsed, isNewYorkMgaSectionEnabled, isBrokerSectionEnabled, isStatesSectionEnabled, isMiamiSubmission, isNYSubmission
} from "../Helpers";

const fieldsMaxLength = {
  "Policy Entry Information": 250,
  "EPI Update": 250,
  "Assured / Master Assured": 130,
  "Policy Reference Number": 30,
  "Master Policy Reference Number": 30,
  "Expiring Policy Reference Number": 30,
  "Comments": 200,
  "Deal Name": 50,
  "Cedent": 50
};

const numericFieldsConstraints = {
  "Total Number of Policy References/Declarations": {
    minValue: 1,
    maxValue: 99
  }
};

const validateSecondAssured = form => {
  if (isDualStampEntity(form.businessEntity)) {
    return validateTextField("Assured / Master Assured (HIDAC)", form.secondAssured);
  }

  return { valid: true, message: "" };
}

const validateAssuredNotMiami = form => {
  if (!isMiamiSubmission(form.businessEntity)) {
    return validateTextField("Assured / Master Assured", form.assured);
  }
  else {
    return validateTextField("Insured (Miami)", form.assuredMiami);
  }

  return { valid: true, message: "" };
}

const validateMajorClasses = form => {
  var validationResult = {};
  if (!form.businessEntity) {
    validationResult[""] = validateRequiredField("Major Class", "");
  } else {
    for (let majorClass in form.majorClasses) {
      if (form.majorClasses.hasOwnProperty(majorClass)) {
        validationResult[majorClass] = validateRequiredField(majorClass, form.majorClasses[majorClass]);
      }
    }
  }

  return validationResult;
};

const validatePolicyReferenceNumber = form => {
  let isPolicyReferenceEnabled = form.documentTypesList?.find(
    documentType => documentType.name === form.documentType)?.isPolicyReferenceNumberEnabled;

  if (isPolicyReferenceEnabled) {
    if (isDualStampEntity(form.businessEntity)) {
      let policyReferenceResult = !form.isNewAssured
        ? validateTextField("Policy Reference Number (S4000)", form.policyReferenceNumber)
        : { valid: true, message: "" };

      let secondPolicyReferenceResult = !form.isNewSecondAssured
        ? validateTextField("Policy Reference Number (HIDAC)", form.secondPolicyReferenceNumber)
        : { valid: true, message: "" };

      return { policyReferenceResult, secondPolicyReferenceResult };
    }

    let policyReferenceResult = !form.isNewAssured
      ? validateTextField("Policy Reference Number", form.policyReferenceNumber)
      : { valid: true, message: "" };

    let secondPolicyReferenceResult = { valid: true, message: "" };

    return { policyReferenceResult, secondPolicyReferenceResult };
  }

  return {
    policyReferenceResult: { valid: true, message: "" },
    secondPolicyReferenceResult: { valid: true, message: "" }
  };
}

const validateMasterPolicyReferenceNumber = (form, placingBases) => {
  let validationRequired =
    placingBases.find(placingBasis => placingBasis.name === form.placingBasis)?.isMasterPolicyReferenceNumberEnabled &&
    form.documentTypesList?.find(documentType => documentType.name === form.documentType)?.isMasterPolicyReferenceNumberEnabled;

  if (validationRequired) {
    if (isDualStampEntity(form.businessEntity)) {
      let masterPolicyReferenceResult = validateTextField(
        "Master Policy Reference Number (S4000)", form.masterPolicyReferenceNumber);
      let secondMasterPolicyReferenceResult = validateTextField(
        "Master Policy Reference Number (HIDAC)", form.secondMasterPolicyReferenceNumber);

      return { masterPolicyReferenceResult, secondMasterPolicyReferenceResult };
    }

    let masterPolicyReferenceResult = validateTextField("Master Policy Reference Number", form.masterPolicyReferenceNumber);
    let secondMasterPolicyReferenceResult = { valid: true, message: "" };

    return { masterPolicyReferenceResult, secondMasterPolicyReferenceResult };
  }

  return {
    masterPolicyReferenceResult: { valid: true, message: "" },
    secondMasterPolicyReferenceResult: { valid: true, message: "" }
  };
}

const validateExpiringPolicyReferenceNumber = form => {
  if (isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList) || 
    !isExpiringPolicyReferenceNumberEnabled(form.documentTypesList, form.documentType, form.renewedPolicy)) {
    return {
      expiringPolicyReferenceResult: { valid: true, message: "" },
      secondExpiringPolicyReferenceResult: { valid: true, message: "" }
    };
  }

  if (isDualStampEntity(form.businessEntity)) {
    let expiringPolicyReferenceResult = validateTextField(
      "Expiring Policy Reference Number (S4000)", form.expiringPolicyReferenceNumber);
    let secondExpiringPolicyReferenceResult = validateTextField(
      "Expiring Policy Reference Number (HIDAC)", form.secondExpiringPolicyReferenceNumber);

    return { expiringPolicyReferenceResult, secondExpiringPolicyReferenceResult };
  }

  let expiringPolicyReferenceResult = validateTextField("Expiring Policy Reference Number", form.expiringPolicyReferenceNumber);
  let secondExpiringPolicyReferenceResult = { valid: true, message: "" };

  return { expiringPolicyReferenceResult, secondExpiringPolicyReferenceResult };
}

const validateMinorClass = (form, dropdownsData) => {
  if (areMinorClassesUsed(form.documentType, form.businessEntity, form.firstMajorClass, dropdownsData)) {
    return validateRequiredField("Minor Class", form.minorClass);
  }

  return { valid: true, message: "" };
}

const validateClass = (form, dropdownsData) => {
  let isClassAvailable =
    dropdownsData.entities[form.businessEntity]?.producingTeam === ProducingTeam.London;

  if (isClassAvailable && areMinorClassesUsed(form.documentType, form.businessEntity, form.firstMajorClass, dropdownsData)) {
    return validateRequiredField("Class", form.class);
  }

  return { valid: true, message: "" };
}

const validateProducingTeam = (form, dropdownsData) => {
  if (isProducingTeamUsed(form.documentType, form.businessEntity, form.firstMajorClass, dropdownsData)) {
    return validateRequiredField("Producing Team", form.producingTeam);
  }

  return { valid: true, message: "" };
}

const validateAssignedUW = form => {
  if (isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList)) {
    return validateRequiredField("Assigned UW", form.assignedUW);
  }

  return { valid: true, message: "" };
}

const validateBroker = form => {
  let showBrokerSection = isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList) && isBrokerSectionEnabled(form.product);
  if (showBrokerSection) {
    return validateRequiredField("Broker Name", form.broker?.name);
  }

  return { valid: true, message: "" };
}

const validateBrokerBranch = form => {
  let showBrokerSection = isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList) && isBrokerSectionEnabled(form.product);
  if (showBrokerSection) {
    return validateRequiredField("Broker Branch", form.brokerBranch?.name);
  }

  return { valid: true, message: "" };
}

const validateBrokerContact = form => {
  let showBrokerSection = isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList) && isBrokerSectionEnabled(form.product);
  if (showBrokerSection) {
    return validateRequiredField("Broker Contact", form.brokerContact?.name);
  }

  return { valid: true, message: "" };
}

const validateBrokerContactEmail = (field, form) => {
  let showBrokerSection = isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList) && isBrokerSectionEnabled(form.product);
  if (showBrokerSection && !form.businessEntity.includes('Miami')) {
    let result = validateRequiredField(field, form.brokerContact?.email);
    if (!result.valid) {
      return result;
    }

    return validateEmailField("Broker Contact Email", form.brokerContact?.email);
  }

  return { valid: true, message: "" };
}

const validateState = form => {
  let showState = isStatesSectionEnabled(form.businessEntity);
  if (showState) {
    return validateRequiredField("State", form.state);
  }

  return { valid: true, message: "" };
}

export const validateDatePicker = date => {
  if (!date ||
    (date instanceof Date && !isNaN(date))) {
    return { valid: true, message: "" };
  }

  return { valid: false, message: "Invalid Date Format" };
}

export const validateEffectiveDate = (form, effectiveDate) => {
  if (isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList)) {
    let result = validateRequiredField("Effective Date", effectiveDate);
    return result.valid ? validateDatePicker(effectiveDate) : result;
  }

  return { valid: true, message: "" };
}

const validateQuoteBy = form => {
  if (isNewYorkMgaSectionEnabled(form.documentType, form.documentTypesList)) {
    return validateDatePicker(form.quoteBy);
  }

  return { valid: true, message: "" };
}

const policyEntryInformation = "Policy Entry Information";

const validateMinValue = (field, value, minValue) => {
  minValue = minValue ?? numericFieldsConstraints[field].minValue;
  if (minValue && value < minValue) {
    return {
      valid: false,
      message: `"${field}" value must not be less than ${minValue}.`
    };
  }

  return { valid: true, message: "" };
};

const validateMaxValue = (field, value, maxValue) => {
  maxValue = maxValue ?? numericFieldsConstraints[field].maxValue;
  if (maxValue && value > maxValue) {
    return {
      valid: false,
      message: `"${field}" value must not be greater than ${maxValue}.`
    };
  }

  return { valid: true, message: "" };
};

export const validateRenewedPolicy = (renewedPolicy, documentType, documentTypesList) => {
  if (documentType && !isRenewedPolicyEnabled(documentTypesList, documentType) && renewedPolicy) {
    let allowedDocumentTypes = [...new Set(documentTypesList?.filter(x => x.isPolicyRenewalEnabled).map(x => x.name))];
    return { valid: false, message: `"Renewed Policy" is only applicable for document types: ${allowedDocumentTypes.join(', ')}.` };
  }

  return { valid: true, message: "" };
}

export const validatePreRenewal = form => {
  if (form.preRenewal) {
    return validateRequiredField("Expiring Policy Reference Number", form.expiringPolicyReferenceNumber);
  }
  return { valid: true, message: "" };
}

export const validateTypeOfBusiness = form => {
  
  let showTypeOfBusiness = isNYSubmission(form.businessEntity);
  if (showTypeOfBusiness) {
    return validateRequiredField("Type of Business", form.typeOfBusiness);
  }

  return { valid: true, message: "" };
}

export const validateRequiredField = (field, value) => {
  if (!value) {
    return {
      valid: false,
      message: `"${field.trim()}" is a required field.`
    };
  }

  return { valid: true, message: "" };
};

export const validateFieldLength = (field, value, maxLength) => {
  let fieldMaxLength = maxLength ?? fieldsMaxLength[field];
  if (fieldMaxLength > 0 && value.length > fieldMaxLength) {
    return {
      valid: false,
      message: `"${field.trim()}" maximum length is ${fieldMaxLength} characters.`
    };
  }

  if (field === policyEntryInformation) {
    return { valid: true, message: `"length is ${value.length} characters"` };
  }

  return { valid: true, message: "" };
};

export const validateEmailField = (field, email) => {
  const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isEmail = emailExpression.test(String(email).toLowerCase());
  return { valid: isEmail, message: isEmail ? "" : `${field} has an incorrect format.` };
}

export const validateTextField = (field, value, maxLength) => {
  var result = validateRequiredField(field, value);
  if (!result.valid) {
    return result;
  }

  return validateFieldLength(field, value, maxLength);
};

export const validateNumericField = (field, value, minValue, maxValue) => {
  let result = validateRequiredField(field, value);
  if (!result.valid) {
    return result;
  }

  result = validateMinValue(field, value, minValue);
  if (!result.valid) {
    return result;
  }

  return validateMaxValue(field, value, maxValue);
};

const validateDealName = (form, dropdownsData) => {
  return dropdownsData.entities[form.businessEntity]?.producingTeam === ProducingTeam.Bermuda
    ? validateRequiredField("Deal Name", form.dealName)
    : { valid: true, message: "" };
};

const validateDocumentType = (value, placingBasis, documentTypesList) => {
  let result = validateRequiredField("Document Type", value);
  if (!result.valid) {
    return result;
  }

  return validateDocumentTypeAndPlacingBasis(value, placingBasis, documentTypesList);
};

const validatePlacingBasis = (value, documentType, documentTypesList) => {
  if (isPlacingBasisDisabled(documentTypesList, documentType)) {
    return { valid: true, message: "" };
  }

  let result = validateRequiredField("Placing Basis", value);
  if (!result.valid) {
    return result;
  }

  return validateDocumentTypeAndPlacingBasis(documentType, value, documentTypesList);
};

export const validateDocumentTypeAndPlacingBasis = (documentType, placingBasis, documentTypesList) => {
  if (documentType.length > 0 && placingBasis.length > 0) {
    const isPlacingBasisAllowed = documentTypesList?.find(x => x.name === documentType)?.allowedPlacingBases?.includes(placingBasis);
    if (!isPlacingBasisAllowed) {
      return {
        valid: false,
        message: `This combination of Type Of Document cannot be associated with this Placing Basis.`
      };
    }
  }

  return { valid: true, message: "" };
}

export const validateForm = (form, dropdownsData) => {
  const renewedPolicyResult = validateRenewedPolicy(form.renewedPolicy, form.documentType, form.documentTypesList);
  const policyEntryInformationResult = validateTextField("Policy Entry Information", form.policyEntryInformation);
  const justificationForEpiUpdateResult = validateTextField("EPI Update", form.justificationForEpiUpdate);
  const assuredResult = validateAssuredNotMiami(form);
  const secondAssuredResult = validateSecondAssured(form);
  const entityResult = validateRequiredField("Business Entity", form.businessEntity);
  const dealNameResult = validateDealName(form, dropdownsData);
  const minorClassResult = validateMinorClass(form, dropdownsData);
  const classResult = validateClass(form, dropdownsData);
  const prodicingTeamResult = validateProducingTeam(form, dropdownsData);
  const brokerResult = validateBroker(form);
  const brokerBranchResult = validateBrokerBranch(form);
  const brokerContactResult = validateBrokerContact(form);
  const brokerContactEmailResult = validateBrokerContactEmail("Broker Contact Email", form);
  const assignedUWResult = validateAssignedUW(form);
  const effectiveDateResult = validateEffectiveDate(form, form.effectiveDate);
  const quoteByResult = validateQuoteBy(form);
  const yoaResult = validateRequiredField("YOA", form.yoa);
  const stateResult = validateState(form);
  const documentTypeResult = validateDocumentType(form.documentType, form.placingBasis, form.documentTypesList);
  const placingBasisResult = validatePlacingBasis(form.placingBasis, form.documentType, form.documentTypesList);
  const majorClassResult = validateMajorClasses(form);
  const totalNumberOfPolicyReferencesResult = validateNumericField("Total Number of Policy References/Declarations", form.totalNumberOfPolicyReferences)

  const { policyReferenceResult, secondPolicyReferenceResult } = validatePolicyReferenceNumber(form);
  const { masterPolicyReferenceResult, secondMasterPolicyReferenceResult } = validateMasterPolicyReferenceNumber(form, dropdownsData.placingBases);
  const { expiringPolicyReferenceResult, secondExpiringPolicyReferenceResult } = validateExpiringPolicyReferenceNumber(form);

  const commentsResult = validateFieldLength("Comments", form.comments);
  const preRenewalResult = validatePreRenewal(form);
  const typeOfBusinessResult = validateTypeOfBusiness(form);

  return {
    fields: {
      renewedPolicy: renewedPolicyResult,
      policyEntryInformation: policyEntryInformationResult,
      entity: entityResult,
      dealName: dealNameResult,
      majorClasses: majorClassResult,
      minorClass: minorClassResult,
      class: classResult,
      producingTeam: prodicingTeamResult,
      broker: brokerResult,
      brokerBranch: brokerBranchResult,
      brokerContact: brokerContactResult,
      brokerContactEmail: brokerContactEmailResult,
      assignedUW: assignedUWResult,
      effectiveDate: effectiveDateResult,
      quoteBy: quoteByResult,
      yearOfAccount: yoaResult,
      state: stateResult,
      typeOfDocument: documentTypeResult,
      placingBasis: placingBasisResult,
      justificationForEpiUpdate: justificationForEpiUpdateResult,
      assured: assuredResult,
      secondAssured: secondAssuredResult,
      policyReferenceNumber: policyReferenceResult,
      secondPolicyReferenceNumber: secondPolicyReferenceResult,
      totalNumberOfPolicyReferences: totalNumberOfPolicyReferencesResult,
      masterPolicyReferenceNumber: masterPolicyReferenceResult,
      secondMasterPolicyReferenceNumber: secondMasterPolicyReferenceResult,
      expiringPolicyReferenceNumber: expiringPolicyReferenceResult,
      secondExpiringPolicyReferenceNumber: secondExpiringPolicyReferenceResult,
      comments: commentsResult,
      preRenewal: preRenewalResult,
      typeOfBusiness: typeOfBusinessResult
    },
    valid: renewedPolicyResult.valid && policyEntryInformationResult.valid &&
      assuredResult.valid && secondAssuredResult.valid && dealNameResult.valid &&
      entityResult.valid && yoaResult.valid && stateResult.valid &&
      documentTypeResult.valid && placingBasisResult.valid && minorClassResult.valid &&
      classResult.valid && prodicingTeamResult.valid && assignedUWResult.valid &&
      effectiveDateResult.valid && quoteByResult.valid && Object.values(majorClassResult).every(d => d.valid) &&
      policyReferenceResult.valid && secondPolicyReferenceResult.valid && totalNumberOfPolicyReferencesResult.valid &&
      masterPolicyReferenceResult.valid && secondMasterPolicyReferenceResult.valid &&
      expiringPolicyReferenceResult.valid && secondExpiringPolicyReferenceResult.valid &&
      brokerResult.valid && brokerBranchResult.valid && brokerContactResult.valid &&
      brokerContactEmailResult.valid && commentsResult.valid && preRenewalResult.valid && typeOfBusinessResult.valid
  };
};
