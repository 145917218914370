import React, { useEffect, useState, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { parse } from 'query-string';

import { FormControl, Divider, Grid, Dialog, DialogTitle, DialogActions, TextField} from '@material-ui/core';
import { Send, Clear } from "@material-ui/icons";

import { setValueAndTriggerInputEvent, waitUntil, waitForFieldToAppearAndEnable } from 'star-ai';

import { ErrorPage } from '../navigation';

import {  Banner,  DatePickerElement,  BannerType, Dropdown,  FormButton,  Footer,  DragAndDropArea, DialogButton,  FileSection,  } from '../common';

import { parseMessage, initDocument, FileUploadStatus, FileType } from 'Helpers';

import QuoteMapper from './Helpers';

import { fetchCurrentUser } from 'Api';
import { fetchEntitiesData, fetchKissflowInfo } from './MiamiClone.Api';

import {
  fetchProductPlacingBases, fetchUWGroupMembers, fetchPlacingBases, fetchDocTypes, fetchMiamiCedants, uploadFile, fetchAddressCountries } from "components/SubmissionForm/Form.api";

import BrokerAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerAutocomplete";
import BrokerBranchAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerBranchAutocomplete";
import BrokerContactAutocomplete from "components/SubmissionForm/Sections/MGA/BrokerContactAutocomplete";

import { initialDropdowns, initialFormState, initialValidationState, } from 'components/SubmissionForm/State';

import { submitFormData } from 'components/SubmissionForm/Form.api';

import { getFormattedDate, isMasterPolicyReferenceNumberEnabled } from 'components/SubmissionForm/Helpers/Form.helpers';

import './MiamiClone.css';

import { validateTextField, validateRequiredField, validateDocumentTypeAndPlacingBasis,validateEffectiveDate, validateForm } from "components/SubmissionForm/Validation";

export const MiamiClone = ({ setLoading }) => {

  const [isInitialized, setInitialized] = useState(false);
  const [userState, setUserState] = useState();
  const [dropdownsData, setDropdownsData] = useState(initialDropdowns);
  const location = useLocation();  
  const dropdownRef = useRef(null);
  const majorClassRef = useRef(null);
  const documentTypeRef = useRef(null);
  const placingBasisRef = useRef(null);
  const assignedUWRef = useRef(null);
  const yoaRef = useRef(null);
  const miamiRef = useRef(null);

  const [formState, setFormState] = useState(initialFormState);
  const [validationState, setValidationState] = useState(initialValidationState);
  const [bannerState, setBannerState] = useState({});
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    const initializeForm = async () => {
      let queryParams = QuoteMapper.mapQueryParametersToForm(parse(location.search));
      var validationResult = validateQueryParams(queryParams);
      if (validationResult.length > 0) {
        setValidationState((prevState) => {
          return {
            ...prevState,
            fields: {
              ...initialValidationState.fields,
              policyEntryInformation: prevState.fields.policyEntryInformation,
              renewedPolicy: prevState.fields.renewedPolicy,
            },
            errors: validationResult
          };
        });

        let message = validationResult[0].message;

        setBannerState({ show: true, type: BannerType.error, message: message });
      } else {

        const response = await fetchEntitiesData();

        if (response.success) {
          loadFormData(response.data);
          await loadUserInfo();
          let processFlow = 'Policy_Entry_Assigned_Cases_Miami_';

          let kissflowInfo = await fetchKissflowInfo(processFlow, queryParams.kissflowId);
          if (kissflowInfo.success) {

            const policyInfo = {
              PolicyEntryInformation: kissflowInfo.data.policyEntryInformation,
              BusinessEntity: kissflowInfo.data.businessEntity,
              MajorClass: kissflowInfo.data.majorClass,
              YOA: kissflowInfo.data.yoa,
              TypeOfDocument: kissflowInfo.data.typeOfDocument,
              PlacingBasis: kissflowInfo.data.placingBasis,
              Assured: kissflowInfo.data.insuredName,
              uwEmail: kissflowInfo.data.assignedUnderwriter,
              ProducingTeam: kissflowInfo.data.producingTeam,
              BrokerId: kissflowInfo.data.brokerId,
              BrokerCode: kissflowInfo.data.brokerCode,
              BrokerName: kissflowInfo.data.brokerName,
              BrokerBranchId: kissflowInfo.data.brokerBranchId,
              BrokerBranchName: kissflowInfo.data.brokerBranch,
              BrokerBranchCode: kissflowInfo.data.brokerBranchCode,
              BrokerContactId: kissflowInfo.data.brokerContactId,
              BrokerContactCode: kissflowInfo.data.brokerContactCode,
              BrokerContactName: kissflowInfo.data.brokerContact,
              EffectiveDate: kissflowInfo.data.effectiveDate,
              BrokerRelationshipId: kissflowInfo.data.brokerRelationshipId1,
              ProductCode: kissflowInfo.data.productCode,
              ProductId: kissflowInfo.data.productId,
              UnderwriterName: kissflowInfo.data.underWriterName,
              Cedant: kissflowInfo.data.cedantInformation ?? kissflowInfo.data.reinsuredName ?? '',
              Cedant_Country: kissflowInfo.data.cedantCountry ?? kissflowInfo.data.cedantCountry1 ?? '',
              QuoteDate: kissflowInfo.data.quoteDate,
              CedantText: (kissflowInfo.data.cedantInformation ?? kissflowInfo.data.reinsuredName ?? '') + ' | ' + (kissflowInfo.data.cedantCountry ?? kissflowInfo.data.cedantCountry1 ?? '')
            };           


            setValueAndTriggerInputEvent(dropdownRef.current.node, policyInfo.BusinessEntity);

            try {
              await waitUntil(() => waitForFieldToAppearAndEnable(majorClassRef, "MajorClass")).promise;
              setValueAndTriggerInputEvent(majorClassRef.current.node, policyInfo.MajorClass)
            } catch (error) {
              let message = 'Major Class not working';
              setBannerState({ show: true, type: BannerType.error, message: message });
            }

            try {
              await waitUntil(() => waitForFieldToAppearAndEnable(documentTypeRef, "TypeOfDocument")).promise;
              setValueAndTriggerInputEvent(documentTypeRef.current.node, policyInfo.TypeOfDocument)
            } catch (error) {
              let message = 'Type of Document not working';
              setBannerState({ show: true, type: BannerType.error, message: message });
            }

            try {
              await waitUntil(() => waitForFieldToAppearAndEnable(placingBasisRef, "PlacingBasis")).promise;
              setValueAndTriggerInputEvent(placingBasisRef.current.node, policyInfo.PlacingBasis)

              setFormState(previousState => ({
                ...previousState,
                product: {
                  productId: policyInfo.ProductId,
                  productCode: policyInfo.ProductCode
                },
                broker: {
                  id: policyInfo.BrokerId,
                  name: policyInfo.BrokerName,
                  code: policyInfo.BrokerCode
                },
                brokerBranch: {
                  id: policyInfo.BrokerBranchId,
                  name: policyInfo.BrokerBranchName,
                  code: policyInfo.BrokerBranchCode,
                },
                brokerContact: {
                  id: policyInfo.BrokerContactId,
                  name: policyInfo.BrokerContactName,
                  code: policyInfo.BrokerContactCode,
                  relationshipId: policyInfo.BrokerRelationshipId
                },
                policyEntryInformation: policyInfo.PolicyEntryInformation,
                effectiveDate: new Date(policyInfo.EffectiveDate),
                assuredMiami: policyInfo.Assured,
                producingTeam: policyInfo.ProducingTeam,
                justificationForEpiUpdate: ' ',
                yoa: policyInfo.YOA
              }));
            } catch (error) {
              let message = 'Placing Basis not working';
              setBannerState({ show: true, type: BannerType.error, message: message });
            }

            try {
              await waitUntil(() => waitForFieldToAppearAndEnable(assignedUWRef, "uwEmail")).promise;
              setValueAndTriggerInputEvent(assignedUWRef.current.node, policyInfo.uwEmail)
            } catch (error) {
              let message = 'Assigned UW not working';
              setBannerState({ show: true, type: BannerType.error, message: message });
            }

            try {
              await waitUntil(() => waitForFieldToAppearAndEnable(yoaRef, "YOA")).promise;
              setValueAndTriggerInputEvent(yoaRef.current.node, policyInfo.YOA)
            } catch (error) {
              let message = 'YOA not working';
              setBannerState({ show: true, type: BannerType.error, message: message });
            }

            if (policyInfo.Cedant_Country.length > 3 && policyInfo.Cedant.length > 3) {
              try {
                await waitUntil(() => waitForFieldToAppearAndEnable(miamiRef, "CedantText")).promise;
                setValueAndTriggerInputEvent(miamiRef.current.node, policyInfo.CedantText)
              } catch (error) {
                let message = 'Miami Cedants not working';
                setBannerState({ show: true, type: BannerType.error, message: message });
              }
            }
          }
          else {
              setBannerState({
                show: true,
                type: BannerType.error,
                message: response.errorMessage,
              });           
          }
        }
        else {
          if (response.permissionError) {
            setErrorMessage(response.errorMessage);
          } else {
            setBannerState({
              show: true,
              type: BannerType.error,
              message: response.errorMessage,
            }); 
          }         
        }
        setInitialized(true);
      }
      setLoading(false);
    }

    setLoading(true);
    initializeForm();
  }, []);


  const loadFormData = (data) => {
    var entities = [];
    for (var item in data.entities) {
      if (data.entities.hasOwnProperty(item)) {
        entities.push(item);
      }
    }

    setDropdownsData({
      ...data,
      entitiesList: entities,
      majorClasses: [],
      minorClasses: [],
      classes: [],
      years: [],
      placingBases: [],
      productPlacingBases: [],
      assignedUWs: [],
      states: [],
      miamiCedants: [],
      miamiCedantsCountries: [],
    });
  };

  const loadUserInfo = async () => {
    var user = await fetchCurrentUser();
    setUserState(user);
    setFormState({
      ...formState,
      underwriterEmail: user?.mail,
    });
  };

  const getDocTypes = async (entity) => {
    let docTypesReponse = await fetchDocTypes(entity);
    if (!docTypesReponse?.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: docTypesReponse.errorMessage,
      });

      return [];
    }

    return docTypesReponse?.data ?? [];
  };

  const getPlacingBases = async entity => {
    let placingBasesResponse = await fetchPlacingBases(entity);
    if (!placingBasesResponse?.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: placingBasesResponse.errorMessage
      });

      return [];
    }

    return placingBasesResponse?.data ?? [];
  };

  const getMiamiCedants = async (entity) => {
    let miamiCedantsResponse = await fetchMiamiCedants();
    if (!miamiCedantsResponse?.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: miamiCedantsResponse.errorMessage,
      });

      return [];
    }

    return miamiCedantsResponse?.data ?? [];
  };

  const getMiamiCedantsCountries = async () => {
    let miamiCedantsCountriesResponse = await fetchAddressCountries();
    if (!miamiCedantsCountriesResponse?.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: miamiCedantsCountriesResponse.errorMessage,
      });

      return [];
    }

    return miamiCedantsCountriesResponse?.data ?? [];
  };

  const getUWGroupMembers = async entity => {
    let uwGroupMembersResponse = await fetchUWGroupMembers(entity);
    if (!uwGroupMembersResponse.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: uwGroupMembersResponse.errorMessage
      });

      return [];
    }

    return uwGroupMembersResponse.data ?? [];
  };

  const validateQueryParams = params => {
    let result = [];

    if (!params.kissflowId) {
      result.push({
        parameter: 'instance_id',
        message: 'instance_id value should be set.'
      })
    }
    return result;
  }

  const validateEntity = (value) => {
    var result = validateRequiredField('Business Entity', value);
    setValidationState((prevState) => {
      return {
        ...prevState,
        fields: {
          ...prevState.fields,
          entity: result,
        },
      };
    });
  };

  const validateMajorClass = (value, entityName) => {
    var result = validateRequiredField("Major Class", value);
    var majorClassesValidationState = { ...validationState.fields.majorClasses };
    majorClassesValidationState[entityName] = result;

    setValidationState(prevState => {
      return {
        ...prevState,
        fields: {
          ...prevState.fields,
          majorClasses: majorClassesValidationState
        }
      }
    });
  };

  const validatePlacingBasis = value => {
    let result = validateRequiredField("Placing Basis", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        placingBasis: result
      }
    });
  };

  const validateAssignedUW = value => {
    let result = validateRequiredField("Assigned UW", value);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        assignedUW: result
      }
    }));
  };

  const validateYearOfAccount = value => {
    let result = validateRequiredField("YOA", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        yearOfAccount: result
      }
    });
  };


  const onPolicyEntryInformationChanged = (event) => {
    if (isInitialized) {
      setFormState({
        ...formState,
        policyEntryInformation: event.target.value,
      });
    }
  };

  const onEntityChanged = async (event) => {

    var entityValue = event.target.value;

    if (entityValue !== '') {
      let entityData = dropdownsData.entities[entityValue];
      let currentMajorClasses = {};

      if (entityData.parentEntities) {
        for (var i = 0; i < entityData.parentEntities.length; i++) {
          currentMajorClasses[entityData.parentEntities[i]] = '';
        }
      } else {
        currentMajorClasses[entityValue] = '';
      }

      setValidationState((prevState) => {
        return {
          ...prevState,
          fields: {
            ...initialValidationState.fields,
            policyEntryInformation: prevState.fields.policyEntryInformation,
            renewedPolicy: prevState.fields.renewedPolicy,
          },
        };
      });

      let entityName = entityData.parentEntities
        ? entityData.parentEntities[0]
        : entityData.name;

      let placingBases = await getPlacingBases(entityName);
      let docTypes = await getDocTypes(entityName);
      let miamiCedants = await getMiamiCedants();
      let miamiCedantsCountries = await getMiamiCedantsCountries();
      let years = entityData.years;
      let majorClassesList = entityData.parentEntities
        ? dropdownsData.entities[entityData.parentEntities[0]].majorClasses
        : entityData.majorClasses;

      setFormState((prevState) => ({
        ...initialFormState,
        critical: prevState.critical,
        renewedPolicy: prevState.renewedPolicy,
        policyEntryInformation: prevState.policyEntryInformation,
        underwriterEmail: prevState.underwriterEmail,
        businessEntity: entityValue,
        firstMajorClass: '',
        majorClasses: currentMajorClasses,
        producingTeam:
          dropdownsData.producingTeams.find(
            (team) => team === entityData.defaultProducingTeam,
          ) || '',
        yoa: entityData.autoselectCurrentYear
          ? years.find((year) => year === new Date().getFullYear())
          : '',
        defaultPlacingBasis: entityData.defaultPlacingBasis,
        documentTypesList: docTypes,
        documentType: docTypes.find((doc) => doc.name === 'Submissions').name,
        totalNumberOfPolicyReferences: 1,
        peerReviewNotRequired: !entityData.allowsPeerReview,
        companyId: entityData.companyId,
      }));

      setDropdownsData({
        ...dropdownsData,
        majorClasses: majorClassesList ? majorClassesList : [],
        placingBases: placingBases,
        documentTypes: docTypes,
        years: years ? years : [],
        miamiCedants: miamiCedants ? miamiCedants : [],
        miamiCedantsCountries: miamiCedantsCountries
          ? miamiCedantsCountries
          : [],
      });
    }

    validateEntity(entityValue);

    return {
      success: true,
    }    
  };

  const onMajorClassChanged = async event => {
    let value = event.target.value;
    let currentMajorClasses = { ...formState.majorClasses };
    currentMajorClasses[formState.businessEntity] = value;

    let placingBases = await getPlacingBases(formState.businessEntity);

    setDropdownsData({
      ...dropdownsData,
      placingBases: placingBases,
      preRenewal: false
    });

    setFormState({
      ...formState,
      firstMajorClass: value,
      class: '',
      majorClasses: currentMajorClasses,
      placingBasis: '',
    });

    validateMajorClass(value, formState.businessEntity);

    if (!!formState.documentType) {
      await changeDocumentType(formState.documentType);
      await tryLoadProductPlacingBases(formState.documentType, value);
    }
  };

  const onDocumentTypeChanged = async event => {
    await changeDocumentType(event.target.value);
    await tryLoadProductPlacingBases(event.target.value, formState.firstMajorClass);
  }

  const onProductPlacingBasisChanged = event => {
    let placingBasis = event.target?.value;
    let placingBasisData = dropdownsData.productPlacingBases.find(m => m.placingBasis === placingBasis);

    setFormState(previousState => ({
      ...previousState,
      placingBasis: placingBasis,
      product: {
        productId: placingBasisData.productId,
        productCode: placingBasisData.productCode,
      }
    }));

    validatePlacingBasis(placingBasis);
  }

  const onAssignedUWChanged = event => {
    let value = event?.target?.value;
    let assignedUWName = dropdownsData.assignedUWs.find(a => a.mail === value)?.displayName;

    setFormState({
      ...formState,
      assignedUW: value,
      assignedUWName
    });

    validateAssignedUW(value);
  };

  const onEffectiveDateChanged = value => {
    let result = validateEffectiveDate(formState, value);

    setValidationState(validState => ({
      ...validState,
      fields: {
        ...validState.fields,
        effectiveDate: result
      }
    }));

    setFormState(prevState => ({
      ...prevState,
      effectiveDate: value
    }));
  };

  const onYearOfAccountChanged = event => {
    setFormState({
      ...formState,
      yoa: event.target.value,
    });

    validateYearOfAccount(event.target.value);
  };

  const onMiamiCedantChanged = event => {

    let miamiCedantsResponse = dropdownsData.miamiCedants.find(m => (m.companyName + ' | ' + m.countryName) === event.target?.value)

    let miamiCedantsCountriesResponse = dropdownsData.miamiCedantsCountries.find(m => m.description === miamiCedantsResponse.countryName)

    setFormState({
      ...formState,
      miamiCedant: miamiCedantsResponse.companyName,
      miamiCedantCountry: miamiCedantsCountriesResponse.description,
      miamiCedantCountryCode: miamiCedantsCountriesResponse.code,
      miamiCedantText: event.target?.value,
    });
  }

  const onAssuredMiamiChanged = event => {
    setFormState({
      ...formState,
      assuredMiami: event.target?.value
    });
  };

  const onEntityBlur = (event) => {
    validateEntity(event?.target?.value);
  };

  const onMajorClassBlur = (event, entityName) => {
    validateMajorClass(event?.target?.value, entityName);
  };

  const onDocumentTypeBlur = (event) => {
    const documentType = event?.target?.value;
    let result = validateRequiredField("Type Of Document", documentType);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(documentType, formState.placingBasis, formState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }
  };

  const onPlacingBasisBlur = (event) => {
    const placingBasis = event?.target?.value;
    let result = validateRequiredField("Placing Basis", placingBasis);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          placingBasis: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(formState.documentType, placingBasis, formState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }
  };

  const onAssignedUWBlur = event => {
    validateAssignedUW(event?.target?.value);
  };

  const onYearOfAccountBlur = event => {
    validateYearOfAccount(event?.target?.value);
  };

  const handleSubmit = async () => {
    setConfirmationDialogOpen(false);
    setLoading(true);

    if (
      files.length === 0 ||
      files.every((file) => file.upload.status === FileUploadStatus.success)
    ) {
      await submitForm();
    } else if (
      files.some(
        (file) =>
          file.upload.status !== FileUploadStatus.success ||
          file.upload.status !== FileUploadStatus.inProgress,
      )
    ) {
      setLoading(false);
      setBannerState({
        show: true,
        type: BannerType.error,
        message:
          "Some of the dropped files haven't been uploaded. Cannot submit.",
      });
    } else {
      const uploadPromises = files
        .filter((file) => file.upload.status === FileUploadStatus.inProgress)
        .map((file) => file.upload.promise);

      Promise.all(uploadPromises).then(
        async () => {
          // on success
          await submitForm();
        },
        () => {
          // on error
          setLoading(false);
        },
      );
    }
  };

  const handleClose = () => {
    setConfirmationDialogOpen(false);
  };

  const onMailRead = (event, file) => {
    const messageData = parseMessage(event.target.result, file);
    setFiles((prevFiles) => [...prevFiles, messageData]);
  };

  const onDocumentRead = (event, file) => {
    const documentData = initDocument(event.target.result, file);
    setFiles((prevFiles) => [...prevFiles, documentData]);
  };

  const onFileDrop = (event) => {
    for (let index in event.dataTransfer.files) {
      if (index && event.dataTransfer.files.hasOwnProperty(index)) {
        const file = event.dataTransfer.files[index];
        const reader = new FileReader();
        const fileReadHandler = file.name.endsWith('.msg')
          ? onMailRead
          : onDocumentRead;
        reader.onload = (event) => fileReadHandler(event, file);
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const clearAttachments = () => {
    setFiles([]);
    setValidationState((prevState) => ({
      ...prevState,
      files: { dropped: false },
      valid: false,
    }));
  };

  const clearForm = () => {
    setFormState((previousState) => ({
      ...initialFormState,
      underwriterEmail: previousState.underwriterEmail,
    }));

    setFiles([]);
    setValidationState(initialValidationState);
  };

  const changeDocumentType = async newDocumentType => {
    let totalNumberOfPolicyReferences = 1;

    let result = validateRequiredField("Type Of Document", newDocumentType);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(newDocumentType, formState.placingBasis, formState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }

    setFormState((prevState) => ({
      ...formState,
      ...prevState,
      documentType: newDocumentType,
      totalNumberOfPolicyReferences: totalNumberOfPolicyReferences,
    }));
  };

  const tryLoadProductPlacingBases = async (documentType, majorClass) => {
    if (!!majorClass) {
      let placingBasesResponse = {};
      setLoading(true);

      placingBasesResponse = await fetchProductPlacingBases(formState.businessEntity, majorClass, documentType);

      if (!placingBasesResponse.success) {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: placingBasesResponse.errorMessage
        });

        return;
      }

      setDropdownsData(prevState => ({
        ...prevState,
        productPlacingBases: placingBasesResponse.data ?? []
      }));

      let uwGroupMembers = await getUWGroupMembers(formState.businessEntity);
      setDropdownsData(prevState => ({
        ...prevState,
        assignedUWs: uwGroupMembers
      }));

      setLoading(false);
    }
  }

  const submitForm = async () => {
    const filesToSubmit = [];
    files.forEach((data) => {
      if (data.type === FileType.document) {
        filesToSubmit.push({ name: data.file.name, path: data.upload.path });
      } else if (data.type === FileType.message) {
        filesToSubmit.push({
          name: data.file.name,
          path: data.upload.path,
          body: data.body,
          title: data.title,
          sender: data.sender,
          attachments: data.attachments,
        });
      }
    });

    let producingTeam = formState.producingTeam;

    let {
      masterPolicyReference,
      secondMasterPolicyReference,
      expiringPolicyReference,
      secondExpiringPolicyReference,
    } = {
      masterPolicyReference: isMasterPolicyReferenceNumberEnabled(
        formState.documentTypesList,
        dropdownsData,
        formState.documentType,
        formState.placingBasis,
      )
        ? formState.masterPolicyReferenceNumber
        : '',
      secondMasterPolicyReference: isMasterPolicyReferenceNumberEnabled(
        formState.documentTypesList,
        dropdownsData,
        formState.documentType,
        formState.placingBasis,
      )
        ? formState.secondMasterPolicyReferenceNumber
        : '',
      expiringPolicyReference:'',
      secondExpiringPolicyReference: '',
    };

    let response = await submitFormData(
      {
        ...formState,
        producingTeam: producingTeam,
        masterPolicyReferenceNumber: masterPolicyReference,
        secondMasterPolicyReferenceNumber: secondMasterPolicyReference,
        expiringPolicyReferenceNumber: expiringPolicyReference,
        secondExpiringPolicyReferenceNumber: secondExpiringPolicyReference,
        totalNumberOfPolicyReferences: parseInt(formState.totalNumberOfPolicyReferences,),
        assured: {
          name: formState.assured,
          id: formState.assuredId,
          isNew: formState.isNewAssured,
        },
        assuredMiami: formState.assuredMiami,
        secondAssured: {
          name: formState.secondAssured,
          isNew: formState.isNewSecondAssured,
        },
        effectiveDate: getFormattedDate(formState.effectiveDate),
        quoteBy: getFormattedDate(formState.quoteBy),
        broker: formState.broker && {
          ...formState.broker,
          id: parseInt(formState.broker.id),
        },
        brokerContact: formState.brokerContact && {
          ...formState.brokerContact,
          id: parseInt(formState.brokerContact.id),
          relationshipId: parseInt(formState.brokerContact.relationshipId),
        },
        brokerBranch: formState.brokerBranch && {
          ...formState.brokerBranch,
          id: parseInt(formState.brokerBranch.id),
        },
        miamiCedant: formState.miamiCedant,
        miamiCedantCountry: formState.miamiCedantCountry,
        miamiCedantCountryCode: formState.miamiCedantCountryCode,
        preRenewal: false,

      },
      filesToSubmit,
    );


    if (response.success) {
      let msg = 'Submission is';

      clearForm();
      setBannerState({
        type: BannerType.success,
        message: msg + ' successfully started.',
        show: true,
      });
    } else {
      setBannerState({
        type: BannerType.error,
        message: response.errorMessage,
        show: true,
      });
    }

    setLoading(false);
  };




  const onSubmit = async () => {    
    let result = validateForm(formState, dropdownsData);
    setValidationState((_) => result);

    if (!result.valid) {
      setBannerState({
        show: true,
        type: BannerType.warning,
        message:
          'Form contains validation errors. Please provide valid information.',
      });
      return;
    }
    if (!formState.businessEntity.includes('Miami'))
    {
      setBannerState({
        show: true,
        type: BannerType.warning,
        message:
          'Form contains validation errors. Business Entity must be US MGA - Miami',
      });
      return;
    }

    if (files.length === 0) {
      setConfirmationDialogOpen(true);
    } else {
      handleSubmit();
    }
  };



  const onPolicyEntryInformationLength = (event) => {
    var result = validateTextField(
      'Policy Entry Information',
      event.target.value,
    );
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        policyEntryInformation: result,
      },
    });
  };


  const renderConfirmationDialog = () => {
    return (
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm that the form does not have any attachments
        </DialogTitle>
        <DialogActions
          className="dialog-buttons"
          style={{ justifyContent: 'center' }}
        >
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton onClick={handleSubmit}>Confirm</DialogButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderEntity = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.entity.valid}>
          <Dropdown
            id="entity"
            label="Business Entity"
            value={formState.businessEntity}
            data={dropdownsData.entitiesList}
            ref={dropdownRef}
            onChange={onEntityChanged}
            onBlur={onEntityBlur}
            errorText={validationState.fields.entity.message}
            readonly
          />
        </FormControl>
      </Grid>
    )
  }

  const renderMajorClasses = () => {
    return (
      <Grid item>
        <FormControl
          fullWidth
          required
          error={validationState.fields.majorClasses[formState.businessEntity]?.valid === false}>
          <Dropdown
            id="majorClass"
            ref={majorClassRef}
            label="Major Class"
            value={
              formState.majorClasses[formState.businessEntity]
                ? formState.majorClasses[formState.businessEntity]
                : ''}
            data={dropdownsData.majorClasses.map(d => d.name)}
            onChange={(event) => onMajorClassChanged(event)}
            onBlur={(event) => onMajorClassBlur(event, formState.businessEntity)}
            disabled={formState.businessEntity === ''}
            errorText={validationState.fields.majorClasses[formState.businessEntity]?.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderDocumentType = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.typeOfDocument.valid}>
          <Dropdown
            id="documentType"
            ref={documentTypeRef}
            label="Type Of Document"
            value={formState.documentType}
            data={formState.documentTypesList.map(x => x.name)}
            onChange={onDocumentTypeChanged}
            onBlur={onDocumentTypeBlur}
            errorText={validationState.fields.typeOfDocument.message}
            disabled={formState.businessEntity === '' || formState.firstMajorClass === ''}            
          />
        </FormControl>
      </Grid>
    );
  };
  
  const renderProductPlacingBasis = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.placingBasis.valid}>
          <Dropdown
            id="placingBasis"
            ref={placingBasisRef}
            label="Placing Basis"
            value={formState.placingBasis}
            data={dropdownsData.productPlacingBases.map(x => x.placingBasis)}
            disabled={formState.businessEntity === '' || formState.firstMajorClass === '' || formState.documentType === '' || !dropdownsData.productPlacingBases || dropdownsData.productPlacingBases?.length === 0}
            onChange={onProductPlacingBasisChanged}
            onBlur={onPlacingBasisBlur}
            errorText={validationState.fields.placingBasis.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderBrokerName = () => {
    return <Grid item>
      <BrokerAutocomplete
        fieldsState={formState}
        validationState={validationState}
        setFieldsState={setFormState}
        setValidationState={setValidationState}
        setBannerState={setBannerState}
      />
    </Grid>;
  };

  const renderBrokerBranch = () => {
    return <Grid item>
      <BrokerBranchAutocomplete
        fieldsState={formState}
        validationState={validationState}
        setFieldsState={setFormState}
        setValidationState={setValidationState}
        setBannerState={setBannerState}
      />
    </Grid>;
  };

  const renderBrokerContact = () => {
    return <Grid item>
      <BrokerContactAutocomplete
        fieldsState={formState}
        validationState={validationState}
        setFieldsState={setFormState}
        setValidationState={setValidationState}
        setBannerState={setBannerState}
      />
    </Grid>;
  };

  const renderAssignedUW = () => {
    return <Grid item>
      <FormControl fullWidth required error={!validationState.fields.assignedUW.valid}>
        <Dropdown
          id="assignedUW"
          ref={assignedUWRef}
          label="Assigned UW"
          value={formState.assignedUW}
          data={dropdownsData.assignedUWs.map(a => a.mail)}
          disabled={!dropdownsData?.assignedUWs || dropdownsData.assignedUWs.length === 0}
          onChange={onAssignedUWChanged}
          onBlur={onAssignedUWBlur}
          errorText={validationState.fields.assignedUW.message}
        />
      </FormControl>
    </Grid>;
  };

  const renderEffectiveDate = () => {
    return <Grid item>
      <DatePickerElement
        id="effectiveDate"
        label="Effective Date"
        required
        fullWidth
        format="MM/dd/yyyy"
        value={formState.effectiveDate}
        onChange={onEffectiveDateChanged}
        error={!validationState.fields.effectiveDate.valid}
        helperText={validationState.fields.effectiveDate.message}
      />
    </Grid>;
  }

  const renderYearOfAccount = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.yearOfAccount.valid}>
          <Dropdown
            id="yoa"
            ref={yoaRef}
            label="YOA"
            value={formState.yoa}
            data={dropdownsData.years}
            onChange={onYearOfAccountChanged}
            onBlur={onYearOfAccountBlur}
            disabled={formState.businessEntity === ''}
            errorText={validationState.fields.yearOfAccount.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderMiamiCedants = () => {
    return (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="miamiCedants"
            ref={miamiRef}
            label="Cedant"
            data={dropdownsData.miamiCedants.map(d => d.companyName + ' | ' + d.countryName)}
            value={formState.miamiCedantText ?? ""}
            onChange={onMiamiCedantChanged}
            disabled={formState.businessEntity === ''}
          />
        </FormControl>
      </Grid>
    )
  }

  const renderAssuredMiami = () => {    
    return (

      <Grid item>
        <TextField
          id="assignedMiami"
          label="Insured (Miami)"
          required
          fullWidth
          value={formState.assuredMiami ?? ""}
          onChange={onAssuredMiamiChanged}
        />
      </Grid>
    )
  }

  const renderForm = () => {

    if (errorMessage.length > 0) {
      return <ErrorPage message={errorMessage} />;
    }

    return (
      <div className="form-container">
        <>
          <h2>Entry form for Miami Clone Submission</h2>
          <Divider />
          <Banner
            type={bannerState.type}
            message={bannerState.message}
            show={bannerState.show}
            showBanner={(show) =>
              setBannerState({
                ...bannerState,
                show,
              })
            }
          />
        </>
        <Grid container className="form-section" direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
          <Grid item container xs={6} direction="column" spacing={3} className="form-fields-container">
            <Grid item>
              <TextField id="policyEntryInformation" label="Policy Entry Information" required fullWidth
                onChange={(e) => {
                  onPolicyEntryInformationChanged(e);
                  onPolicyEntryInformationLength(e);
                }}
                onBlur={onPolicyEntryInformationLength}
                value={formState.policyEntryInformation}
              />
            </Grid>
            {renderEntity()}
            {renderMajorClasses()}
            {renderDocumentType()}
            {renderProductPlacingBasis()}
            {renderBrokerName()}
            {renderBrokerBranch()}
            {renderBrokerContact()}
            {renderAssignedUW()}
            {renderEffectiveDate()}
            {renderYearOfAccount()}
            {renderMiamiCedants()}
            {renderAssuredMiami()}
          </Grid>
          <Grid item container xs={6} direction="column" spacing={3}>
            <Grid item>
              <DragAndDropArea text="Drag and Drop Email/Document" onFileDrop={onFileDrop}/>
            </Grid>
            <Grid item>
              <FormButton endIcon={<Clear />} onClick={clearAttachments} style={{ width: '100%', marginLeft: '1.2rem' }}>
                Clear Attachments
              </FormButton>
            </Grid>
            <Grid item>
              {files.length > 0 && (
                <FileSection
                  files={files}
                  setFiles={setFiles}
                  uploadFile={uploadFile}
                  setBannerState={setBannerState}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className="form-footer">
          <FormButton endIcon={<Clear />} onClick={clearForm}>
            Reset
          </FormButton>
          <FormButton endIcon={<Send />} onClick={onSubmit}>
            Submit
          </FormButton>
          {renderConfirmationDialog()}
        </div>        
        <Footer />
      </div>
    );
  }

  return renderForm();
};