import React from 'react';

import { OrganisationTypes, PlacingBases } from 'components/SubmissionWizard/Helpers';

import { PolicyReferenceControl } from './PolicyReferenceControl';

export const DefaultMasterPolicyReferenceControlName = "_masterAssuredOrMasterReassured"

export const MasterPolicyReferenceControl = ({
  entities,
  fields,
  control,
  errors,
  lookupType,
  forceClearDatasourceState,
  disabled,
  setBannerState,
  setLoading,
  setFormState,
  setValidationValue,
  abortController,
  setAbortController,
  inputRef
}) => {
  const getAssured = (_, assured) => {
    let masterAssuredData = {
      [DefaultMasterPolicyReferenceControlName]: assured?.assured ?? '',
      masterPolicyId: assured?.policyId ?? null,
      masterPolicyReferenceNumber: assured?.policyReferenceNumber ?? '',
      umr: assured?.umr ?? ''
    };

    if (assured?.assuredType.toLowerCase() === OrganisationTypes.Reassured) {
      masterAssuredData.masterReassured = assured?.assured;
      masterAssuredData.masterAssured = '';
    } else {
      masterAssuredData.masterReassured = '';
      masterAssuredData.masterAssured = assured?.assured;
    }

    return masterAssuredData;
  };
  
  const requestData = {
    businessEntities: entities,
    year: fields.yoa,
    placingBases: [
      PlacingBases.Binder,
      PlacingBases.BinderNonProportionalRI,
      PlacingBases.BinderProportionalRI,
      PlacingBases.ConsortiaNonProportionalRI,
      PlacingBases.ConsortiaProportionalRI,
      PlacingBases.LineslipNonProportionalRI,
      PlacingBases.LineslipProportionalRI,
      PlacingBases.LineSlip,
      PlacingBases.MasterBinder,
      PlacingBases.Consortium,
      PlacingBases.FacultativeCover,
      PlacingBases.ReportingReinsuranceMaster,
      PlacingBases.NonProportionalReportingReinsuranceMaster,
      PlacingBases.ProportionalReportingReinsuranceMaster
    ]
  };
  
  return (<PolicyReferenceControl
    controlName={DefaultMasterPolicyReferenceControlName}
    getValue={fieldData => fieldData[DefaultMasterPolicyReferenceControlName]}
    fields={fields}
    control={control}
    errors={errors}
    lookupType={lookupType}
    requestData={requestData}
    forceClearDatasourceState={forceClearDatasourceState}
    setBannerState={setBannerState}
    setLoading={setLoading}
    setFormState={setFormState}
    setValidationValue={setValidationValue}
    getAssuredData={getAssured}
    disabled={disabled}
    abortController={abortController}
    setAbortController={setAbortController}
    inputRef={inputRef}
  />);
}