"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parse = void 0;
var utils_1 = require("./utils");
/**
 * @internal
 */
function parse(ds) {
  // PidLidTimeZoneStruct Canonical Property
  // https://learn.microsoft.com/en-us/office/client-developer/outlook/mapi/pidlidtimezonestruct-canonical-property
  if (!ds.isEof()) {
    var lBias = ds.readInt32();
    var lStandardBias = ds.readInt32();
    var lDaylightBias = ds.readInt32();
    var wStandardYear = ds.readUint16();
    var stStandardDate = (0, utils_1.readTransitionSystemTime)(ds);
    var wDaylightYear = ds.readUint16();
    var stDaylightDate = (0, utils_1.readTransitionSystemTime)(ds);
    return Object.assign({}, {
      bias: lBias,
      standardBias: lStandardBias,
      daylightBias: lDaylightBias,
      standardYear: wStandardYear,
      standardDate: stStandardDate,
      daylightYear: wDaylightYear,
      daylightDate: stDaylightDate,
    });
  }
  return null;
}
exports.parse = parse;