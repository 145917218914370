import React, { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Divider, FormControl
} from '@material-ui/core';
import { DialogButton } from 'components/common';
import AutocompleteOrAdd from './AutocompleteOrAdd';
import { fetchEndorsmentsFolders } from 'components/RpaDropzones/RpaDropzones.Api';
import { parse } from 'query-string';
import { RpaMapper } from "../../Helpers";

export const SelectOrCreateFolderPopup = ({ popupState, setFormState, submit, submitArguments, formState, setLoading }) => {
    const location = useLocation();
  const [formValid, setFormValid] = useState(true);
  const [errorText, setErrorText] = useState('')

    const { folderName } = formState.popup.data;

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const queryParameters = parse(location.search);
            const formData = RpaMapper.mapQueryParametersToForm(queryParameters);
            formData.documentType = formState.droppedTile;
            const response = await fetchEndorsmentsFolders(formData);

            setLoading(false);
            setFormState(state => ({
                ...state,
                popup: { ...state.popup, show: true, folders: response.data, data: {} }
            }));
        }

        fetchData();
    }, []);

    const handleClose = useCallback(() => {
        setFormState(state => ({
            ...state,
            popup: { ...state.popup, show: false, data: {} },
            droppedTile: ''
        }));
    }, [setFormState]);

    const validate = () => {
        if (!folderName) {
            setFormValid(false);
            setErrorText('Required');
            return false;
        }
        if (folderName.length > 30)
        {
            setFormValid(false);
            setErrorText('Folder should be less then 30 symbols');
            return false;
        }
        const specialCharsRegex = /[\\\/:\*\?"<>|]/;
        if (specialCharsRegex.test(folderName)) {
            setFormValid(false);
            setErrorText('Forbidden Symbols:  \ / : * ? " < > | ');
            return false;
        }        
        setFormValid(true);
        setErrorText('')
        return true;
      }

    useEffect(() => {
        if (folderName !== null && folderName !== undefined) {
            validate();
        }
    }, [folderName]);

    const handleChange = useCallback((folder) => {
        setFormState(state => ({
            ...state,
            popup: {
                ...state.popup,
                data: { folderName: folder }
            }
        }))
    }, [setFormState]);

    const handleSubmit = useCallback(() => {
        if (!validate()) {
            return;
        }
        submit(submitArguments);
        setFormState(state => ({
            ...state,
            popup: { ...state.popup, show: false }
        }));
    }, [setFormState, submit, submitArguments]);

    return (
        <Dialog open={popupState?.show} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{formState.droppedTile}</DialogTitle>
            <Divider style={{ backgroundColor: "#1FB6AD", padding: "0.04rem" }} />
            <DialogContent style={{ padding: 30, overflow: 'hidden' }}>
                <FormControl style={{ width: '100%' }}>
                    <AutocompleteOrAdd
                        label="Select Folder or Create New"
                        helperText={formValid ? '' : errorText}
                        error={!formValid}
                        options={formState.popup.folders}
                        onChange={handleChange}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions className="dialog-buttons">
                <DialogButton onClick={handleClose}>Cancel</DialogButton>
                <DialogButton onClick={handleSubmit}>Confirm</DialogButton>
            </DialogActions>
        </Dialog>
    )
}