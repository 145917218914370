import React, { useMemo } from 'react';
import { InputLabel, FormControl, FormHelperText, Select, Checkbox, ListItemText, MenuItem, makeStyles } from '@material-ui/core';
import { CheckboxElement } from 'components/common';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export const MultiSelectDropdown = ({ errorText, data, onChange, disabled, defaultValues  }) => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = React.useState([]);

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    onChange(event.target.value);
  };

  const defaultValuesChange = useMemo(() => {
    if (defaultValues) {
      setSelectedValues(defaultValues);
    }
    
  }, [defaultValues])

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <InputLabel id="demo-mutiple-checkbox-label">Select Folders</InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple        
        value={selectedValues}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        error={errorText!==''}        
      >
        {data.map((name) => (
          <MenuItem key={name} value={name}>
            <CheckboxElement checked={selectedValues.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={errorText !== ''}>{disabled ? 'disabled' : errorText}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelectDropdown;
