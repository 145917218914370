import React, { useEffect } from 'react';
import { Select, InputLabel, MenuItem, FormHelperText, Checkbox, ListItemText } from '@material-ui/core';

export const MultiDropdown = props => {  
  useEffect(() => {
  if (!props.shouldSetDefaultValue || props.emptyValue) return;
    if (props.data.length === 1 && props.value !== props.data[0]) {
      props.onChange(
        {
        target: { name: props.name, value: props.data[0] }
      });
    }
    
  }, [props, props.data]); 

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const denseStyle = {
    padding: "0px"
  };
  const cbColor = {
    color: "#1FB6AD"
  };

  return <>
    {!props.hideLabel && <InputLabel id={props.label} shrink={props.emptyValue}>{props.label}</InputLabel>}
    <Select
      labelId={props.label}
      id={props.id}
      multiple
      name={props.name}
      value={personName}
      onChange={e => { handleChange(e); props.onChange(e) }}
      onBlur={props.onBlur}
      disabled={props.disabled}
      data-testid={props.testId}
      displayEmpty={props.emptyValue}
      renderValue={(selected) => selected.join(', ')}
      inputRef={props.inputRef}
      {...props.field}
    >
      {props.emptyValue && <MenuItem key={-1} value=""><em>None</em></MenuItem>}
      {
        props.data.map((row, index) => {
          return (
            <MenuItem style={denseStyle} key={index} value={row} disabled={personName.indexOf(row) < 0 && personName.length >= 2}>
              <Checkbox checked={personName.indexOf(row) > -1} style={cbColor} />
              <ListItemText primary={row} />
            </MenuItem>
          );
        })
      }
    </Select>
    <FormHelperText>{props.errorText}</FormHelperText>
  </>;
};