import React from 'react';

import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles({
  default: () => ({
    color: "#1FB6AD",
    borderColor: "#1FB6AD",
    margin: "0rem 0.5rem 0rem 0.5rem"
  }),
  custom: props => ({
    ...props,
    '&:hover': { ...props.hover }
  })
});

export const DialogButton = props => {
  const classes = useStyles(props.styles);

  return (
    <Button
      {...props}
      className={`${props.styles ? classes.custom : classes.default}`}
      variant="outlined"
    >
      {props.children}
    </Button>
  );
};