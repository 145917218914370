import React, { useCallback } from 'react';

export const InformationMessage = ({
  fieldName,
  fieldErrors,
  maxLength,
  showLengthInfo,
  length
}) => {
  const getMessage = useCallback(() => {
    if (fieldErrors?.type === 'required') {
      return <span role="alert">"{fieldName}" is required</span>;
    }
    
    if (fieldErrors?.type === 'maxLength') {
      return <span role="alert">"{fieldName}" maximum length is {maxLength} characters</span>;
    }

    return showLengthInfo && length > 0 && `Length is ${length}/${maxLength} characters`;
  }, [fieldErrors, fieldName, length, maxLength, showLengthInfo]);

  return <>{getMessage()}</>;
}