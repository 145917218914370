import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { BannerType, InformationBanner, InfoRoundIcon } from './common';
import packageJson from '../../package.json';

export const ApplicationUpdater = props => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  useEffect(() => {
    const refreshInterval = 180 * 1000; // 3 minutes

    let intervalId = setInterval(async () => {
      var headers = {};
      headers["Cache-Control"] = "no-cache";

      var metadata = await axios.get("meta.json", {
        headers: headers
      });

      var newVersion = metadata.data.version;
      var currentVersion = packageJson.config.applicationVersion;

      if (newVersion !== currentVersion) {
        setIsUpdateAvailable(true);
        clearInterval(intervalId);
      }
    }, refreshInterval);
  }, []);

  const refreshApplication = async () => {
    if (caches) {
      var cacheRecords = await caches.keys();
      cacheRecords.forEach(record => {
        caches.delete(record);
      });
    }

    window.location.reload(true);
  };

  return isUpdateAvailable &&
    <InformationBanner
      onClick={refreshApplication}
      severity={BannerType.info}
      variant="outlined"
      icon={<InfoRoundIcon />}
    >
      New application version is available. Click on the banner to refresh.
    </InformationBanner>;
};