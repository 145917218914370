import React, { useMemo } from 'react';

import { DocumentTypes } from 'components/SubmissionWizard/Helpers';

import SubmissionSection from "./SubmissionSection";
import CancellationsSection from './CancellationsSection';
import EndorsementsSection from './EndorsementsSection';
import BordereauxBookingSection from './BordereauxBookingSection';
import EpiUpdateSection from './EpiUpdateSection';
import FirmOrderSection from './FirmOrderSection';
import ModellingSubmissionSection from './ModellingSubmissionSection';
import ModellingInfoSection from './ModellingInfoSection';
import QCErrorSection from './QCErrorSection';
import PeerReviewOnlySection from './PeerReviewOnlySection';
import BulkItemSection from './BulkItemSection';
import AccountsReceivableSection from './AccountsReceivableSection';

export const SectionsFactory = props => {
  const submissionSection = useMemo(() => <SubmissionSection {...props} />, [props]);
  const cancellationsSection = useMemo(() => <CancellationsSection {...props} />, [props]);
  const endorsementsSection = useMemo(() => <EndorsementsSection {...props} />, [props]);
  const bordereauxBookingSection = useMemo(() => <BordereauxBookingSection {...props} />, [props]);
  const epiUpdateSection = useMemo(() => <EpiUpdateSection {...props} />, [props]);
  const modellingSubmissionSection = useMemo(() => <ModellingSubmissionSection {...props} />, [props]);
  const modellingInfonSection = useMemo(() => <ModellingInfoSection {...props} />, [props]);
  const qcErrorSection = useMemo(() => <QCErrorSection {...props} />, [props]);
  const peerReviewOnlySection = useMemo(() => <PeerReviewOnlySection {...props} />, [props]);
  const firmOrderSection = useMemo(() => <FirmOrderSection {...props} />, [props]);
  const bulkItemSection = useMemo(() => <BulkItemSection {...props} />, [props]);
  const accountsReceivableSection = useMemo(() => <AccountsReceivableSection {...props} />, [props]);

  const getSection = () => {
    switch (props.fields.documentType) {
      case DocumentTypes.Submissions:
      case DocumentTypes.FirmOrderOnly:
      case DocumentTypes.Quote:
        return submissionSection;
      case DocumentTypes.Cancellations:
        return cancellationsSection;
      case DocumentTypes.AccountsReceivable:
        return accountsReceivableSection;
      case DocumentTypes.Endorsements:
        return endorsementsSection;
      case DocumentTypes.BordereauxBooking:
        return bordereauxBookingSection;
      case DocumentTypes.EpiUpdate:
        return epiUpdateSection;
      case DocumentTypes.FirmOrder:
        return firmOrderSection;
      case DocumentTypes.ModellingSubmission:
        return modellingSubmissionSection;
      case DocumentTypes.ModellingInfo:
        return modellingInfonSection;
      case DocumentTypes.QCError:
        return qcErrorSection;
      case DocumentTypes.PeerReviewOnly:
        return peerReviewOnlySection;
      case DocumentTypes.BulkItem:
        return bulkItemSection;
      default:
        return <></>;
    }
  };

  return getSection();
}