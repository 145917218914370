import React, { useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, Divider, TextField, Checkbox, FormControlLabel
} from '@material-ui/core';

import { DialogButton, Dropdown } from 'components/common';
import { BannerType } from 'components/common/Banner';
import { fetchDeclinedReasons, fetchNonRevewalReasons } from "components/RpaDropzones/RpaDropzones.Api";

import '../Popup.css';

const DeclineFieldNames = {
  DeclinedReason: 'Declined Reason',
  DeclinedReasonNotes: 'Declined Reason Notes',
  NonRenewable: 'Non-Renewable',
  NonRenewalReason: 'Non-Renewal Reason',
  NonRenewalReasonNotes: 'Non-Renewal Reason Notes',
};

const FieldsMaxLength = {
  [DeclineFieldNames.DeclinedReasonNotes]: 255,
  [DeclineFieldNames.NonRenewalReasonNotes]: 255,
};

export const DeclinePopup = ({ popupState, setFormState, submit, submitArguments, setLoading, setBannerState }) => {
  const { control, handleSubmit, formState: { errors }, watch } = useForm({ mode: "onTouched" });

  const nonRenewable = watch('nonRenewable');
  const nonRenewalReason = watch('nonRenewalReason');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const declinedReasonsRequest = fetchDeclinedReasons();
      const nonRenewalReasonsRequest = fetchNonRevewalReasons();
      const declineReaonsResponse = await declinedReasonsRequest;
      const nonRenewalReasonsResponse = await nonRenewalReasonsRequest;
      setLoading(false);

      if (!declineReaonsResponse.success || !nonRenewalReasonsResponse.success) {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: declineReaonsResponse.errorMessage || nonRenewalReasonsResponse.errorMessage
        });

        return;
      }

      setFormState(state => ({
        ...state,
        popup: { ...state.popup, show: true, data: { 
          declinedReasons: declineReaonsResponse.data.declinedReasons,
          nonRenewalReasons: nonRenewalReasonsResponse.data.nonRenewalReasons
        }}
      }));
    }

    fetchData();
  }, []);

  const handleClose = useCallback(() => {
    setFormState(state => ({
      ...state,
      popup: { ...state.popup, show: false },
      droppedTile: ''
    }));
  }, [setFormState]);

  const onSubmit = useCallback(data => {
    setFormState(state => ({
      ...state,
      popup: {
        data,
        show: false
      }
    }));

    submit({ ...submitArguments });
  }, [setFormState, submit, submitArguments]);

  const getDeclinedReasonValidationError = () => {
    let required = errors.declinedReason?.type === 'required' &&
      <span role="alert">'{DeclineFieldNames.DeclinedReason}' is required</span>;

    return required;
  }

  const getDeclinedReasonNotesValidationError = () => {
    let maxLength = errors.declinedReasonNotes?.type === 'maxLength' &&
      <span role="alert">'{DeclineFieldNames.DeclinedReasonNotes}' cannot be longer than {FieldsMaxLength[DeclineFieldNames.DeclinedReasonNotes]} characters</span>;

    return maxLength;
  }

  return (
    <Dialog open={popupState?.show} onClose={handleClose} aria-labelledby="decline-popup-title">
      <form onSubmit={handleSubmit(onSubmit)} className="popup-form">
        <DialogTitle id="decline-popup-title">Decline</DialogTitle>
        <Divider style={{ backgroundColor: "#1FB6AD", padding: "0.04rem" }} />
        <DialogContent>
          <Controller
            name="declinedReason"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) =>
              <FormControl fullWidth error={!!errors.declinedReason}>
                <Dropdown
                  id="declinedReason"
                  name="declinedReason"
                  label={DeclineFieldNames.DeclinedReason}
                  data={popupState.data.declinedReasons ?? []}
                  field={field}
                  errorText={getDeclinedReasonValidationError()}
                />
              </FormControl>
            }
          />
          <Controller
            name="declinedReasonNotes"
            control={control}
            defaultValue=""
            rules={{ maxLength: FieldsMaxLength[DeclineFieldNames.DeclinedReasonNotes] }}
            render={({ field: { ref, ...rest } }) =>
              <TextField
                id="declinedReasonNotes"
                label={DeclineFieldNames.DeclinedReasonNotes}
                name="declinedReasonNotes"
                multiline
                fullWidth
                error={!!errors.declinedReasonNotes}
                helperText={getDeclinedReasonNotesValidationError()}
                {...rest}
              />
            }
          />
          <Controller
            name="nonRenewable"
            control={control}
            defaultValue={false}
            render={({ field }) =>
              <FormControlLabel
                name="nonRenewable"
                label={DeclineFieldNames.NonRenewable}
                style={{ marginTop: 20 }}
                control={<Checkbox
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />}
              />
            }
          />
          {nonRenewable && <Controller
            name="nonRenewalReason"
            control={control}
            defaultValue=""
            rules={{ required: `'${DeclineFieldNames.NonRenewalReason}' is required` }}
            render={({ field }) =>
              <FormControl fullWidth error={!!errors.nonRenewalReason}>
                <Dropdown
                  name="nonRenewalReason"
                  label={DeclineFieldNames.NonRenewalReason}
                  data={[...popupState.data.nonRenewalReasons ?? []]}
                  field={field}
                  errorText={errors.nonRenewalReason?.message}
                />
              </FormControl>
            }
          />}
          {nonRenewalReason === 'Other' && <Controller
            name="nonRenewalReasonNotes"
            control={control}
            defaultValue=""
            rules={{ maxLength: {
              value: FieldsMaxLength[DeclineFieldNames.NonRenewalReasonNotes],
              message: `${DeclineFieldNames.NonRenewalReasonNotes} cannot be longer than ${FieldsMaxLength[DeclineFieldNames.DeclinedReasonNotes]} characters`
            }}}
            render={({ field: { ref, ...rest } }) =>
              <TextField
                label={DeclineFieldNames.NonRenewalReasonNotes}
                name="nonRenewalReasonNotes"
                multiline
                fullWidth
                error={!!errors.nonRenewalReasonNotes}
                helperText={errors.nonRenewalReasonNotes?.message}
                {...rest}
              />
            }
          />}
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="submit">Confirm</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}