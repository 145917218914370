const privateFieldMarker = "_";

export let cleanseRequest = request => {
  let newRequest = { ...request };

  for (let field in newRequest) {
    if (newRequest.hasOwnProperty(field) && field.startsWith(privateFieldMarker)) {
      delete newRequest[field];
    }
  }

  return newRequest;
}