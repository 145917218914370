import React, { useEffect, useState } from 'react';

import { loadConfig } from 'config';

import './Footer.css';

export const Footer = () => {
  const [version, setVersion] = useState("");
  const [environment, setEnvironment] = useState("");

  useEffect(() => {
    const getApplicationVersion = async () => {
      var config = await loadConfig();
      setVersion(config.version);
      setEnvironment(config.environment)
    };

    getApplicationVersion();
  }, []);

  const renderEnvironmentIndicator = () => {
    if (environment !== "Production") {
      return `Environment: ${environment.toLowerCase()}`;
    }
    return "";
  };
  return <div>
    <div className="footer">
      Copyright © {new Date().getFullYear()} Hamilton Insurance Group {version} {renderEnvironmentIndicator()}
    </div>
  </div>;
};