import React, { useEffect, useCallback } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Divider, Checkbox, FormControlLabel, Tooltip
} from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { DialogButton, CheckboxElement } from 'components/common';
import { fetchCurrentMtg } from "components/RpaDropzones/RpaDropzones.Api";
import '../Popup.css';

export const DeclineRelatedMtgPopup = ({ mTGRelatedPopup, popupState, setFormState, submit, submitArguments, formState }) => {

  useEffect(() => {
    async function fetchData() {

      let mTGRelatedData = await fetchCurrentMtg(mTGRelatedPopup.id);
      let relatedOpprotunities = mTGRelatedData.data.map(x => {
        let displayName = `${x.mtg} ${x.name} ${x.closeDate}`;
        let lockedMessage = "The deal is locked, current stage: " + x.stageName;
        return { ...x, displayName: displayName, IsDeclined: x.isCurrent, lockedMessage: lockedMessage };
      });

      setFormState(state => ({
        ...state,
        popup: {
          ...state.popup, show: true
        },
        mTGRelatedPopup: {
          ...state.mTGRelatedPopup,
          relatedOpprotunitiesToDecline: relatedOpprotunities
        }
      }));

    }
    fetchData();
  }, []);

  const handleClose = useCallback(() => {
    setFormState(state => ({
      ...state,
      popup: { ...state.popup, show: false },
      droppedTile: '',
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        relatedOpprotunitiesToDecline: []
      }
    }));
  }, [setFormState]);

  const handleChange = useCallback(({ target }) => {
    setFormState(state => {
      const mtg = state.mTGRelatedPopup.relatedOpprotunitiesToDecline.find(f => f.mtg === target.value);
      if (mtg) {
        return {
          ...state,
          mTGRelatedPopup: {
            ...state.mTGRelatedPopup,
            relatedOpprotunitiesToDecline: state.mTGRelatedPopup.relatedOpprotunitiesToDecline.map(x => {
              if (x.mtg === target.value) {
                return {
                  ...x,
                  IsDeclined: target.checked
                }
              }
              return x;
            })
          }          
        }
      }
    });

  }, [setFormState]);

  const handleSubmit = useCallback(() => {

    if (mTGRelatedPopup.relatedOpprotunitiesToDecline.some(mtg => mtg.IsDeclined)) {
      setFormState(state => ({
        ...state,
        declineRelatedMtgSelected: true
      }));
    }    
    
  }, [setFormState, submit, submitArguments]);

  return (
    <Dialog open={popupState?.show} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Decline Related MTG</DialogTitle>
      <Divider style={{ backgroundColor: "#1FB6AD", padding: "0.04rem" }} />
      <DialogContent style={{ padding: 30, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        {mTGRelatedPopup.relatedOpprotunitiesToDecline?.map((data) => (
          <div key={data?.displayName}>
            <FormControlLabel
              style={{ display: 'flex', flexDirection: 'row' }}
              value={data?.mtg}
              label={data?.displayName}
              labelPlacement="start"
              control={data.isLocked
                ? <Tooltip title={data.lockedMessage} placement="top-start">
                    <Lock className="lock-icon-disabled" style={{ padding: "9px" }} ></Lock>
                  </Tooltip>
                : <CheckboxElement onChange={handleChange} checked={data?.IsDeclined} />}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions className="dialog-buttons">
        <DialogButton onClick={handleClose}>Cancel</DialogButton>
        <DialogButton onClick={handleSubmit} disabled={!mTGRelatedPopup.relatedOpprotunitiesToDecline.some(x => x.IsDeclined)} >Confirm</DialogButton>
      </DialogActions>
    </Dialog>
  );
}