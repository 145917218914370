import { QueryParametersConstants } from "./QueryParameters";
import { getFormattedDate } from 'components/SubmissionForm/Helpers';

const QuoteMapper = {
  mapQueryParametersToForm: parameters => {
    let policyNoParameter = QueryParametersConstants.PolicyNo;
    let kissflowIdParameter = QueryParametersConstants.KissflowId;
    let brokerIdParameter = QueryParametersConstants.BrokerId;
    let brokerBranchIdParameter = QueryParametersConstants.BrokerBranchId;
    let brokerContactIdParameter = QueryParametersConstants.BrokerContactId;
    let insuredParameter = QueryParametersConstants.Insured;
    let addressLine1Parameter = QueryParametersConstants.AdressLine1;
    let addressLine2Parameter = QueryParametersConstants.AdressLine2;
    let addressLine3Parameter = QueryParametersConstants.AdressLine3;
    let cityParameter = QueryParametersConstants.City;
    let stateParameter = QueryParametersConstants.State;
    let zipCodeParameter = QueryParametersConstants.ZipCode;
    let effectiveDateParameter = QueryParametersConstants.EffectiveDate;
    let processFlowParameter = QueryParametersConstants.ProcessFlow;
    let productIdParameter = QueryParametersConstants.ProductId;
  
    

    return {
      policyNo: searchParameter(parameters, policyNoParameter),
      kissflowId: searchParameter(parameters, kissflowIdParameter),
      brokerId: searchParameter(parameters, brokerIdParameter),
      brokerBranchId: searchParameter(parameters, brokerBranchIdParameter),
      brokerContactId: searchParameter(parameters, brokerContactIdParameter),
      insured: searchParameter(parameters, insuredParameter),
      addressLine1: searchParameter(parameters, addressLine1Parameter),
      addressLine2: searchParameter(parameters, addressLine2Parameter),
      addressLine3: searchParameter(parameters, addressLine3Parameter),
      city: searchParameter(parameters, cityParameter),
      state: searchParameter(parameters, stateParameter),
      zipCode: searchParameter(parameters, zipCodeParameter),
      effectiveDate: searchParameter(parameters, effectiveDateParameter),
      processFlow: searchParameter(parameters, processFlowParameter),
      productId: searchParameter(parameters, productIdParameter)
    };
  },
  mapFormToRequest: (data) => {
    let formData = new FormData();

    formData.append("policyNumber", data.policyNo ?? "");
    formData.append("policyId", data.policyId ?? 0);
    formData.append("effectiveDate", getFormattedDate(data.effectiveDate));
    formData.append("brokerContactName", data.brokerContact.name ?? "");
    formData.append("brokerBranchName", data.brokerBranch.name ?? "");
    formData.append("brokerName", data.broker.name ?? "");
    formData.append("kissflowId", data.kissflowId ?? "");
    formData.append("productId", data.product.productId ?? 0);
    formData.append("companyId", data.companyId ?? 0);
    formData.append("customerId", data.customerId ?? 0);
    formData.append("addressLine1", data.insuredAddressLine1 ?? "");
    formData.append("addressLine2", data.insuredAddressLine2 ?? "");
    formData.append("addressLine3", data.insuredAddressLine3 ?? "");
    formData.append("city", data.insuredCity ?? "");
    formData.append("state", data.insuredState ?? "");
    formData.append("zipCode", data.insuredZipCode ?? "");
    formData.append("processId", data.processFlow ?? "");
    formData.append("companyName", data.companyName ?? "");
    formData.append("brokerContactEmail", data.brokerContactEmail ?? "");

    return formData;
  }
}

const searchParameter = (object, key) => {
  return object[Object.keys(object)
    .find(k => k.toLowerCase() === key.toLowerCase())
  ];
}

export default QuoteMapper;