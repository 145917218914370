import React from "react";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core";

const defaultColors = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#1FB6AD"
      }
    },
    MuiPickersYear: {
      yearSelected: {
        color: "#1FB6AD"
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#1FB6AD"
      },
      current: {
        color: "#1FB6AD"
      }
    }
  }
});

export const DatePickerElement = (props) => {
  let marginProp = "";
  if (!props.disableMargin) {
    marginProp = "normal";
  }
  
  return <ThemeProvider theme={defaultColors}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        variant="inline"
        margin={marginProp}
        autoOk
        className="MuiDatepicker"
        InputProps={{
          ...props.InputProps,
          inputRef: props.inputRef
        }}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
};
