import React, { useEffect, useState } from 'react';

import { withStyles, Button } from '@material-ui/core';
import { ExitToApp, SupervisedUserCircleRounded } from '@material-ui/icons';

import { getAuthorizationProvider } from 'Authentication';
import './Navigation.css';

const SignOutButton = withStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    color: "#1FB6AD",
    height: "100%",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: "#1FB6AD",
      color: "#ffffff"
    }
  }
}))(Button);

const SignOutSmallButton = withStyles(() => ({
  root: {
    textTransform: "none",
    color: "#7f6895",
  },
}))(Button);

export const UserInfo = ({ shortVersion = false, fixedPosition = false }) => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    var loadUsername = async () => {
      var provider = await getAuthorizationProvider();
      setUsername(provider.getAccount().name);
    };

    loadUsername();
  }, [setUsername]);

  const onSignOutClick = async () => {
    var provider = await getAuthorizationProvider();
    provider.logout();
  };

  return shortVersion ? (
    <div
      className={`user-info-container ${
        fixedPosition ? 'user-info-fixed' : ''
      }`}
    >
      <SignOutSmallButton endIcon={<ExitToApp />} onClick={onSignOutClick}>
        {username}
      </SignOutSmallButton>
    </div>
  ) : (
    <div className="user-info-container">
      <div className="user-name">
        <SupervisedUserCircleRounded className="user-icon" />
        {username}
      </div>
      <SignOutButton endIcon={<ExitToApp />} onClick={onSignOutClick}>
        Sign Out
      </SignOutButton>
    </div>
  );
};