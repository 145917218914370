import { authorizedApiClient } from "Api";

export const Datasource = {
  assuredWithMajorClass: "assured/withMajorClass",
  assuredNames: "assured/names",
  expiringPolicies: "assured/expiringPolicies",
  programmes: "policy/programmes",
  majorClass: "classesOfBusiness/majorClasses",
  brokers: "brokers",
  brokerContacts: "brokers/contacts",
  underwritersData: "underwriters/data",
  operatingTerritory: "operatingTerritory",
  eplacement: "eplacement",
  obligor: "obligor",
  countries: "countries",
  organisations: {
    assured: "organisations/assured",
    reassured: "organisations/reassured",
    coverholder: "organisations/coverholder"
  }
}

export const fetchPickerRows = async (datasource, request, area, config) => {
  let url;
  if (area) {
    url = `api/${area}/${datasource}/search`;
  } else {
    url = `api/${datasource}`;
  }

  try {
    let response = await authorizedApiClient.post(url, request, config);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.name === "CanceledError") {
      return {
        canceled: true
      };
    }

    return {
      success: false,
      errorMessage: "Error occurred during the picker data loading. Please, try again later."
    };
  }
}