import React, { useCallback } from 'react';
import { useStarAiContext } from 'star-ai'
import { Grid, TextField } from '@material-ui/core';

import { AssuredAndPolicyReferenceControl } from './common';
import { createMajorClassStructure, GeneralDetailsFields } from 'components/SubmissionWizard/Helpers';

import '../GeneralDetails.css';

const QCErrorSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  modellingMajorClasses
}) => {

  // star ai
  const ai = useStarAiContext();

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        majorClass: majorClass
      }
    }));
  }, [setFormState]);

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
      additionalRequestData={{ businessEntities: dropdownsData.entitiesForFilter }}
      inputRef={ai?.fieldsRef?.[lookupType]}  
    />)
  }

  const renderAssured = () => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = () => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.policyReferenceNumber}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  return <>
    {renderLookupForAssuredAndPolicyReference()}
    {renderPolicyReferenceNumber()}
    {renderAssured()}
  </>;
}

export default QCErrorSection;