import React from 'react';

import { FileType } from 'Helpers';
import DocumentFile from './DocumentFile';
import MessageFile from './MessageFile';
import './FileSection.css';

export const FileSection = props => {
  return (
    <div className="mails-info-container">
      {
        props.files.map(file => file.type === FileType.message
          ? <MessageFile key={file.id} mail={file} {...props} />
          : <DocumentFile key={file.id} document={file} {...props} />
        )
      }
    </div>
  );
};
