import React, { useEffect, useCallback } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import Button from "@material-ui/core/Button";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { fetchCurrentMtg, updateMTG } from 'components/RpaDropzones/RpaDropzones.Api';
import { BannerType } from '../../../common';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export const MTGComponent = ({ mTGRelatedPopup, setFormState, setLoading, setBannerState, formState, allowUpdate, filterFromCurrent }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const relationStatus = "Primary";

  useEffect(async () => {
    let mTGRelatedData = await fetchCurrentMtg(mTGRelatedPopup.id);

    let relatedOpprotunities = mTGRelatedData.data.map(x => {
      let displayName = `${x.mtg} ${x.name} ${x.closeDate}`;
      return { ...x, displayName: displayName };
    })

    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        originalOpportunity: relatedOpprotunities,
        relatedOpprotunities: relatedOpprotunities
      }
    }));
  }, []);

  const handleUpdateMtg = useCallback(async () => {
    setLoading(true);
    let original = mTGRelatedPopup.originalOpportunity.map(x => x.id);
    let related = mTGRelatedPopup.relatedOpprotunities.map(x => x.id);
    let formData = formState.data;

    setFormState(previousState => ({
      ...previousState,
      mTGRelatedPopup: {
        ...previousState.mTGRelatedPopup,
        show: false
      }
    }));

    let response = await updateMTG(original, related, formData);
    setLoading(false);

    if (response.success) {
      setBannerState({
        show: true,
        type: BannerType.success,
        message: `Update Related MTGs successfully completed.`
      });
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: `An error occured during update of Related MTGs.`
      });
    }

  }, [mTGRelatedPopup]);

  const handleRemoveAllMTGsClick = useCallback(() => {
    let removedMtgs = mTGRelatedPopup.relatedOpprotunities.map(x => { x.relationStatus = ''; return x; });
    let candidatesToShow = [...mTGRelatedPopup.candidatesToShow, ...removedMtgs];

    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        relatedOpprotunities: [],
        candidatesToShow: filterFromCurrent(candidatesToShow)
      }
    }));
  }, [setFormState, mTGRelatedPopup]);

  const handleRemoveMTGClick = useCallback(id => {
    let relatedOpprotunities = mTGRelatedPopup.relatedOpprotunities.filter(x => x.id !== id);
    let candidateToShow = mTGRelatedPopup.relatedOpprotunities.find(x => x.id === id);
    candidateToShow.relationStatus = '';
    let candidatesToShow = [...mTGRelatedPopup.candidatesToShow, candidateToShow];


    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        relatedOpprotunities: relatedOpprotunities,
        candidatesToShow: filterFromCurrent(candidatesToShow)
      }
    }));
  }, [setFormState, mTGRelatedPopup]);

  const handleRelatedMTGClick = useCallback(() => {
    let isSearch = !mTGRelatedPopup.isSearch;

    setFormState(state => ({
      ...state,
      mTGRelatedPopup: {
        ...state.mTGRelatedPopup,
        isSearch: isSearch
      }
    }));
  }, [setFormState, mTGRelatedPopup]);

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <div>
            <ListItem>
              <ListItemText primary="Related MTG" />
              <Button onClick={handleRelatedMTGClick} disabled={mTGRelatedPopup.disableChanges} >
                <AddCircleIcon style={{ color: mTGRelatedPopup.disableChanges ? '#bcbcbc' : '#20B2AA' }} />
              </Button>
            </ListItem>
          </div>
        }
        className={classes.root}
      >
        <ListItem>
          <ListItemIcon>
            <StarBorder style={{ color: '#FFD700' }} />
          </ListItemIcon>
          <ListItemText primary={mTGRelatedPopup.relatedOpprotunities.find(x => x.relationStatus === relationStatus)?.displayName} />
          <Button onClick={handleRemoveAllMTGsClick} disabled={mTGRelatedPopup.disableChanges} >
            <RemoveCircleIcon style={{ color: mTGRelatedPopup.disableChanges ? '#bcbcbc' : '#DC143C' }} />
          </Button>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {
            mTGRelatedPopup.relatedOpprotunities?.filter(x => x.relationStatus !== relationStatus).map(data => {
              return <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={data?.displayName} />
                  <Button onClick={() => handleRemoveMTGClick(data.id)} disabled={mTGRelatedPopup.disableChanges} >
                    <RemoveCircleIcon style={{ color: mTGRelatedPopup.disableChanges ? '#bcbcbc' : '#DC143C' }} />
                  </Button>
                </ListItem>
              </List>
            })
          }
        </Collapse>
      </List>
      {allowUpdate
        ? <Button variant="contained" disabled={mTGRelatedPopup.disableChanges} onClick={handleUpdateMtg}>Save</Button>
        : ''}
    </>
  );
}
