import { getAuthorizationProvider } from "../Authentication";

const callGraphApi = async (url) => {
  var provider = await getAuthorizationProvider();
  var tokenRequest = {
    scopes: ["User.Read"]
  };

  try {
    var token = await provider.acquireTokenSilent(tokenRequest);
    var headers = new Headers();
    headers.append("Authorization", `Bearer ${token.accessToken}`);

    try {
      var response = await fetch(url, { method: 'GET', headers: headers });
      var data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  } catch (e) {
    provider.acquireTokenRedirect(tokenRequest);
  }
};

export const fetchCurrentUser = async () => {
  var user = await callGraphApi("https://graph.microsoft.com/v1.0/me");
  return user;
};