import React from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core';

const Banner = withStyles(theme => ({
  root: {
    borderColor: "#1FB6AD",
    color: "#1FB6AD",
    cursor: "pointer"
  }
}))(MuiAlert);

export const InformationBanner = props => {
  return (
    <Banner {...props}>
      {props.children}
    </Banner>
  );
};