import { Grid } from "@material-ui/core";

import { TypesOfReassured } from "../Helpers";

export const renderAssuredLookupView = (entityType, typeOfReassured) => {
  return ({ option, className }) => {
    return (
      <Grid container direction="row" spacing={0} className={className}>
        <Grid item xs={6}><div>{option[entityType]}</div></Grid>
        <Grid item xs={1}><div>{option.country}</div></Grid>
        {
          typeOfReassured === TypesOfReassured.Individual
            ? <Grid item xs={4}><div>{`${option.address ?? ''} ${option.postcode ?? ''}`}</div></Grid>
            : <>
              <Grid item xs={3}><div>{`${option.address ?? ''} ${option.postcode ?? ''}`}</div></Grid>
              <Grid item xs={1}><div>{option.orbisId}</div></Grid>
            </>
        }
        <Grid item xs={1}><div>{option.source}</div></Grid>
      </Grid>
    );
  };
}